import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GrFormView } from "react-icons/gr";
import React from 'react'

export const genderColumns = (handleEditItem, handleDeleteItem) => {
  return [
    {
      name: "ID",
      selector: (_, index) => index + 1, // Adding 1 to start the index from 1
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Pronouns",
      selector: (row) => row.pronoun,
      sortable: true,
    },
    {
      name: "Action",
      sortable: true,
      cell: (row) => {
        return (
          <>
            <div
              className="edit d-flex align-items-center"
              onClick={() => handleEditItem(row)}
            >
              <FiEdit3 />
              <p className="ms-2">Edit</p>
            </div>
            <div
              className="delete d-flex align-items-center ms-3"
              onClick={() => handleDeleteItem(row)}
            >
              <RiDeleteBin6Line />
              <p className="ms-2 me-5">Delete</p>
            </div>
            {/* <button className="btn btn-primary">Delete</button> */}
          </>
        );
      },
    },
  ];
};

export const semesterColumns = (
  handleEditItem,
  handleDeleteItem,
  subjectData,
  data
) => {
  return [
    {
      name: "ID",
      selector: (row) => row.number, // Adding 1 to start the index from 1
    },
    {
      name: "Semester Name",

      selector: (row) => row.semester_name,
      sortable: true,
    },

    {
      name: "Start Date",
      selector: (row) => row.semester_start_date,
    },
    {
      name: "End Date",
      selector: (row) => row.semester_end_date,
      sortable: true,
    },
    {
      name: "Remaining Days",
      cell: (row) => {
        const diffInTime = new Date(row.semester_end_date) - new Date();
        const remainingDays = Math.round(diffInTime / (1000 * 3600 * 24));
        if (remainingDays < 0) {
          return <div>Ended</div>;
        } else {
          return <div>{remainingDays} days</div>;
        }
      },
    },
    // {
    //   name: "Subject",
    // selector: (row) => row.subject_name,
    // selector: (row) => {

    //   const check = subjectData?.find((item) => {
    //     return item.id === row?.class_name
    //   })
    //   return check.class_name
    // },
    // },
    
    {
      name: "Subject",

      selector: (row) => {
        let temparray = [];
        const subject = row?.subject?.map((item) => {
          return subjectData?.find((sub) => {
            return sub.id === item;
          });
        });
        subject?.map((item) => {
          temparray.push(item?.subject_name);
        });
        return temparray.join(", ");
      },
      // selector: (row) => row.subject.join("", ""),

      sortable: true,
    },
    {
      name: "Next Semester",
      // selector: (row) => row.next_semester,
      selector: (row) => {
        const check = data?.find((item) => {
          return item.next_semester === row?.next_semester;
        });
        return row.next_semester;
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div
              onClick={() => handleEditItem(row)}
              className="edit d-flex align-items-center"
            >
              <FiEdit3 />
              <p className="ms-2">Edit</p>
            </div>
            <div
              onClick={() => handleDeleteItem(row)}
              className="delete d-flex align-items-center ms-3"
            >
              <RiDeleteBin6Line />
              <p className="ms-2 me-5">Delete</p>
            </div>
          </>
        );
      },
    },
  ];
};
export const daysColumns = () => {
  return [
    {
      name: "Semester Name",

      selector: (row) => row.semester_name,
      sortable: true,
    },

    // {
    //   name: "Start Date",
    //   selector: (row) => row.semester_start_date,
    // },
    // {
    //   name: "End Date",
    //   selector: (row) => row.semester_end_date,
    //   sortable: true,
    // },
    {
      name: "Remaining Days",
      cell: (row) => {
        const diffInTime = new Date(row.semester_end_date) - new Date();
        const remainingDays = Math.round(diffInTime / (1000 * 3600 * 24));
        if (remainingDays < 0) {
          return <div>Ended</div>;
        } else {
          return <div>{remainingDays} days</div>;
        }
      },
    },
    // {
    //   name: "Subject",
    // selector: (row) => row.subject_name,
    // selector: (row) => {

    //   const check = subjectData?.find((item) => {
    //     return item.id === row?.class_name
    //   })
    //   return check.class_name
    // },
    // },
  ];
};
export const subjectsColumns = (handleEditItem, handleDeleteItem) => {
  return [
    // {
    //   cell: (row) => (
    //     <div className="table-cell-img">
    //       <img className="rounded" src={row?.subject_img} alt="img" />
    //     </div>
    //   ),

    //   sortable: true,
    // },
    {
      name: "ID",
      selector: (row) => row?.number,
      sortable: true,
    },
    {
      name: "Subject Name",
      selector: (row) => row?.subject_name,
      sortable: true,
    },
    // {
    //   name: "Semester",
    //   selector: (row) => {
    //     return row?.semester;
    //   },
    //   sortable: true,
    // },
    {
      name: "Subject Category",
      selector: (row) => row?.category,
      // {
      // const subj = row?.subject?.map((item) => item?.category);
      // return subj.join(", ");}

      sortable: true,
    },
    
    {
      name: "Trends",
      cell: (row) => (
        <div>
          <p>{row?.trend1}</p>
          <p>{row?.trend2}</p>
          <p>{row?.trend3}</p>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div
              className="edit d-flex align-items-center"
              onClick={() => handleEditItem(row)}
            >
              <FiEdit3 />
              <p className="ms-2">Edit</p>
            </div>
            <div
              className="delete d-flex align-items-center ms-3"
              onClick={() => handleDeleteItem(row)}
            >
              <RiDeleteBin6Line />
              <p className="ms-2 me-5">Delete</p>
            </div>
          </>
        );
      },
    },
  ];
};
export const studentsColumns = (
  handleEditItem,
  handleDeleteItem,
  classData,
  handleCheckboxChange,
  checkedRows,
) => {
  return [
    {
      name: "Select",
      cell: (row) => (
        <input
          type="checkbox"
          checked={checkedRows && checkedRows.includes(row.id)}
          onChange={() => handleCheckboxChange(row.id)}
        />
      ),
    },
    {
      name: "ID",
      selector: (row) => row?.number,
      sortable: true,
    },
    {
      cell: (row) => (

        <div className="table-cell-img image-container">
          <img className="rounded" src={row?.student_img} alt="" />
        </div>
      ),

      sortable: true,
    },
    {
      name: "Std Name",
      selector: (row) => row?.student_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.student_email,
    },
    {
      name: "Class",
      selector: (row) => {
        const check = classData?.find((item) => {
          return item.name === row?.class_name;
        });
        return check?.name;
      },
    },

    {
      name: "Gender",

      selector: (row) => row?.gender,
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row?.subjects.join(", "),

      sortable: true,
    },
    {
      name: "Semester Name",
      selector: (row) => row?.semester,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div
              className="edit d-flex align-items-center"
              onClick={() => handleEditItem(row)}
            >
              <FiEdit3 />
              <p className="ms-2">Edit</p>
            </div>
            <div
              className="delete d-flex align-items-center ms-3 me-4"
              onClick={() => handleDeleteItem(row)}
            >
              <RiDeleteBin6Line />
              <p className="ms-2 me-5">Delete</p>
            </div>
          </>
        );
      },
    },
  ];
};
export const commentsColumns = (
  handleEditItem,
  handleDeleteItem,
  handleViewPage
) => {
  return [
    {
      name: "ID",
      selector: (row) => row.number, // Adding 1 to start the index from 1
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Comment",
      selector: (row) => row.comment,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div
              className="edit d-flex align-items-center"
              onClick={() => handleEditItem(row)}
            >
              <FiEdit3 />
              <p className="ms-2">Edit</p>
            </div>
            <div
              className="delete d-flex align-items-center ms-3"
              onClick={() => handleDeleteItem(row)}
            >
              <RiDeleteBin6Line />
              <p className="ms-2 me-5">Delete</p>
            </div>
          </>
        );
      },
    },
  ];
};

export const commentsCategoryColumns = (handleEditItem, handleDeleteItem) => {
  return [
    {
      name: "ID",
      selector: (_, index) => index + 1, // Adding 1 to start the index from 1
    },
    {
      name: "Comment Category",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div
              className="edit d-flex align-items-center"
              onClick={() => handleEditItem(row)}
            >
              <FiEdit3 />
              <p className="ms-2">Edit</p>
            </div>
            <div
              className="delete d-flex align-items-center ms-3"
              onClick={() => handleDeleteItem(row)}
            >
              <RiDeleteBin6Line />
              <p className="ms-2 me-5">Delete</p>
            </div>
          </>
        );
      },
    },
  ];
};

export const classColumns = (
  handleEditItem,
  handleDeleteItem,
  semesterData
) => {
  return [
    {
      name: "ID",
      selector: (row) => row.number, // Adding 1 to start the index from 1
    },
    {
      name: "Class",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Semester",

    //   selector: (row) => row?.semester,
    //   sortable: true,
    // },
    {
      name: "Semester",
      selector: (row) => {
        let semName = [];

        row.semester.map((item) => {
          return semName.push(item.semester_name);
        });

        return semName.join(", ");
      },

      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div
              className="edit d-flex align-items-center"
              onClick={() => handleEditItem(row)}
            >
              <FiEdit3 />
              <p className="ms-2">Edit</p>
            </div>
            <div
              className="delete d-flex align-items-center ms-3"
              onClick={() => handleDeleteItem(row)}
            >
              <RiDeleteBin6Line />
              <p className="ms-2 me-5">Delete</p>
            </div>
          </>
        );
      },
    },
  ];
};


export const dashStudentsColumns = (

) => {
  

   

    return [
     
      {
        name: "ID",
        selector: (row) => row?.number,
        sortable: true,
      },
      
   
      {
        name: "Std Name",
        selector: (row) => row?.student_name,
        sortable: true,
      },
    {
      name: "Email",
      selector: (row) => row?.student_email,
    },
    // {
    //   name: "Class",
    //   selector: (row) => {
    //     const check = classData?.find((item) => {
    //       return item.name === row?.class_name;
    //     });
    //     return check?.name;
    //   },
    // },

    {
      name: "Class",
      selector: (row) => row?.class_name,

   
    },
    {
      name: "Gender",

      selector: (row) => row?.gender,
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row?.subjects.join(", "),

      sortable: true,
    },
    {
      name: "Semester Name",
      selector: (row) => row?.semester,
      sortable: true,
    },
    
  ];
};