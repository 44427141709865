import {
  addSemester,
  getSemesters,
  deleteSemester,
  updateSemester,
} from "./semesterThunk";

export const reducerHelper = (builder) => {
  //* Semester Data ====================================
  builder.addCase(getSemesters.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getSemesters.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getSemesters.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Add Semester Data ====================================
  builder.addCase(addSemester.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(addSemester.fulfilled, (state, action) => {
    state.loading = false;
    // state.data.push(action.payload);
    // state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(addSemester.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Semester Data ====================================
  builder.addCase(deleteSemester.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(deleteSemester.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(deleteSemester.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Semester Data ====================================
  builder.addCase(updateSemester.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(updateSemester.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(updateSemester.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });
};
