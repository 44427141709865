import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Form, Formik } from "formik";
import { subjectValidationSchema } from "../../helpers/validationSchemas.js";
import { useDispatch, useSelector } from "react-redux";
import FormikField from "../Common/InputField.jsx";
import CommonButton from "../Common/CommonButton.jsx";
import SubjectCategoryDropdown from "../Common/SubjectCategoryDropdown.js";


const AddSubjectModal = ({ showAddModal, handleAddToggle, addSubject }) => {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.reducer.subjectcategory);
  const [saveButton, setSaveButton] = useState(false)



  const addSubjectHandler = (values, resetForm, shouldResetForm) => {
    if (!values.subjectName) {
      return;
    }

    dispatch(addSubject(values)).then(() => {
      if (shouldResetForm === false && saveButton === false) {
        resetForm();
      } else {
        handleAddToggle()
      }
    });
  };
  return (
    <>
      <Modal show={showAddModal} onHide={handleAddToggle}>
        <div className="modal-dialog modal-dialog-centered m-0">
          <div className="modal-content p-4">
            <div className=" mb-3">
            <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Add Subject</Modal.Title>
        </Modal.Header>               
        <Modal.Body>  
            <Formik
                initialValues={{
                  subjectName: "",
                  subjectCategory: "",
                  trend1: "",
                  trend2: "",
                  trend3: "",
                }}
                validationSchema={subjectValidationSchema}
                onSubmit={(values, { resetForm }) => {
                  addSubjectHandler(values, resetForm, false)

                }
                }
              >
                {({ values }) => (
                  <Form className="row">
                    <div className="col-6">
                      <FormikField
                        name="subjectName"
                        label="Subject Name"
                        type="text"
                      />
                    </div>
                    <div className="col-6">
                      <SubjectCategoryDropdown
                        name="subjectCategory"
                        label="Subject Category"
                        options={category?.data}
                        displayProperty="category"
                      />
                    </div>
                    <div className="col-4">
                      <FormikField
                        name="trend1"
                        label="Trend 1"
                        type="text"
                      />
                    </div>
                    <div className="col-4">
                      <FormikField
                        name="trend2"
                        label="Trend 2"
                        type="text"
                      />
                    </div>
                    <div className="col-4">
                      <FormikField
                        name="trend3"
                        label="Trend 3"
                        type="text"
                      />
                    </div>
                    <div className="select-btn mt-5 d-flex gap-3">
                      <CommonButton
                        variant="light px-5"
                        onClick={handleAddToggle}
                        text="Cancel"
                        type="button"
                      />
                      <CommonButton variant="primary px-4" text="Save & Add New" type="submit" />

                      <CommonButton variant="primary px-5" text="Save" type="submit" onClick={(values) =>
                        setSaveButton(true)
                      } />
                    </div>
                  </Form>
                )}
              </Formik>
              </Modal.Body>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddSubjectModal;
