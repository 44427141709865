import React from "react";

const Statistics = (props) => {
  const { image, text, count,color } = props
  return (
    <div className={`d-flex flex-column h-100   box-item  gap-1 `}>
      <div className=""><img src={image} /></div>
      <h5 className={`title1 text-${color}`}>{count}</h5>

      <p className="title4">{text}</p>
    </div>
  );
};

export default Statistics;
