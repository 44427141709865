

import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api, { apiPrivate } from "../../api";

export const getSubjectCategory = createAsyncThunk(
  "subjectcategory/getSubjectCategory",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/subject/category/");
      return response.data;
    } catch (error) {
      const message = error.response.data;

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addSubjectCategory = createAsyncThunk(
  "subjectcategory/addSubjectCategory",
  async (data, { dispatch }, thunkAPI) => {
    try {
      const response = await apiPrivate.post("api/subject/category/", {
        name: data,
      });
      dispatch(getSubjectCategory());
      toast.success("Successfully added Subject Category");
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const message = error.response.data[0];

        toast.error(message);
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const deleteSubject = createAsyncThunk(
  "subjectcategory/deleteSubject",
  async (id, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.delete(`api/subject/category/${id}/`);
      dispatch(getSubjectCategory());
      toast.success("Successfully Deleted Subject Category");
    } catch (error) {
      const message = error.response.data;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateSubjectCategory = createAsyncThunk(
  "subjectcategory/updateSubjectCategory",
  async (data, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.put(`api/subject/category/${data?.id}/`, {
        name: data.categoryName,
      });
      dispatch(getSubjectCategory());
      toast.success("Successfully Updated Subject Category");
    } catch (error) {
      const message = error.response.data;
      toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);
