import { Form, Formik } from "formik";
import React from "react";
import {
  commentsCategoryValidationSchema,
  commentsValidationSchema,
  subjectsCategoryValidationSchema,
} from "../../helpers/validationSchemas";
import FormikField from "../../components/Common/InputField";
import CommonButton from "../../components/Common/CommonButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { commentsCategoryColumns } from "../../helpers/tableColumns.js";
import Loader from "../../components/Common/Loader.jsx";
import { useEffect, useState } from "react";
import {
  addSubjectCategory,
  deleteSubject,
  getSubjectCategory,
  updateSubjectCategory,
} from "../../store/subjectCategory/subjectCategoryThunk";
import DataGrid from "../../components/Common/DataGrid";
import DeleteModal from "../../components/Common/DeleteModal";
import EditCommentCategoryModal from "../../components/Models/EditCommentCategoryModal";
import EditSubjectCategoryModal from "../../components/Models/EditSubjectCategoryModal";

const SubjectCategory = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [closeModal, setCloseModal] = useState(showAddModal);

  const dispatch = useDispatch();
  const { data, loading, error, status } = useSelector(
    (state) => state.reducer.subjectcategory
  );

  useEffect(() => {
    dispatch(getSubjectCategory());
  }, [dispatch]);

  const handleDeleteToggle = async (row) => {
    setselectedItem(row);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteItem = () => {
    dispatch(deleteSubject(selectedItem?.id));
    handleDeleteToggle();
  };
  const handleAddToggle = (row) => {
    setShowAddModal(!showAddModal);
  };

  const handleCloseToggle = (row) => {
    setCloseModal(!closeModal);
  };
  const handleEditToggle = (row) => {
    setselectedItem(row);
    setShowEditModal(!showEditModal);
  };

  const handleUpdateItem = (values, id) => {
    values.id = id;
    dispatch(updateSubjectCategory(values));
    handleEditToggle();
  };

  const columns = commentsCategoryColumns(handleEditToggle, handleDeleteToggle);

  const addCategoryHandler = (values, resetForm) => {
    dispatch(addSubjectCategory(values.categoryName));
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="comment-page">
        <DeleteModal
          showDeleteModal={showDeleteModal}
          handleDeleteToggle={handleDeleteToggle}
          handleDeleteItem={handleDeleteItem}
        />
        <EditSubjectCategoryModal
          showEditModal={showEditModal}
          handleEditToggle={handleEditToggle}
          handleUpdateItem={handleUpdateItem}
          selectedItem={selectedItem}
          commentCategory={data}
          handleCloseToggle={handleCloseToggle}
          setShowEditModal={setShowEditModal}


        />
        <div className="box-item mb-3">
          <h3 className="title3 mb-3">Add Subject Category</h3>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-7">
              <Formik
                initialValues={{
                  categoryName: "",
                }}
                validationSchema={subjectsCategoryValidationSchema}
                onSubmit={(values) => addCategoryHandler(values)}
              >
                <Form className="mb-4">
                  <FormikField
                    name="categoryName"
                    label="Subject Category"
                    type="text"
                  />

                  <div className="select-btn mt-3">
                    <CommonButton
                      variant="primary"
                      text="Add Subject"
                      type="submit"
                    />
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
        <div className="data-table-container comment-table">
          <h3 className="title3 mb-4 px-3">Subject Category</h3>
          <DataGrid columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
};

export default SubjectCategory;
