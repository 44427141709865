import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode";
import Dashboard from "./pages/dashboard/Dashboard.jsx";
import Login from "./pages/auth/Login.jsx";
import Semester from "./pages/semester/Semester.jsx";
import Gender from "./pages/gender/Gender.jsx";
import Subjects from "./pages/subjects/Subjects.jsx";
import Students from "./pages/students/Students.jsx";
import Report from "./pages/report/Report.jsx";
import ProtectedRoutes from "./components/ProtectRoutes/ProtectedRoutes.jsx";
import PublicRoutes from "./components/ProtectRoutes/PublicRoutes.jsx";
import Comments from "./pages/comments/Comments.jsx";
import Profile from "./pages/profile/Profile.jsx";
import { getToken } from "./helpers/index.js";
import axios from "axios";
import { apiPrivate } from "./api/index.js";
import PageNotFound from "./components/Common/PageNotFound.jsx";
import { getUserInfo } from "./store/dashboard/dashboardThunk.js";
import { useDispatch } from "react-redux";
import CommentCategory from "./pages/comments/CommentCategory.js";
import Class from "./pages/class/Class.js";
import ReportMain from "./pages/report/ReportMain.jsx";
import SubjectCategory from "./pages/subjects/SubjectCategory.jsx";
import Signup from "./pages/auth/Signup.jsx";
import ResetPassword from "./components/Common/ResetPassword.js";
const App = () => {
  const navigate = useNavigate();
  const token = getToken();
  const [isLoggedIn, setILoggedIn] = useState(token);
  // const isLoggedIn = false;
  const [topContent, setTopContent] = useState("");
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    navigate("/login");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decodedToken = jwtDecode(token);

      const dateObject = new Date(decodedToken.exp * 1000);
      const humanDate = dateObject.toLocaleString();

      if (decodedToken.exp < Date.now() / 1000) {
        // Token has expired, log out the user
        logout();
      }
    }
  }, []);
  return (
    <div className="">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route element={<PublicRoutes isLoggedIn={isLoggedIn} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route exact path="/accounts/password_reset/confirm/" element={<ResetPassword />} />

        </Route>
        <Route
          element={
            <ProtectedRoutes isLoggedIn={isLoggedIn} topContent={topContent} />
          }
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="/semester" element={<Semester />} />
          <Route path="/subjects" element={<Subjects />} />
          <Route path="/subjectCategory" element={<SubjectCategory />} />
          <Route path="/students" element={<Students />} />

          <Route path="/report" element={<Report />} />
          <Route path="/reportmain" element={<ReportMain />} />

          <Route path="/comments" element={<Comments />} />
          <Route path="/gender" element={<Gender />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/commentcategory" element={<CommentCategory />} />

          {/* <Route path="/view" element={<CommentDetail />} /> */}
          <Route path="/class" element={<Class />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default App;
