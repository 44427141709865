// CommentsCard.jsx

import React, { useEffect, useState } from "react";
import { GrDownload, GrTrash } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteComment,
  getReportComment,
} from "../../store/reports/reportsThunk.js";
import { ExportPDF } from "../../store/csv/singlePdfSlice.js";
import moment from 'moment';

const CommentsCard = ({ semester, student, reports, selectedSubject }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.reducer.subjects);

  const foundSubject = data?.find(
    (item) => item.id === Number(selectedSubject)
  );

  const filteredComments = selectedSubject
    ? reports?.reportComments?.filter(
        (item) => item.subject === foundSubject?.subject_name
      )
    : reports?.reportComments || [];

  const handleClick = (studentID,subjectID) => {
    dispatch(ExportPDF({studentID,subjectID}));
  };

  const handleDelete = () => {
    dispatch(deleteComment(student?.id));
  };

  return (
    <>
      {filteredComments?.length > 0 ? (
        filteredComments?.map((item) => (
          <div className="comment" key={item.id}>
            <div className="d-flex justify-content-between ">
            <p className="my-2">{item.subject}</p>
            <div className="d-flex">
            <button onClick={handleDelete} className="export-btn blue-button">
              <GrTrash style={{ color: 'red' }}/>
            </button>
            <button onClick={() => handleClick(item.id,item?.subject_id)} className="export-btn red-button">
              <GrDownload style={{ color: 'red' }} />
            </button>

            </div>
           
            </div>
           
            <p className="span1">{item.comment.join(" ")}</p>
            <p className="span2 my-2">{moment(item.created_at).format('YYYY-MM-DD hh:mm A')}</p>
          </div>
        ))
      ) : (
        <p>
          {selectedSubject
            ? "No comments available for the selected subject"
            : "Displaying all comments"}
        </p>
      )}
    </>
  );
};

export default CommentsCard;
