import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api, { apiPrivate } from "../../api";

export const getSubjects = createAsyncThunk(
  "subject/getSubjects",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/subjects/");
      return response.data;
    } catch (error) {
      const message = error.response.data;

      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const addSubject = createAsyncThunk(
  "subject/addSubject",
  async (data, { dispatch }, thunkAPI) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const response = await apiPrivate.post(
        "api/subjects/",
        {
          subject_name: data?.subjectName,
          category: data?.subjectCategory,
          trend1: data?.trend1,
          trend2: data?.trend2,
          trend3: data?.trend3,
        },
        config
      );
      dispatch(getSubjects());
      toast.success("Successfully Added Subject");
      return response.data;
    } catch (error) {
      const message = error.response.data;
      toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateSubject = createAsyncThunk(
  "subject/updateSubject",
  async (data, { dispatch }, thunkAPI) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await apiPrivate.put(
        `api/subjects/${data?.id}/`,
        {
          subject_name: data.subjectName,
          category: data.subjectCategory,
          trend1: data?.trend1,
          trend2: data?.trend2,
          trend3: data?.trend3,
        },
        config
      );
      dispatch(getSubjects());
      toast.success("Successfully Updated Subject");
    } catch (error) {
      const message = error.response.data;
      toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// export const addSubject = createAsyncThunk(
//   "subject/addSubject",
//   async (data, { dispatch }, thunkAPI) => {
//     try {
//       const config = {
//         headers: {
//           "content-type": "multipart/form-data",
//         },
//       };
//       const response = await apiPrivate.post(
//         "api/subjects/",
//         {
//           subject_img: data?.selectedImg,
//           subject_name: data?.subjectName,
//           category: data?.subjectCategory,
//         },
//         config
//       );
//       dispatch(getSubjects());
//       toast.success("Successfully Added Subject");
//       return response.data;
//     } catch (error) {
//       const message = error.response.data;
//       toast.error("Something went wrong");
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const deleteSubject = createAsyncThunk(
  "subject/deleteSubject",
  async (id, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.delete(`api/subjects/${id}/`);
      dispatch(getSubjects());
      toast.success("Successfully Deleted Subject");
    } catch (error) {
      const message = error.response.data;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const updateSubject = createAsyncThunk(
//   "subject/updateSubject",
//   async (data, { dispatch }, thunkAPI) => {
//     try {
//       const config = {
//         headers: {
//           "content-type": "multipart/form-data",
//         },
//       };
//       await apiPrivate.put(
//         `api/subjects/${data?.id}/`,
//         {
//           subject_img: data.selectedImg,
//           subject_name: data.subjectName,
//           category: data.subjectCategory,
//         },
//         config
//       );
//       dispatch(getSubjects());
//       toast.success("Successfully Updated Subject");
//     } catch (error) {
//       const message = error.response.data;
//       toast.error("Something went wrong");
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );
