import {
  addSubject,
  deleteSubject,
  getSubjects,
  updateSubject,
} from "./subjectThunk";

export const reducerHelper = (builder) => {
  //* Gender Data ====================================
  builder.addCase(getSubjects.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getSubjects.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getSubjects.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Add Gender Data ====================================
  builder.addCase(addSubject.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(addSubject.fulfilled, (state, action) => {
    state.loading = false;

    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(addSubject.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Gender Data ====================================
  builder.addCase(deleteSubject.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(deleteSubject.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(deleteSubject.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Gender Data ====================================
  builder.addCase(updateSubject.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(updateSubject.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(updateSubject.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });
};
