import React from "react";
import moment from "moment";
import semesterIcon from "../../assets/Group 2225.png"
import classIcon from "../../assets/Group 2226.png"
import studentIcon from "../../assets/Group 2227.png"
import subjectIcon from "../../assets/Group 2229.png"
const RecentActivites = ({ action, message, createdAt,type }) => {
  return (
    <div class=" d-flex mb-2 ">
      <div className="me-4 activity-img">
        <img className=" w-100  h-100 " src={type==="class" ? classIcon : type==="student" ? studentIcon : type ==="semester"? semesterIcon :subjectIcon} />
      </div>
      <div class="activity-pannel">
      <p className="span2">
          {moment(createdAt)?.format("MMMM D, YYYY - h:mm A")}
        </p>
        <p class="mb-2">{message}</p>
       
      </div>
    </div>
  );
};

export default RecentActivites;
