import { addCategory } from "../commentCategory/commentCategoryThunk";
import {
  addComment,
  deleteComment,
  getComments,
  updateComment,
} from "./commentThunk";

export const reducerHelper = (builder) => {
  //* Gender Data ====================================
  builder.addCase(getComments.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getComments.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getComments.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Add Gender Data ====================================
  builder.addCase(addComment.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(addComment.fulfilled, (state, action) => {
    state.loading = false;
    // state.data.push(action.payload);
    // state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(addComment.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Gender Data ====================================
  builder.addCase(deleteComment.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(deleteComment.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(deleteComment.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Gender Data ====================================
  builder.addCase(updateComment.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(updateComment.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(updateComment.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });
};
