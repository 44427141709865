import React, { useEffect, useState } from "react";
import { FaGraduationCap, FaUserGraduate, FaCommentDots } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";
import {
  BsFillCircleFill,
  BsFillFileEarmarkTextFill,
  BsGenderAmbiguous,
} from "react-icons/bs";
import { IoLibrary } from "react-icons/io5";
import { AiOutlineLogout } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import logo from "../../assets/logo.png";
import { getSelectedTab } from "../../helpers";
import { useDispatch } from "react-redux";
import { logout } from "../../store/auth/authThunk";
import { setSelectedTab } from "../../store/dashboard/dashboardSlice";
import SidebarDropdown from "./SidebarDropdown";
import dashboardIcon from "../../assets/icons/Dashboard.png"
import subjectIcon from "../../assets/icons/Group 2292.png"
import genderIcon from "../../assets/icons/Group 2294.png"
import commentIcon from "../../assets/icons/Group 2350.png"
import studentIcon from "../../assets/icons/Group.png"
import classIcon from "../../assets/icons/Vector (1).png"
import semesterIcon from "../../assets/icons/Union.png"
import reportIcon from "../../assets/icons/File.png"
import logoutIcon  from "../../assets/icons/Logout.png"



const sidebarItems = [
  { id: 1, icon:<img src={dashboardIcon}  className="sidebar-icon"/>, text: "Dashboard", url: "/" },
  { id: 2, icon:<img src={genderIcon}  className="sidebar-icon"/>, text: "Genders", url: "/gender" },

  {
    id: 3,
    icon: <img src={subjectIcon} className="sidebar-icon"/>,
    text: "Subjects",
    dropdown: [
      {
        id: 4,
        icon: <img src={subjectIcon}  className="sidebar-icon"/>,
        text: "Subject Category",
        url: "/subjectCategory",
      },
      {
        id: 5,
        icon:<img src={subjectIcon}  className="sidebar-icon"/>,
        text: "Add Subjects",
        url: "/subjects",
      },
    ],
  },
  {
    id: 6,
    icon: <img src={semesterIcon}  className="sidebar-icon"/>,
    text: "Semesters",
    url: "/semester",
  },
  {
    id: 7,
    icon: <img src={classIcon}  className="sidebar-icon"/>,
    text: "Classes",
    url: "/class",
  },
  {
    id: 8,
    icon: <img src={studentIcon}  className="sidebar-icon"/>,
    text: "Students",
    url: "/students",
  },

  {
    id: 9,
    icon: <img src={commentIcon}  className="sidebar-icon"/>,
    text: "Feedback",
    dropdown: [
      {
        id: 10,
        icon:<img src={commentIcon}  className="sidebar-icon"/>,
        text: "Comment Categories",
        url: "/commentcategory",
      },
      {
        id: 11,
        icon: <img src={commentIcon}  className="sidebar-icon"/>,
        text: "Comment Bank",
        url: "/comments",
      },
    ],
  },

  {
    id: 12,
    icon: <img src={reportIcon}  className="sidebar-icon" />,
    text: "Reports",
    url: "/report",
  },
];

const Sidebar = ({ topContent }) => {
  const dispatch = useDispatch();
  const [refreshed, setRefreshed] = useState(false);

  const [selected, setSelected] = useState(getSelectedTab() || sidebarItems[0]);
  const [selectedComment, setSelectedComment] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const selectedSideItem = sidebarItems?.find(
      (item) => item?.url === location?.pathname
    );

    const check = sidebarItems?.[6]?.dropdown?.find(
      (item) => item?.url === location.pathname
    );

    setSelectedComment(check);
    setSelected(selectedSideItem);
    dispatch(setSelectedTab(selectedSideItem || check));
  }, [location, dispatch]);

  return (
    <div className="sidebar m-3 bg-white rounded-4  relative">
      <div
        className="logo mt-3 mb-3 mb-md-4"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setSelected(sidebarItems[0]);
          navigate("/");
        }}
      >
        <img src={logo} class="img-fluid" alt="" />
      </div>

      {sidebarItems.map((item, idx) => {
        if (item.dropdown) {
          return (
            <SidebarDropdown
              key={idx}
              icon={item.icon}
              text={item.text}
              url={item.url}
              items={item.dropdown}
              setSelected={setSelected}
              selected={selected}
            />
          );
        } else {
          return (
            <div
              onClick={() => {
                setSelected(item);
                setSelectedTab(item);
                setRefreshed(!refreshed);
                localStorage.setItem("selected-tab", JSON.stringify(item));
                dispatch(setSelectedTab(item));
                navigate(item.url);
                localStorage.removeItem("student")
              }}
            >
              <SidebarItem
                key={idx}
                icon={item.icon}
                text={item.text}
                url={item.url}
                setSelected={setSelected}
                selected={selected}
              />
            </div>
          );
        }
      })}

      <div
        onClick={() => {
          dispatch(logout()).then(() => {
            navigate("/login");
          });
        }}
      >
        <button className="logout-btn ms-2 mt-1">
          <img src={logoutIcon} className="me-2"/>
          Logout 

        </button>
      </div>
    </div>
  );
};

export default Sidebar;
