import { createSlice } from "@reduxjs/toolkit";
import { reducerHelper } from "./reducerHelper";

const initialState = {
  status: "",
  loading: false,
  data: [],
  error: null,
  academicsData: [],
  behaviourData: [],
  improvementsData: [],
  reportComments: [],
  trends: null,
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},

  // * Apiii Calls resultss
  extraReducers: reducerHelper,
});

// this is for dispatch
export const { setSelectedTab } = reportsSlice.actions;

// this is for configureStore
export default reportsSlice.reducer;
