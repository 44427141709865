import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CommonButton from "../../components/Common/CommonButton.jsx";
import FormikField from "../../components/Common/InputField.jsx";
import DataGrid from "../../components/Common/DataGrid.jsx";

import {
  getGenders,
  deleteGender,
  addGender,
  updateGender,
} from "../../store/gender/genderThunk.js";

import Loader from "../../components/Common/Loader.jsx";
import { genderColumns } from "../../helpers/tableColumns.js";
import { genderValidationSchema } from "../../helpers/validationSchemas.js";
import DeleteModal from "../../components/Common/DeleteModal.jsx";
import EditFormModal from "../../components/Models/EditSemesterModal.jsx";
import EditGenderModal from "../../components/Models/EditGenderModal.jsx";
import AddGenderModal from "../../components/AddModals/AddGenderModal.jsx";

const Gender = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const dispatch = useDispatch();

  const { data, loading } = useSelector((state) => state.reducer.gender);
  const handleRowClicked = (row) => {};

  useEffect(() => {
    dispatch(getGenders());
  }, [dispatch]);

  const handleDeleteToggle = async (row) => {
    setselectedItem(row);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteItem = () => {
    dispatch(deleteGender(selectedItem?.id));
    handleDeleteToggle();
  };

  const handleEditToggle = (row) => {
    setselectedItem(row);
    setShowEditModal(!showEditModal);
  };
  const handleAddToggle = (row) => {
    
    setShowAddModal(!showAddModal);
  };

  const handleUpdateItem = (values, id) => {
    values.id = id;
    dispatch(updateGender(values));
    handleEditToggle();
  };
  const handleCloseToggle = (row) => {
    setCloseModal(!closeModal);
  };

  const columns = genderColumns(handleEditToggle, handleDeleteToggle);

  

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
       <AddGenderModal
        showAddModal={showAddModal}
        handleAddToggle={handleAddToggle}
        addGender={addGender}
        setShowAddModal={setShowAddModal}
       
      />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteToggle={handleDeleteToggle}
        handleDeleteItem={handleDeleteItem}
      />
      <EditGenderModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        handleEditToggle={handleEditToggle}
        handleUpdateItem={handleUpdateItem}
        selectedItem={selectedItem}
        handleCloseToggle={handleCloseToggle}

      />
      <div className=" w-100 d-flex justify-content-end my-3">
      <CommonButton
       variant="primary"
       text='+ Create New'
       onClick={() => handleAddToggle()}
       />
        </div>
    
      
      <div className="data-table-container">
        <h3 className="title3 mb-4 px-3">Gender</h3>
        <DataGrid
          columns={columns}
          data={data}
          handleRowClicked={handleRowClicked}
        />
      </div>
    </div>
  );
};

export default Gender;
