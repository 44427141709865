import React, { useEffect, useState } from "react";
import { Button, Modal, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../store/dashboard/dashboardThunk";
import TopBarCard from "./TopBarCard";
import ProfileModal from "./ProfileModal";
import { Form } from "formik";

const TopBar = ({ topContent }) => {
  const { selectedTab } = useSelector((state) => state.reducer.dashboard);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleProfileClick = () => {
    setShowProfileModal(true);
  };

  const handleCloseProfileModal = () => {
    setShowProfileModal(false);
  };

  return (
    <div className="topbar d-flex justify-content-between align-items-center px-4 mt-3">
      <div className="d-flex align-items-center gap-1 topbar-title">
        <h4 className="title2">{selectedTab?.text}</h4>
      </div>
      <div className="d-flex align-items-center gap-4">
        <TopBarCard onProfileClick={handleProfileClick} />
      </div>

      <ProfileModal show={showProfileModal} handleClose={handleCloseProfileModal} />
    </div>
  );
};

export default TopBar;
