import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AddCommentCard = ({
  name,
  ratings,
  placeholder,
  ratingChangeHandler,
  commentChangeHandler,
  commentText,
  commentName1,
  onChange,

  commentName,

  color,
}) => {
  const [onPress, setOnPress] = useState(false);

  const handleChange = (e) => {
    if (e.key === "Enter") {
      debugger;
      setOnPress(true);
    } else {
      onChange(e.target.value);
      setOnPress(false);
    }
  };

  return (
    <div className="add-comment my-4">
      <div className="d-flex justify-content-between align-items-centern flex-wrap ">
        <div className="d-flex align-items-center gap-2">
          <p className="shape" style={{ background: color }}></p>
        
          <p>{commentName}</p>
        </div>
        <div className="slider d-flex gap-2 align-items-center">
          <p className="title5">Rating</p>
          <input
            type="range"
            min={0}
            max={10}
            value={ratings}
            onChange={ratingChangeHandler}
            // onInput="rangeValue.innerText=this.value"
          />
          <p className="title5" id="rangeValue">
            {ratings}
          </p>
        </div>
      </div>
      <div class="my-2">
        <textarea
          class="form-control"
          name=""
          id=""
          rows="3"
          value={commentText}
          onChange={commentChangeHandler}
        ></textarea>
      </div>
    </div>
  );
};

export default AddCommentCard;
