import React, { useState } from "react";

import { ErrorMessage, Field } from "formik";

const SemesterDropdown = ({
  name,
  label,
  selected,
  options,
  onChange,
  disabled,
  classData,
}) => {
  return (
    <Field as="select" name={name}>
      {(formikField) => {
        const res = classData?.find((item) => {
          return item.id === Number(formikField.form.values.className);
        });

        return (
          <>
            <div>
              {label && (
                <label for="" class="form-label">
                  {label}
                </label>
              )}
              <select
                placeholder="Select"
                className="form-select"
                value={formikField?.field?.value || ""}
                {...formikField?.field}
                disabled={formikField.form.values.className ? false : true}
              >
                <option>Select</option>
                {res?.semester?.map?.((item) => {
                  return (
                    <option key={item?.id} value={item?.id}>
                      {item?.semester_name}
                    </option>
                  );
                })}

                {options?.map?.((item) => {
                  return (
                    item.id ===
                    (
                      <option key={item?.id} value={item?.id}>
                        {item?.semester_name}
                      </option>
                    )
                  );
                })}
              </select>
            </div>
            <ErrorMessage name={name}>
              {(errMessage) => {
                return <div className="err-msg">{errMessage}</div>;
              }}
            </ErrorMessage>
          </>
        );
      }}
    </Field>
  );
};

export default SemesterDropdown;
