import { ErrorMessage, Field } from "formik";
import React from "react";
import Select from "react-select";

const SemesterMultiselect = ({ label, name, options, selectedItem }) => {

  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <Field name="semester">
        {({ formikField, form }) => {
          return (
            <Select
              {...formikField}
              isMulti
              name={name}
              options={options}
              value={formikField?.field.value || form?.values?.classSemester}
              onChange={(option) => form.setFieldValue(name, option)}
            />
          );
        }}
      </Field>
      <div className="text-danger font-size-13">
        <ErrorMessage name={name}>
          {(errMessage) => {
            return <div className="err-msg">{errMessage}</div>;
          }}
        </ErrorMessage>
      </div>
    </div>
  );
};
export default SemesterMultiselect;
