import {
  addGender,
  deleteGender,
  getGenders,
  updateGender,
} from "./genderThunk";

export const reducerHelper = (builder) => {
  //* Gender Data ====================================
  builder.addCase(getGenders.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getGenders.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getGenders.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Add Gender Data ====================================
  builder.addCase(addGender.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(addGender.fulfilled, (state, action) => {
    state.loading = false;
    // state.data.push(action.payload);
    // state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(addGender.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Gender Data ====================================
  builder.addCase(deleteGender.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(deleteGender.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(deleteGender.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Gender Data ====================================
  builder.addCase(updateGender.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(updateGender.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(updateGender.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });
};
