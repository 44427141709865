import React, { useState } from "react";
import CommonDropdown from "../Common/CommonDropdown.jsx";
import StudentCard from "./StudentCard.jsx";
import chart from "../../assets/chart.png";

import StudentCardImg from "../../assets/student-card.png";
import { useSelector } from "react-redux";

const StudentPerformance = () => {
  const { data } = useSelector((state) => state.reducer.students);
  const semesterData = useSelector((state) => state.reducer.semester);
  const [selectedSemester, setSelectedSemester] = useState("");

  const handleSemChange = (e) => {
    setSelectedSemester(e.target.value);
  };
  return (
    <div className="student-performance box-item">
      <div className="d-flex justify-content-center  align-content-center ">
      <h3 className="title3 w-100 my-auto">Student Performance</h3>
      <div class="d-flex gap-2 my-3 w-100  justify-content-end ">
        <select
          placeholder="Select"
          className="form-select border-1"
          value={selectedSemester}
          onChange={handleSemChange}
        >
          <option>Semester</option>
          {semesterData?.data?.map?.((item) => {
            return (
              <option key={item?.id} value={item?.id}>
                {item?.semester_name}
              </option>
            );
          })}
        </select>

       
      </div>
      </div>
      <div className="performance-detail row">
        {data
          ?.filter((item) =>
            selectedSemester && selectedSemester !== "Semester"
              ? item.semester_id === Number(selectedSemester)
              : true
          )
          ?.map((student) => (
            <div className="row"> 
            <div className="col-4">
            <StudentCard
              studentData={student}
              image={StudentCardImg}
              // graph={chart}
            />
            </div>
              <div className="col-8">
                <p>{student?.comments}</p>
                <div/>
           </div>
           </div>
          ))}
        
      </div>
    </div>
  );
};

export default StudentPerformance;