import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import userimg from "../../assets/user.png";
import { getUserInfo } from "../../store/dashboard/dashboardThunk";
import { FaUser } from 'react-icons/fa';

const TopBarCard = ({ onProfileClick }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  const { userProfile } = useSelector((state) => state.reducer.dashboard);

  return (
    <div className="d-flex align-items-center gap-2 user-prof bg-white rounded-3 py-2 px-3" onClick={onProfileClick}>
    

{userProfile?.length > 0 && userProfile[0]?.profile_img ? (
  <div className="avatar" style={{width:"34px",height:"34px"}}>
      <img
          src={userProfile[0].profile_img}
          alt="Avatar"
          className="rounded-circle img-fluid"
        />
    </div>
      
      ) : (
        <div className="rounded-circle img-fluid">
          <FaUser className="mr-2" style={{ width: '38px', height: '24px' }} />
        </div>
      )}
      <div className="user-info">
        <p className="fw-bold" style={{fontSize:"16px"}}>{userProfile?.[0]?.username}</p>
        {/* <p className="">Super Admin</p> */}
      </div>
    </div>
  );
};

export default TopBarCard;
