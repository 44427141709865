import React from "react";
import { Stepper, Step } from "react-form-stepper";
import { useRef } from "react";
const ReportStepper = ({ activeStep, category }) => {
  const mainRef = useRef(null);
  const handleScroll = (e) => {
    const item = mainRef.current;
    if (e.deltaY > 0) item.scrollLeft += 100;
    else item.scrollLeft -= 100;
  };
  return (
    <div id="MAIN" ref={mainRef} onWheel={handleScroll}>
      <Stepper activeStep={activeStep}>
        <Step label="Name" />
        <Step label="Subject" />
        {category?.comments?.map?.((item) => (
          <Step label={item?.title} key={item?.id} />
        ))}


      </Stepper>
    </div>
  );
};

export default ReportStepper;
