import React from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { classValidationSchema } from "../../helpers/validationSchemas";
import FormikField from "../Common/InputField";
import SemesterMultiselect from "../Common/SemesterMultiselect";
import CommonButton from "../Common/CommonButton";

const EditClassModal = ({
  showEditModal,
  handleEditToggle,
  handleUpdateItem,
  selectedItem,
  semesterData,
  handleCloseToggle,
  setShowEditModal
}) => {
  const dataCheck = semesterData?.data?.map?.((option) => {
    return { value: option?.id, label: option?.semester_name };
  });
  const selectedItems = selectedItem
    ? dataCheck?.filter((item) =>
        selectedItem?.semester?.find((i) => item?.value === i?.id)
      )
    : null;


    const handleCancel =()=>{

      setShowEditModal(!showEditModal)
     
       }
  return (
    <Modal show={showEditModal} onHide={handleEditToggle}>
      <div class="modal-dialog modal-dialog-centere m-0 ">
        <div class="modal-content">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Edit Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Formik
              initialValues={{
                className: selectedItem?.name,
                classSemester: selectedItems,
              }}
              validationSchema={classValidationSchema}
              onSubmit={(values, { resetForm }) => {
                handleUpdateItem(values, selectedItem?.id);
              }}
            >
              {({ values }) => {
                return (
                  <Form >

                    <div className="row">
                    <div class="mb-3 col-6">
                      
                        <FormikField
                          name="className"
                          label="Class Name"
                          type="text"
                        />
                    </div>
                    <div className="col-6  multi-select">
                      <SemesterMultiselect
                        name="classSemester"
                        label="Semester"
                        options={dataCheck}
                        selectedItem={selectedItem}
                      />
                    </div>
                    </div>

                    <div className="d-flex flex-row gap-3  my-3">
                    
                    <CommonButton variant="light px-5" onClick={  handleCancel} text="Cancel"                          type="button"
/>
                    <CommonButton variant="primary px-5" text="Save" type="submit" />

                    
                  </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default EditClassModal;
