import {
  getLatestComments,
  getPerformance,
  getRecentActivity,
  getStats,
  getUserInfo,
  getStudentPerformance,
} from "./dashboardThunk";

export const reducerHelper = (builder) => {
  //* Get Stats Data ====================================
  builder.addCase(getStats.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getStats.fulfilled, (state, action) => {
    state.loading = false;
    state.stats = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getStats.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Get performance Data ====================================
  builder.addCase(getPerformance.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getPerformance.fulfilled, (state, action) => {
    state.loading = false;
    state.performance = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getPerformance.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Get latest comments Data ====================================
  builder.addCase(getLatestComments.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getLatestComments.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.latestComments = action.payload;
    state.status = "fulfilled";
  });
  builder.addCase(getLatestComments.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Get Recent Activities Data ====================================
  builder.addCase(getRecentActivity.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getRecentActivity.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.recentActivity = action.payload;
    state.status = "fulfilled";
  });
  builder.addCase(getRecentActivity.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Get User Info Data ====================================
  builder.addCase(getUserInfo.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getUserInfo.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.userProfile = action.payload;
    state.status = "fulfilled";
  });
  builder.addCase(getUserInfo.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Get Student Performance Data ====================================
  builder.addCase(getStudentPerformance.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getStudentPerformance.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.studentPerformance = action.payload;
    state.status = "fulfilled";
  });
  builder.addCase(getStudentPerformance.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });
};
