import { createSlice } from "@reduxjs/toolkit";
import { reducerHelper } from "./reducerHelper";

const initialState = {
  selectedTab: null,
  userProfile: "",
  status: "",
  loading: false,
  latestComments: [],
  stats: [],
  recentActivity: [],
  performance: [],
  error: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
  },

  // * Apiii Calls resultss
  extraReducers: reducerHelper,
});

// this is for dispatch
export const { setSelectedTab } = dashboardSlice.actions;

// this is for configureStore
export default dashboardSlice.reducer;
