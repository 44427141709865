import {
  addStudent,
  deleteStudent,
  getStudents,
  getStudentsBySemester,
  promoteStudents,
  updateStudent,
} from "./studentsThunk";

export const reducerHelper = (builder) => {
  //* Gender Data ====================================
  builder.addCase(getStudents.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getStudents.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getStudents.rejected, (state, action) => {
    state.loading = false;

    state.error = action.payload;
    state.status = "rejected";
  });
  //* Gender Data ====================================
  builder.addCase(getStudentsBySemester.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getStudentsBySemester.fulfilled, (state, action) => {
    state.loading = false;
    state.studentsBySemester = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getStudentsBySemester.rejected, (state, action) => {
    state.loading = false;

    state.error = action.payload;
    state.status = "rejected";
  });

  //* Add Gender Data ====================================
  builder.addCase(addStudent.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(addStudent.fulfilled, (state, action) => {
    state.loading = false;

    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(addStudent.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Gender Data ====================================
  builder.addCase(deleteStudent.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(deleteStudent.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(deleteStudent.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Gender Data ====================================
  builder.addCase(updateStudent.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(updateStudent.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(updateStudent.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });


//promote student 

builder.addCase(promoteStudents.pending, (state, action) => {
  state.loading = true;
  state.status = "pending";
});
builder.addCase(promoteStudents.fulfilled, (state, action) => {
  state.loading = false;
  state.error = null;
  state.status = "fulfilled";
  // Handle the action.payload if needed (updated data from the server)
});
builder.addCase(promoteStudents.rejected, (state, action) => {
  state.loading = false;
  state.error = action.payload;
  state.status = "rejected";
})}
