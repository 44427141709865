import { createSlice } from "@reduxjs/toolkit";
import { reducerHelper } from "./reducerHelpers";

const initialState = {
  status: "",
  loading: false,
  data: [],
  error: null,
};

export const commentSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},

  // * Apiii Calls resultss
  extraReducers: reducerHelper,
});

// this is for dispatch
export const {} = commentSlice.actions;

// this is for configureStore
export default commentSlice.reducer;
