import React from "react";
import { useNavigate } from "react-router-dom";

const StudentCard = ({
  studentData,
  image,
  graph,
  setSelectedStudent,
  selectedStudent,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/report", { state: { data: studentData } });
    localStorage.setItem("student", JSON.stringify(studentData));
    localStorage.setItem("semester", studentData.semester);
    setSelectedStudent(studentData);
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      className={`student-detail d-flex gap-4 mb-3  `}
    >
      
      <div className={`studend-profile  ${
        selectedStudent?.id === studentData?.id ? "active" : ""
      } ${!studentData?.student_img ? "grey-background" : ""}`}>
        <img src={studentData?.student_img} class="img-fluid" alt="" />
      </div>
      <div className="student-info text" onClick={handleClick}>
        <p className="title5 ">{studentData?.student_name}</p>
        <p className="span1 ">{`${studentData?.semester} / Class - ${
          studentData?.class_name || ""
        }`}</p>
      </div>
    </div>
  );
};

export default StudentCard;
