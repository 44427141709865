import React, { useEffect, useState } from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getToken } from "../../helpers/index.js";

import Sidebar from "../Common/Sidebar.jsx";
import TopBar from "../Common/TopBar.jsx";

function ProtectedRoutes({ isLoggedIn, topContent }) {
  const token = getToken();

  return (
    <>
      {isLoggedIn || token ? (
        <div className="dashboard  d-flex">
          <Sidebar topContent={topContent} />
          <div className="right-sidebar">
            <TopBar topContent={topContent} />
            <div className="container-fluid dashboard-content ps-0">
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default ProtectedRoutes;
