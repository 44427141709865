import { createSlice } from "@reduxjs/toolkit";
import { loginUser, registerUser } from "../auth/authThunk";
import { reducerHelper } from "./reducerHelper";

const initialState = {
  status: "",
  loading: false,
  data: [],
  error: null,
};

export const subjectsSlice = createSlice({
  name: "subject",
  initialState,
  reducers: {},

  // * Apiii Calls resultss
  extraReducers: reducerHelper,
});

// this is for dispatch
export const {} = subjectsSlice.actions;

// this is for configureStore
export default subjectsSlice.reducer;
