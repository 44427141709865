import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import CommonButton from "../../components/Common/CommonButton.jsx";
import FormikField from "../../components/Common/InputField.jsx";

import DataGrid from "../../components/Common/DataGrid.jsx";

import { useDispatch, useSelector } from "react-redux";
import {
  addSemester,
  deleteSemester,
  getSemesters,
  updateSemester,
} from "../../store/semester/semesterThunk.js";
import Loader from "../../components/Common/Loader.jsx";
import { semesterColumns } from "../../helpers/tableColumns.js";
import { semesterVaidationSchema } from "../../helpers/validationSchemas.js";
import DeleteModal from "../../components/Common/DeleteModal.jsx";
import EditSemesterModal from "../../components/Models/EditSemesterModal.jsx";
import SemesterMultiselect from "../../components/Common/SemesterMultiselect.jsx";
import FormikMultiSelect from "../../components/Common/MultiSelect.jsx";
import { getSubjects } from "../../store/subjects/subjectThunk.js";
import SubjectMultiSelect from "../../components/Common/subjectMultiselect.jsx";
import { getClass } from "../../store/class/classThunk.js";
import SemesterSelect from "../../components/Common/SemesterSelect.js";
import AddSemesterModal from "../../components/AddModals/AddSemesterModal.jsx";
const Semester = () => {
  const [showAddModal, setShowAddModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [closeModal, setCloseModal] = useState(false);

  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.reducer.semester);
  const subjectData = useSelector((state) => state.reducer.subjects);
  const classData = useSelector((state) => state.reducer.class);
  const numberOfItems = data ? data.length : 0;
  const numbersArray = Array.from({ length: numberOfItems }, (_, index) => ({ value: index + 1 }));


   // Combine numbersArray with data
   const combinedData = data?.map((semester, index) => ({
    ...semester,
    number: numbersArray[index]?.value,
  }));
  useEffect(() => {
    dispatch(getSemesters());
    dispatch(getSubjects());
    dispatch(getClass());
  }, [dispatch]);

  const handleDeleteToggle = async (row) => {
    setselectedItem(row);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteItem = () => {
    dispatch(deleteSemester(selectedItem.id));
    handleDeleteToggle();
  };

  const handleEditToggle = (row) => {
    setselectedItem(row);
    setShowEditModal(!showEditModal);
  };

  const handleUpdateItem = (values, id) => {
    values.id = id;
    let ids = [];
    values?.subjects?.map?.((subj) => {
      ids.push(subj.value);
    });
    delete values.subjects;
    values.subjects = ids;
    dispatch(updateSemester(values));
    // resetForm();
    handleEditToggle();
  };

  const columns = semesterColumns(
    handleEditToggle,
    handleDeleteToggle,
    subjectData?.data,
    data
  );
  const handleAddToggle = (row) => {
    
    setShowAddModal(!showAddModal);
  };
  const handleCloseToggle = (row) => {
    setCloseModal(!closeModal);
  };
  const addSemesterHandler = (values, resetForm) => {
    let ids = [];
    values?.subjects?.map((subj) => {
      ids.push(subj.value);
    });
    delete values.subjects;
    values.subjects = ids;
    dispatch(addSemester(values)).then(() => {
      resetForm();
    });
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
     <AddSemesterModal
        showAddModal={showAddModal}
        handleAddToggle={handleAddToggle}
        addSemester={addSemester}
       
      />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteToggle={handleDeleteToggle}
        handleDeleteItem={handleDeleteItem}
      />
      <EditSemesterModal
        showEditModal={showEditModal}
        handleEditToggle={handleEditToggle}
        handleUpdateItem={handleUpdateItem}
        selectedItem={selectedItem}
        subjectData={subjectData}
        data={data}
        setShowEditModal={setShowEditModal}

        handleCloseToggle={handleCloseToggle}
      />

<div className=" w-100 d-flex justify-content-end my-3">
      <CommonButton
       variant="primary"
       text='+ Create New'
       onClick={() => handleAddToggle()}
       />
        </div>
      <div className="data-table-container">
        <h3 className="title3 mb-4 px-3">Semester List</h3>
        <DataGrid columns={columns} data={combinedData} />
      </div>
    </>
  );
};

export default Semester;
