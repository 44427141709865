
import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api, { apiPrivate } from "../../api";

export const getCategory = createAsyncThunk(
  "commentcategory/getCategory",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/comments/category/");
      return response.data;
    } catch (error) {
      const message = error.response.data;

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCategoryStepper = createAsyncThunk(
  "commentcategory/getCategoryStepper",
  async (data, thunkAPI) => {
    try {
      if (data) {
        const response = await apiPrivate.get(
          `api/pronoun/comments/${data?.id}/${
            data?.subject_name ? `?subject=${data?.subject_name}` : ""
          }`
        );
        return response.data;
      }
    } catch (error) {
      const message = error.response.data;

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addCategory = createAsyncThunk(
  "commentcategory/addCategory",
  async (data, { dispatch }, thunkAPI) => {
    try {
      const response = await apiPrivate.post("api/comments/category/", {
        name: data,
      });
      dispatch(getCategory());
      toast.success("Successfully added Comment Category");
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const message = error.response.data[0];

        toast.error(message);
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const deleteComment = createAsyncThunk(
  "commentcategory/deleteComment",
  async (id, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.delete(`api/comments/category/${id}/`);
      dispatch(getCategory());
      toast.success("Successfully Deleted Comment Category");
    } catch (error) {
      const message = error.response.data;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "commentcategory/updateCategory",
  async (data, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.put(`api/comments/category/${data?.id}/`, {
        name: data.categoryName,
      });
      dispatch(getCategory());
      toast.success("Successfully Updated Comment Category");
    } catch (error) {
      const message = error.response.data;
      toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);
