import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import api, { apiInstance, apiPrivate } from "../../api";
import Cookies from "universal-cookie";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserInfo } from "../dashboard/dashboardThunk";
const cookies = new Cookies();


// Function to authenticate user and store the access token
const authenticateUser = (accessToken) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  localStorage.setItem("access_token", accessToken);
  sessionStorage.setItem("access_token", accessToken);
};

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (credentials, thunkAPI) => {
    // Check if the user is already logged in
    // const navigate = useNavigate();
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      credentials.navigate("/");
      toast.error("User is already logged in!");
      return thunkAPI.rejectWithValue("User is already logged in");
    }

    try {
     if(!storedUser){

     
        const response = await api.post("api/login/", {
          email: credentials.teacherId,
          password: credentials.password,
        });

        const accessToken = response.data.access;
        authenticateUser(accessToken);

        localStorage.setItem("user", JSON.stringify(response.data));
        credentials.navigate("/", { replace: true });
        toast.success("Successfully logged in");

        return response.data;
     }
    } catch (error) {
      const message = error.response?.data?.error?.[0] || "Something went wrong";
      toast.error(message);

      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const logout = createAsyncThunk("auth/logout", async (thunkAPI) => {
  try {
    await apiPrivate.post("api/logout/");
    localStorage.clear();
    sessionStorage.clear();

    toast.success("Successfully logged out");
  } catch (error) {
    const message = error.response?.data || "Something went wrong";
    toast.error(message);
    return thunkAPI.rejectWithValue(message);
  }
});




export const signupUser = createAsyncThunk(
  "auth/signupUser",
  async (userData, thunkAPI) => {
    try {
      const response = await apiInstance.post("accounts/register/", {
        username:userData?.teacherId,
        password:userData?.password,
        email:userData?.email
,contact_number:userData?.contactNumber
      });

      toast.success("Successfully signed up");
      
      return response.data;


    } catch (error) {
      console.error("Error during signup:", error);

      const message = error?.response?.data?.email?.[0] || error?.response?.data?.error|| "An error occurred during signup";
      toast.error(message);

      // Use rejectWithValue to pass an error message to the rejected action
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// New async thunk for resetting password
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (email, thunkAPI) => {
    try {
      // Send a POST request to the password reset API endpoint
      const response = await apiInstance.post("accounts/password_reset/", {
        email: email,
      });

      // Handle the response accordingly (e.g., show a success message)
      toast.success("Password reset email sent successfully");

      return response.data;
      
    } catch (error) {
      // Handle errors (e.g., show an error message)
      const message = error.response?.data || "Something went wrong";
      toast.error(message);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// authThunk.js


export const resetPasswordConfirm = createAsyncThunk(
  'auth/resetPasswordConfirm',
  async (data,{ rejectWithValue }) => {
    try {

      const response = await apiInstance.post(
        'accounts/password_reset/confirm/',
        {
          token: data.token,
          password: data.password,
        }
      );
      toast.success('Password reset successful. You can now log in with your new password.');


      return response.data;
    } catch (error) {
      // Handle errors (e.g., show an error message)
      const message = error.response?.data?.password?.[0] ||  'Something went wrong';
      console.log(message,"6767676")
      toast.error(message);

      return rejectWithValue(message);
    }
  }
);



export const resetPasswordConfirm2 = createAsyncThunk(
  'auth/resetPasswordConfirm2',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiPrivate.post(
        'accounts/change_password/',
        {
          old_password: data.oldPassword, // Add old password to the request payload
          token: data.token,
          new_password: data.password,
        }
      );

      // Handle the response accordingly (e.g., show a success message)
      toast.success('Password reset successful. You can now log in with your new password.');

      return response.data;
    } catch (error) {
      // Handle errors (e.g., show an error message)
  
        toast.error( error?.response?.data?.error);
      
      // toast.error(message);
      const message = error.response?.data?.error || 'Something went wrong';

      return rejectWithValue(message);
    }
  }
);
