import React from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import {
  commentsValidationSchema,
  semesterVaidationSchema,
} from "../../helpers/validationSchemas";
import FormikField from "../Common/InputField";
import FormikTextarea from "../Common/TextArea";
import FormikSelect from "../Common/DropDown";
import CommentTitleDropdown from "../Common/CommentTitleDropdown";

const EditCommentModal = ({
  showEditModal,
  handleEditToggle,
  handleUpdateItem,
  selectedItem,
  commentCategory,
  handleCloseToggle,
  setShowEditModal
}) => {
  const handleCancel =()=>{

    setShowEditModal(!showEditModal)
   
     }
  return (
    <Modal show={showEditModal} onHide={handleEditToggle}>
      <div class="modal-dialog modal-dialog-centere m-0">
        <div class="modal-content">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Edit Comment</Modal.Title>
        </Modal.Header>
<Modal.Body>
            <Formik
              initialValues={{
                commentTitle: selectedItem?.title_id,
                commentText: selectedItem?.comment,
              }}
              // validationSchema={commentsValidationSchema}
              onSubmit={(values, { resetForm }) => {
                handleUpdateItem(values, selectedItem?.id);
              }}
            >
              {({ values }) => {
                return (
                  <Form>
                    <div class="mb-3">
                      <CommentTitleDropdown
                        name="commentTitle"
                        label="Comment Title"
                        options={commentCategory?.data}
                      />
                    </div>
                    <div class="mb-3">
                      <FormikField
                        name="commentText"
                        label="Comment"
                        placeholder="Add Comment text"
                      />
                    </div>
                   
                    
                    <div className="d-flex mt-5 mb-3 gap-3 ">
                      <button
                        type="button"
                        class="btn btn-light"
                        data-bs-dismiss="modal"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        class="btn text-white  bg-primary "
                        // onClick={handleUpdateItem}
                      >
                        Save Changes
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default EditCommentModal;
