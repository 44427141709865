import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { semesterVaidationSchema } from "../../helpers/validationSchemas.js";

import FormikField from "../Common/InputField.jsx";
import CommonButton from "../Common/CommonButton.jsx";
import SubjectCategoryDropdown from "../Common/SubjectCategoryDropdown.js";
import SubjectMultiSelect from "../Common/subjectMultiselect.jsx";
import SemesterSelect from "../Common/SemesterSelect.js";

const AddSemesterModal = ({
  showAddModal,
  handleAddToggle,
  addSemester
}) => {
    const dispatch = useDispatch();
    const [saveButton, setSaveButton] = useState(false)

    const category = useSelector((state) => state.reducer.subjectcategory);
    const { data, loading } = useSelector((state) => state.reducer.semester);
    const subjectData = useSelector((state) => state.reducer.subjects);
    const classData = useSelector((state) => state.reducer.class);
  
    const addSemesterHandler = (values, resetForm,shouldResetForm) => {
        let ids = [];
        values?.subjects?.map((subj) => {
          ids.push(subj.value);
        });
        delete values.subjects;
        values.subjects = ids;
       
        dispatch(addSemester(values)).then(() => {
          if (shouldResetForm === false && saveButton === false) {
            resetForm();
          } else{
            handleAddToggle()
          }
        });
      };
  return (
    <>
  
      <Modal show={showAddModal} onHide={handleAddToggle}>
        <div class="modal-dialog modal-dialog-centered m-0 ">
          <div class="modal-content p-4 ">
            {/* <div class="modal-body mx-auto">
              
            </div> */}

<div className="add-semester mb-3">
<Modal.Header closeButton>
          <Modal.Title className="fw-bold">Add Semester</Modal.Title>
        </Modal.Header>     
        <Modal.Body>        <Formik
          initialValues={{
            semesterName: "",
            semesterStartDate: "",
            semesterEndDate: "",
            subjects: "",
            nextSemester: "",
            className: "",
          }}
          validationSchema={semesterVaidationSchema}
          onSubmit={(values, { resetForm }) =>
            addSemesterHandler(values, resetForm,false)
          }
        >
          {({ values }) => {
            return (
              <Form className="row">
                <div className="col-6 col-lg-6">
                  <FormikField
                    name="semesterName"
                    label="Semester Name"
                    type="text"
                    value={values.semesterName}
                  />
                </div>
                <div className="col-6 col-lg-6">
                  <FormikField
                    name="semesterStartDate"
                    label="Semester Start Date"
                    type="date"
                    value={values.semesterStartDate}
                  />
                </div>
                <div className="col-6 col-lg-6">
                  <FormikField
                    name="semesterEndDate"
                    label="Semester End Date"
                    type="date"
                    value={values.semesterEndDate}
                  />
                </div>

                <div className=" col-6 selectSubject">
                  <SubjectMultiSelect
                    name="subjects"
                    label="Subject"
                    value={values.semesterSubject}
                    options={subjectData?.data}
                  />
                </div>
                <div className="col-6">
                  <SemesterSelect
                    name="nextSemester"
                    label="Next Semester"
                    value={values.nextSemester}
                    selected={values.semesterEndDate}
                    options={data}
                    type="text"
                    data={data}
                  />
                </div>
                <div className="select-btn mt-5 d-flex gap-3 ">

              <CommonButton variant="light px-5"   onClick={handleAddToggle} text="Cancel"/>
              <CommonButton variant="primary px-4" text="Save & Add New" type="submit" />

              <CommonButton variant="primary px-5" text="Save" type="submit" 
               onClick={(values) =>
                setSaveButton(true)
              } />


            </div>
              </Form>
            );
          }}
        </Formik>
        </Modal.Body>
      </div>
           
          </div>
        </div>
        
      </Modal>
    </>
  );
};

export default AddSemesterModal;
