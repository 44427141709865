import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { semesterVaidationSchema } from "../../helpers/validationSchemas";
import FormikField from "../Common/InputField";
import FormikMultiSelect from "../Common/MultiSelect";
import SubjectMultiSelect from "../Common/subjectMultiselect";
import SemesterSelect from "../Common/SemesterSelect";
import { useSelector } from "react-redux";
import CommonButton from "../Common/CommonButton";

const EditSemesterModal = ({
  showEditModal,
  handleEditToggle,
  handleUpdateItem,
  selectedItem,
  subjectData,
  handleCloseToggle,
  setShowEditModal
  // data,
}) => {
  const { data } = useSelector((state) => state.reducer.semester);

  const dataCheck = subjectData?.data?.map((option) => {
    return { value: option?.id, label: option?.subject_name };
  });

  const handleCancel =()=>{

    setShowEditModal(!showEditModal)
   
     }
  // Pass the selected item as a prop to the Select component
  const selectedItems = selectedItem
    ? dataCheck.filter((item) =>
        selectedItem?.subject?.find((i) => item?.value === i)
      )
    : null;

  return (
    <Modal show={showEditModal} onHide={handleEditToggle}>
      <div class="modal-dialog modal-dialog-centere m-0">
        <div class="modal-content">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Edit Semester</Modal.Title>
        </Modal.Header>
<Modal.Body>
            <Formik
              initialValues={{
                semesterName: selectedItem?.semester_name || "",
                semesterStartDate: selectedItem?.semester_start_date || "",
                semesterEndDate: selectedItem?.semester_end_date || "",
                subjects: selectedItems,
                nextSemester: selectedItem?.next_semesters_ids?.[0],
              }}
              // validationSchema={semesterVaidationSchema}
              onSubmit={(values, { resetForm }) => {
                handleUpdateItem(values, selectedItem?.id);
              }}
            >
              {({ values }) => {
                return (
                  <Form>

                    <div className="row">
                    <div class="mb-3 col-6">
                      <FormikField
                        name="semesterName"
                        label="Semester Name"
                        type="text"
                        // value={values.semesterName}
                      />
                    </div> 
                    <div class="mb-3 col-6">
                      <FormikField
                        name="semesterStartDate"
                        label="Semester Start Date"
                        type="date"
                        // value={values.semesterStartDate}
                      />
                    </div>
                    </div>
                    <div className="row ">
                    <div class="mb-3 col-6 ">
                      <FormikField
                        name="semesterEndDate"
                        label="Semester End Date"
                        type="date"
                        // value={values.semesterEndDate}
                      />
                    </div>
                    <div class="mb-3 col-6">
                      <FormikMultiSelect
                        name="subjects"
                        label="Subject"
                        options={dataCheck}
                        selectedItem={selectedItems}
                      />
                    </div>
                    </div>
                    <div className="row">

                    <div class="mb-3 col-6">

                      <SemesterSelect
                        name="nextSemester"
                        label="Next Semester"
                        value={values.nextSemester}
                        options={data}
                        displayProperty="nextSemester"
                      />
                    </div>
                    </div>

                    <div className="d-flex flex-row gap-3   mt-5 mb-3">
                    
                      <CommonButton variant="light px-5" onClick={handleCancel} text="Cancel"                         type="button"
 />
                      <CommonButton variant="primary px-5" text="Save" type="submit" />

                      
                    </div>
                  </Form>
                );
              }}
            </Formik>
            </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default EditSemesterModal;
