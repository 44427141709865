import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import DeleteModal from "../../components/Common/DeleteModal.jsx";
import CommonButton from "../../components/Common/CommonButton.jsx";
import FormikField from "../../components/Common/InputField.jsx";
import FormikSelect from "../../components/Common/DropDown";
import FormikMultiSelect from "../../components/Common/MultiSelect.jsx";
import DataGrid from "../../components/Common/DataGrid.jsx";
import imageicon from "../../assets/imageicon.png";

import Loader from "../../components/Common/Loader.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubject,
  deleteSubject,
  getSubjects,
  updateSubject,
} from "../../store/subjects/subjectThunk.js";
import { getSubjectCategory } from "../../store/subjectCategory/subjectCategoryThunk.js";
import { subjectsColumns } from "../../helpers/tableColumns.js";
import { subjectValidationSchema } from "../../helpers/validationSchemas.js";
import EditSubjectModal from "../../components/Models/EditSubjectModal.jsx";
import CategoryDropdown from "../../components/Common/CategoryDropdown.jsx";
import SubjectCategoryDropdown from "../../components/Common/SubjectCategoryDropdown.js";
import AddSubjectModal from "../../components/AddModals/AddSubjectModal.jsx";

const Subjects = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedEditImage, setSelectedEditImage] = useState(null);
  const [closeModal, setCloseModal] = useState(false);

  const [imagePreview, setImagePreview] = useState();

  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.reducer.subjects);
  const category = useSelector((state) => state.reducer.subjectcategory);

  const handleCloseToggle = (row) => {
    setCloseModal(!closeModal);
  };
  const numberOfItems = data ? data.length : 0;
  const numbersArray = Array.from({ length: numberOfItems }, (_, index) => ({ value: index + 1 }));
  
  const handleDeleteToggle = async (row) => {
    setselectedItem(row);
    setShowDeleteModal(!showDeleteModal);  };

  const handleAddToggle = (row) => {
    
    setShowAddModal(!showAddModal);
  };
  const handleDeleteItem = () => {
    dispatch(deleteSubject(selectedItem?.id));
    handleDeleteToggle();
  };

  const handleEditToggle = (row) => {
    setselectedItem(row);
    setShowEditModal(!showEditModal);
  };

  const handleUpdateItem = (values, id) => {
    values.id = id;
    if (!values?.subjectName) {
      return;
    }
    values.selectedImg = selectedImage;
    dispatch(updateSubject(values)).then(() => {
      setImagePreview("");
    });
    handleEditToggle();
  };

  const columns = subjectsColumns(handleEditToggle, handleDeleteToggle);

  useEffect(() => {
    dispatch(getSubjects());
    dispatch(getSubjectCategory());
  }, [dispatch]);


  const combinedData = data.map((subject, index) => ({
    ...subject,
    number: numbersArray[index]?.value,
  }));


  const addSubjectHandler = (values, resetForm) => {
    if (!values?.subjectName) {
      return;
    }
    values.selectedImg = selectedImage;

    dispatch(addSubject(values)).then((data) => {
      setImagePreview("");
      resetForm();
    });
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
     <AddSubjectModal
        showAddModal={showAddModal}
        handleAddToggle={handleAddToggle}
        addSubject={addSubject}
       
      />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteToggle={handleDeleteToggle}
        handleDeleteItem={handleDeleteItem}
      />
      
      <EditSubjectModal
        showEditModal={showEditModal}
        handleCloseToggle={handleCloseToggle}
        setShowEditModal={setShowEditModal}

        handleEditToggle={handleEditToggle}
        handleUpdateItem={handleUpdateItem}
        selectedItem={selectedItem}
        data={data}
        category={category.data}
      />

<div className=" w-100 d-flex justify-content-end my-3">
      <CommonButton
       variant="primary"
       text='+ Create New'
       onClick={() => handleAddToggle()}
       />
        </div>
      <div className="data-table-container subject-list">
        <h3 className="title3 mb-4 px-3">Subjects List</h3>
        <DataGrid columns={columns} data={combinedData} />
      </div>
    </>
  );
};

export default Subjects;
