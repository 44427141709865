import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import CommonButton from "../../components/Common/CommonButton.jsx";
import FormikField from "../../components/Common/InputField.jsx";

import DataGrid from "../../components/Common/DataGrid.jsx";
import FormikTextarea from "../../components/Common/TextArea.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addClass,
  deleteClass,
  getClass,
  updateClass,
} from "../../store/class/classThunk";

import Loader from "../../components/Common/Loader.jsx";
import { ClassColumns, classColumns } from "../../helpers/tableColumns.js";
import { classValidationSchema } from "../../helpers/validationSchemas.js";
import DeleteModal from "../../components/Common/DeleteModal.jsx";
import EditFormModal from "../../components/Models/EditSemesterModal.jsx";
import EditCommentModal from "../../components/Models/EditCommentModal.jsx";
import FormikSelect from "../../components/Common/DropDown.jsx";
import { getCategory } from "../../store/commentCategory/commentCategoryThunk.js";
import EditClassModal from "../../components/Models/EditClassModal.js";
import { getSemesters } from "../../store/semester/semesterThunk.js";
import SemesterMultiselect from "../../components/Common/SemesterMultiselect.jsx";
import AddClassModal from "../../components/AddModals/AddClassModal.jsx";

const Class = () => {
  const [showAddModal, setShowAddModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [closeModal, setCloseModal] = useState(false);

  const dispatch = useDispatch();

  const { data, loading } = useSelector((state) => state.reducer.class);
  const semesterData = useSelector((state) => state.reducer.semester);


  const numberOfItems = data ? data.length : 0;
  const numbersArray = Array.from({ length: numberOfItems }, (_, index) => ({ value: index + 1 }));


   // Combine numbersArray with data
   const combinedData = data?.map((classes, index) => ({
    ...classes,
    number: numbersArray[index]?.value,
  }));
  useEffect(() => {
    dispatch(getClass());
    dispatch(getSemesters());
  }, [dispatch]);

  const handleDeleteToggle = async (row) => {
    setselectedItem(row);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteItem = () => {
    dispatch(deleteClass(selectedItem?.id));
    handleDeleteToggle();
  };

  const handleEditToggle = (row) => {
    setselectedItem(row);
    setShowEditModal(!showEditModal);
  };
  const handleAddToggle = (row) => {
    
    setShowAddModal(!showAddModal);
  };
  const handleCloseToggle = (row) => {
    setCloseModal(!closeModal);
  };

  const handleUpdateItem = (values, id) => {
    values.id = id;
    let ids = [];
    values?.classSemester?.map?.((sem) => {
      ids.push(sem.value);
    });
    delete values.classSemester;
    values.classSemester = ids;
    dispatch(updateClass(values));
    handleEditToggle();
  };

  const columns = classColumns(
    handleEditToggle,
    handleDeleteToggle,
    semesterData
  );

  const addClassHandler = (values, resetForm) => {
    let ids = [];
    values?.classSemester?.map((sem) => {
      ids.push(sem.value);
    });
    delete values.classSemester;
    values.classSemester = ids;

    dispatch(addClass(values)).then(() => {
      resetForm();
    });
  };

  if (loading) {
    return <Loader />;
  }

 
  return (
    <div className="comment-page">
       <AddClassModal
        showAddModal={showAddModal}
        handleAddToggle={handleAddToggle}
        addClass={addClass}
       
      />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteToggle={handleDeleteToggle}
        handleDeleteItem={handleDeleteItem}
      />
      <EditClassModal
        showEditModal={showEditModal}
        handleEditToggle={handleEditToggle}
        handleUpdateItem={handleUpdateItem}
        selectedItem={selectedItem}
        classData={data}
        semesterData={semesterData}
        handleCloseToggle={handleCloseToggle}
        setShowEditModal={setShowEditModal}

      />

<div className=" w-100 d-flex justify-content-end my-3">
      <CommonButton
       variant="primary"
       text='+ Create New'
       onClick={() => handleAddToggle()}
       />
        </div>
      <div className="data-table-container comment-table">
        <h3 className="title3 mb-4 px-3">Classes</h3>
        <DataGrid columns={columns} data={combinedData} />
      </div>
    </div>
  );
};

export default Class;
