import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import FormikField from "../../components/Common/InputField.jsx";
import CommonButton from "../../components/Common/CommonButton.jsx";

import login from "../../assets/login-img.png";
import logo from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, resetPassword } from "../../store/auth/authThunk";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, loading, error, status } = useSelector(
    (state) => state.reducer.auth
  );

  const loginHandler = async (values) => {
    values.navigate = navigate;
    dispatch(loginUser(values));
  };

  const validationSchema = Yup.object().shape({
    teacherId: Yup.string().required("Teacher Id is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);

  const toggleForgetPasswordModal = () => {
    setShowForgetPasswordModal(!showForgetPasswordModal);
  };

  const handleForgetPasswordClose = () => setShowForgetPasswordModal(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Dispatch the resetPassword action with the entered email
      await dispatch(resetPassword(values.email));

      // Close the modal
      setShowForgetPasswordModal(false);
    } catch (error) {
      // Handle errors if needed
      console.error("Error resetting password:", error);
    } finally {
      // Reset the form submission state
      setSubmitting(false);
    }
  };
  return (
    <div className="container-fluid  bg-f2f6ff ">
      <div class="row align-items-center">
        <div class="col-sm-12 col-md-6 col-lg-7 p-0">
          <div class="rgtside-login p-5">
            <div class="bootom-img">
              <img src={login} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-5 p-0 border-12">
          <div className="login-form-holder  bg-white border-12 ">
            <div class="logIn-form px-5 pt-2 pb-0">
              <div className="logo ">
                <img src={logo} class="img-fluid" alt="" />
              </div>
              <h2 className="title1">Login</h2>
              <p className="title5 my-1">Welcome Back Teacher</p>
              <Formik
                initialValues={{ teacherId: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={(values) => loginHandler(values)}
              >
                <Form className="my-3">
                  <div className="mb-3 width-100">
                    <FormikField
                      name="teacherId"
                      label="Teacher ID"
                      type="text"
                      placeholder="Enter Teacher ID"
                    />
                  </div>
                  <div className="mb-3 width-100">
                    <FormikField
                      name="password"
                      label="Password"
                      type="password"
                      placeholder="Password"
                    />
                  </div>
                  <p className='forget-text text-end mb-2 ' onClick={toggleForgetPasswordModal}>Forgot Password?</p>

                 
                  <CommonButton variant="primary" text="Login" type="submit" />

                  <div className=" text-center my-3">
                    Don't have an account? <Link to="/signup" className=" text-decoration-none ">Sign up for free</Link>
                  </div>
                </Form>
              </Formik>
              <div className="bottom-sec">
               

                <p className="copy-right span1 mb-3 ">
                  All Rights Reserved <span>© ClassView360</span>
                </p>
              </div>
              {/* Forget Password Modal */}
      <Modal show={showForgetPasswordModal} onHide={handleForgetPasswordClose}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Form for email input in the modal */}
          
          <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("Invalid email address").required("Email is required"),
          })}
          onSubmit={handleSubmit} // Pass setSubmitting to the handleSubmit function
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="mb-3 w-50 ">
                <FormikField name="email" label="Email" type="text" />
                
              </div>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
        </Modal.Body>
      </Modal>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
