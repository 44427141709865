import React, { useState } from "react";

import { ErrorMessage, Field } from "formik";

const SemesterSelect = ({
  name,
  label,
  selected,
  options,
  onChange,
  disabled,
  classData,
  data,
}) => {
  const [selectedEndDate, setSelectedEndDate] = useState("");
  return (
    <Field as="select" name={name}>
      {(formikField) => {
        const res = options?.filter((item) => {
          return (
            item?.semester_end_date > formikField.form.values.semesterEndDate
          );
        });

        return (
          <>
            <div>
              {label && (
                <label for="" class="form-label">
                  {label}
                </label>
              )}
              <select
                placeholder="Select"
                className="form-select"
                value={formikField?.field?.value || ""}
                {...formikField?.field}
              >
                <option>Select</option>
              

                {res?.map?.((item) => {
                  return (
                    <option key={item?.id} value={item?.id}>
                      {item?.semester_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <ErrorMessage name={name}>
              {(errMessage) => {
                return <div className="err-msg">{errMessage}</div>;
              }}
            </ErrorMessage>
          </>
        );
      }}
    </Field>
  );
};

export default SemesterSelect;
