import React from "react";

const SubjectForm = ({
  label,
  name,
  type,
  value,
  onChange,
  subjectname,
  subjectsIds,
}) => {


  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <select
        name=""
        id=""
        className="form-control"
        onChange={onChange}
        value={value}
      >
        <option>Select </option>
        {subjectname?.map?.((item) => {
          return <option value={item?.id}>{item?.subject_name}</option>;
        })}
      </select>
    </div>
  );
};

export default SubjectForm;
