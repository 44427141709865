import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CSVLink } from "react-csv";
import { apiPrivate } from "../../api";
// import jsPDF from "jspdf";
import { saveAs } from 'file-saver';

export const ExportCommentPDF = createAsyncThunk(
  "allcommentpdf/ExportCommentPDF",

  async (id, thunkAPI) => {
    try {
      const response = await apiPrivate.get(
        `api/export/csv/${id?.[0]}/${id?.[1]}/${id?.[2]}`, { responseType: 'blob' }
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      saveAs(pdfBlob, `student-list.pdf`);
      }
      catch (error) {
        console.error('Error generating pdf:', error);
    }})


const csvSlice = createSlice({
  name: "allcommentpdf",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [ExportCommentPDF.pending]: (state) => {
      state.status = "loading";
    },
    [ExportCommentPDF.fulfilled]: (state) => {
      state.status = "succeeded";
    },
    [ExportCommentPDF.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { setData } = csvSlice.actions;

export default csvSlice.reducer;
