import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Form, Formik } from "formik";
import { classValidationSchema } from "../../helpers/validationSchemas.js";
import { useDispatch, useSelector } from "react-redux";
import FormikField from "../Common/InputField.jsx";
import CommonButton from "../Common/CommonButton.jsx";
import SemesterMultiselect from "../Common/SemesterMultiselect.jsx";

const AddClassModal = ({ showAddModal, handleAddToggle, addClass }) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.reducer.class);
  const semesterData = useSelector((state) => state.reducer.semester);

  const [saveButton, setSaveButton] = useState(false)

  const addClassHandler = (values, resetForm, shouldResetForm) => {
    let ids = [];
    values?.classSemester?.map((sem) => {
      ids.push(sem.value);
    });
    delete values.classSemester;
    values.classSemester = ids;

    dispatch(addClass(values)).then(() => {
      if (shouldResetForm === false && saveButton === false) {
        resetForm();
      } else{
        handleAddToggle()
      }
    });
  };

  const dataCheck = semesterData?.data?.map?.((option) => {
    return { value: option?.id, label: option?.semester_name };
  });

  return (
    <>
      <Modal show={showAddModal} onHide={handleAddToggle}>
        <div className="modal-dialog modal-dialog-centered m-0 ">
          <div className="modal-content p-4 ">
            <div className="mb-3">
              {/* <h3 className="title3 mb-3">Add class</h3> */}
              <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Add Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <div className="row">
                <div className="col-12 col-lg-12">
                  <Formik
                    initialValues={{
                      className: "",
                      classSemester: "",
                    }}
                    validationSchema={classValidationSchema}
                    onSubmit={(values, { resetForm }) => {
                      addClassHandler(values, resetForm, false)

                    }
                    }
                  >
                    <Form className="row">
                      <div className="col-xs-12 col-sm-6 col-6  ">
                        <FormikField
                          name="className"
                          label="Class Name"
                          type="text"
                          placeholder="Enter Class Name"
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6  multi-select col-6 ">
                        <SemesterMultiselect
                          name="classSemester"
                          label="Semester"
                          options={dataCheck}
                          placeholder="Select Semester"
                        />
                      </div>
                      <div className="select-btn mt-5 d-flex gap-3 ">
                        <CommonButton
                          variant="light px-5 "
                          type="button"
                          onClick={handleAddToggle}
                          text="Cancel"
                        />
                        <CommonButton
                          variant="primary px-4"
                          text="Save & Add New"
                          type="submit"
                        />
                        <CommonButton
                          variant="primary px-5"
                          text="Save"
                          type="submit"
                          onClick={(values) =>
                            setSaveButton(true)
                          }
                        />
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
              </Modal.Body>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddClassModal;
