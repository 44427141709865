export const setToken = (token) => {
  localStorage.setToken("access_token", token);
};

export const getToken = () => {
  return localStorage.getItem("access_token");
};

export const setSelectedTab = (item) => {
  localStorage.setItem("selectedTab", JSON.stringify(item));
};

export const getSelectedTab = () => {
  return JSON.parse(localStorage.getItem("selected-tab"));
};
export const getSelectedStudent = () => {
  return JSON.parse(localStorage.getItem("student"));
};
