import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Papa from "papaparse";
import api, { apiPrivate } from "../../api";

export const downloadCSV = createAsyncThunk(
  "csv/downloadCSV",
  async (thunkAPI) => {
    try {
      const response = await apiPrivate.get(`api/students/csv/`);

      // Remove the first row (index 0) from the CSV data
      const csvDataWithoutHeader = response.data.slice(1);

      const csvData = Papa.unparse(csvDataWithoutHeader);
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data.csv";
      a.click();

      return csvData;
    } catch (error) {
      const message = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const csvSlice = createSlice({
  name: "csv",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [downloadCSV.pending]: (state) => {
      state.status = "loading";
    },
    [downloadCSV.fulfilled]: (state) => {
      state.status = "succeeded";
    },
    [downloadCSV.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { setData } = csvSlice.actions;

export default csvSlice.reducer;
