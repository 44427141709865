import React from "react";
import { useNavigate } from "react-router-dom";
import dummy from "../../assets/icons/Group 2350.png"
const SidebarItem = ({ icon, text, selected }) => {

  const navigate = useNavigate();

  return (
    <div
      className={`d-flex align-items-center sidebar-item gap-2 ${
        selected && selected.text === text ? "active" : ""
      } ${text === "Logout" ? "logout" : ""}`}
    >
      {React.cloneElement(icon, {
        className: `sidebar-icon ${
          selected && selected.text === text ? "active" : ""
        }`,
        onMouseEnter: () => {
          icon.props.onMouseEnter && icon.props.onMouseEnter();
        },
        onMouseLeave: () => {
          icon.props.onMouseLeave && icon.props.onMouseLeave();
        },
        onClick: () => {
          icon.props.onClick && icon.props.onClick();
          navigate(icon.props.url);
        },
      })}
      <p className="title5">{text}</p>
    </div>
  );
};


export default SidebarItem;
