import axios from "axios";
import { getToken } from "../helpers";


const user = JSON.parse(localStorage?.getItem("user"));
const token = localStorage.getItem("access_token");
const sessionToken = sessionStorage.getItem("access_token");

const BASE_URL = {
  local: "http://153.92.222.214:8080/",
  server: "http://153.92.222.214:8080/",

};

const api = axios.create({
  baseURL: BASE_URL.server,
});

export const apiPrivate = axios.create({
  baseURL: BASE_URL.server,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  },
});
export const apiInstance = axios.create({
  baseURL: BASE_URL.server,
 
});
apiPrivate.interceptors.request.use(
  (config) => {
    const accessToken =
      localStorage.getItem("access_token") ||
      sessionStorage.getItem("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
