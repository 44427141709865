import { createSlice } from "@reduxjs/toolkit";
import { reducerHelpers } from "./reducerHelpers";

const initialState = {
  status: "",
  loading: false,
  data: [],
  error: null,
};

export const commentCategorySlice = createSlice({
  name: "commentcategory",
  initialState,
  reducers: {},

  // * Apiii Calls resultss
  extraReducers: reducerHelpers,
});

// this is for dispatch
export const {} = commentCategorySlice.actions;

// this is for configureStore
export default commentCategorySlice.reducer;
