import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import FormikSelect from "../../components/Common/DropDown.jsx";
import StudentAccordianList from "../../components/Reports/StudentAccordianList.jsx";
import Chart from "../../components/Common/Chart.jsx";
import CommonButton from "../../components/Common/CommonButton.jsx";
import CommentCard from "../../components/Reports/CommentsCard.jsx";
import AddCommentCard from "../../components/Reports/AddCommentCard.jsx";
import SemesterDropdown from "../../components/Common/SemesterDropdown.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getSemesters } from "../../store/semester/semesterThunk";
import { getStudents } from "../../store/students/studentsThunk.js";
import { Form } from "react-bootstrap";
import nodata from "../../assets/nodata.png";
import {
  addReports,
  getAcademicReports,
  getBehaviourReports,
  getImprovementsReports,
  getReports,
  getReportComment,
  getTrends,
} from "../../store/reports/reportsThunk.js";
import { toast } from "react-toastify";
import SurveyModal from "../../components/Reports/SurveyModal.jsx";
import { FiPlus } from "react-icons/fi";
import { getComments } from "../../store/comments/commentThunk.js";
import Loader from "../../components/Common/Loader.jsx";
import moment from "moment";
import { getClass } from "../../store/class/classThunk.js";
import ClassAccordian from "../../components/Reports/ClassAccordian.js";
import { ExportCSV } from "../../store/csv/commentCsvSlice.js";
import { GrDownload } from "react-icons/gr";
import { getCategoryStepper } from "../../store/commentCategory/commentCategoryThunk.js";
import { getSubjects } from "../../store/subjects/subjectThunk.js";
import SubjectSelect from "../../components/Common/SubjectSelect.jsx";
import CommentsCard from "../../components/Dashboard/CommentsCard.jsx";
import { commentsValidationSchema } from "../../helpers/validationSchemas.js";
import { getSelectedStudent } from "../../helpers/index.js";

const Report = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const semesterData = useSelector((state) => state.reducer.semester);
  const classData = useSelector((state) => state.reducer.class);
  const subjectData = useSelector((state) => state.reducer.subjects);
  const studentsData = useSelector((state) => state.reducer.students);
  const reportsData = useSelector((state) => state.reducer.reports);
  const { academicsData, behaviourData, improvementsData, loading } =
    useSelector((state) => state.reducer.reports);

  const [selectedClass, setSelectedClass] = useState(null);
  const [acadamicsRatings, setAcadamicsRatings] = useState(0);
  const [improvementsRatings, setImprovementsRatings] = useState(0);
  const [behaviourRatings, setBehaviourRatings] = useState(0);
  const [acadamicsComment, setAcadamicsComment] = useState("");
  const [improvementsComment, setImprovementsComment] = useState("");
  const [behaviourComment, setBehaviourComment] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedSemester, setSelectedSemester] = useState("");
  const [openSurveyModal, setOpenSurveyModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dataReports, setDataReports] = useState(reportsData);
  const [subject, setSubject] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [subjectsMergedAray, setSubjectsMergedAray] = useState([]);

  const [refetchGrpahData, setRefetchGrpahData] = useState(false);
  const [academicsChecked, setAcademicsChecked] = useState(true);
  const [improvementsChecked, setImprovementsChecked] = useState(true);
  const [behaviorChecked, setBehaviorChecked] = useState(true);

  const titles = useSelector((state) => state?.reducer?.reports?.data);
  const { trends } = useSelector((state) => state.reducer.reports);


  const [commentName1, setCommentName1] = useState(trends?.trend1);
  const [commentName2, setCommentName2] = useState(trends?.trend2);
  const [commentName3, setCommentName3] = useState(trends?.trend3);

  const holder1 = "Trend 1";
  const holder2 = "Trend 2";
  const holder3 = "Trend 3";

  useEffect(() => {
    const storedStudent = JSON.parse(localStorage.getItem("student"));
    if (storedStudent && !selectedStudent) {
      setSelectedStudent(storedStudent);
    }
  }, [refresh, refetchGrpahData]);

  useEffect(() => {
    dispatch(getReportComment(selectedStudent?.id));
  }, [selectedStudent]);

  useEffect(() => {
    dispatch(getSemesters());
    dispatch(getStudents());
    dispatch(getComments());
    dispatch(getClass());
    dispatch(getSubjects());
  }, []);


  useEffect(() => {
    dispatch(getReports()).then(() => setRefresh(true));
  }, []);

  useEffect(() => {
    dispatch(getSemesters());
    dispatch(getStudents());
    dispatch(getClass());
    dispatch(getSubjects());
    // dispatch(getReportComment(selectedStudent.id));
  }, []);

  // const handleClassChange = (e) => {
  //   setSelectedClass(e.target.value);
  // };

  const acadamicsChangeHandler = (e) => {
    setAcadamicsRatings(e.target.value);
  };
  const improvementsChangeHandler = (e) => {
    setImprovementsRatings(e.target.value);
  };
  const behaviourChangeHandler = (e) => {
    setBehaviourRatings(e.target.value);
  };

  const acadamicsCommentChangeHandler = (e) => {
    setAcadamicsComment(e.target.value);
  };
  const improvementsCommentChangeHandler = (e) => {
    setImprovementsComment(e.target.value);
  };
  const behaviourCommentChangeHandler = (e) => {
    setBehaviourComment(e.target.value);
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  const addReportHandler = () => {
    // if (!location?.state?.data?.id) {
    //   toast.error("Please select a student first");
    //   return;
    // }
    const data = {
      acadamicsRatings,
      improvementsRatings,
      behaviourRatings,
      acadamicsComment,
      improvementsComment,
      behaviourComment,
      selectedStudent,
      selectedSubject,
      commentName1,
      commentName2,
      commentName3,
      subject: selectedSubject,
    };

    if (!selectedSubject) {
      toast.warn("Please selected a subject");
      return;
    }
    dispatch(addReports(data))
      .then(() => {
        getAcademicReports();
        setAcadamicsRatings(0);
        setImprovementsRatings(0);
        setBehaviourRatings(0);
        setAcadamicsComment("");
        setBehaviourComment("");
        setImprovementsComment("");
        // setSelectedStudent("");
        setRefresh(!refresh);
      })
      .then(() => {
        setRefetchGrpahData(!refetchGrpahData);
      });
  };

  const student = getSelectedStudent();
  useEffect(() => {
    const sendData = {
      studentId: selectedStudent?.id || student?.id,
      subjectId: selectedSubject,
    };
    dispatch(getTrends(sendData));
  }, [selectedSubject, selectedStudent]);

  useEffect(() => {
    const id = selectedStudent?.id || student?.id;
    dispatch(getReportComment(id));
  }, [refresh]);

  useEffect(() => {
    const subjectsName = subjectData?.data.find((item) => {
      return item.id === Number(subject);
    });
    const data = {
      subject_name: subjectsName?.subject_name,
      id: location?.state?.data?.id,
    };
    if (location?.state?.data?.id) {
      dispatch(getCategoryStepper(data));
    }
  }, [location.state?.data.id, subject]);

  useEffect(() => {
    const mergedArray = selectedStudent?.subjects_ids?.map((value, index) => {
      return { id: value, subject: selectedStudent?.subjects[index] };
    });
    setSubjectsMergedAray(mergedArray);
  }, [refresh, refetchGrpahData, selectedStudent]);

  useEffect(() => {
    if (selectedSubject) {
      dispatch(getAcademicReports(selectedSubject));
      dispatch(getBehaviourReports(selectedSubject));
      dispatch(getImprovementsReports(selectedSubject));
    }
  }, [selectedSubject, refetchGrpahData, selectedStudent]);

  useEffect(() => {
    if(selectedStudent){

      setSelectedSubject("");
    }
  }, [selectedStudent]);

  const handleSemChange = (e) => {
    setSelectedSemester(e.target.value);
  };

  const handleCheckboxChange = (checkboxName) => {
    switch (checkboxName) {
      case "Academics":
        setAcademicsChecked(!academicsChecked);
        break;
      case "Improvements":
        setImprovementsChecked(!improvementsChecked);
        break;
      case "Behavior":
        setBehaviorChecked(!behaviorChecked);
        break;
      default:
        break;
    }
  };
  const toggleServayModal = () => {
    setOpenSurveyModal(!openSurveyModal);
  };
  const handleClick = () => {
    dispatch(ExportCSV(selectedStudent?.id));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SurveyModal
        openSurveyModal={openSurveyModal}
        toggleServayModal={toggleServayModal}
        selectedStudent={selectedStudent?.id}
        setSubject={setSubject}
        subject={subject}
        refresh={refresh}
        selectedSubject={selectedSubject}
        setRefresh={setRefresh}
      />
      <div className="row report">
        {/* Sidebar */}

        {/* Students Section */}
        <div className="box-item  col-lg-3 student-section">
          <div className="d-flex flex-column  m-3 mt-2 ">
            <h3 className="title3 mb-4 ">Students</h3>
            <div className="report-select">
              <select
                placeholder="Select"
                className="form-select"
                value={selectedClass}
                onChange={handleClassChange}
              >
                <option>Select</option>
                {classData?.data?.map?.((item) => {
                  return (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {classData?.data
            ?.filter((item) =>
              selectedClass && selectedClass !== "Select"
                ? item.id === Number(selectedClass)
                : true
            )
            ?.map((item) => {
              return (
                <ClassAccordian
                  headerText={item?.name}
                  classData={item}
                  studentsData={studentsData}
                  setSelectedStudent={setSelectedStudent}
                />
              );
            })}
        </div>
        {/* graph section */}

        {/* graph section */}
        <div className="col-12 col-lg-5 middle h-100">
{/* {
!selectedSubject ? <p className="select-subject-msg">
Please select a subject 
</p>:<></>
}       */}
    <div className="mb-2 subject-dropdown">
            <select
              placeholder="Select"
              className="form-select w-603"
              value={selectedSubject}
              onChange={(e) => {
                setSelectedSubject(e.target.value);
              }}
            >
              <option>Select</option>
              {subjectsMergedAray?.map((item) => {
                return (
                  <option key={item?.id} value={item?.id}>
                    {item?.subject}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="chart-box mb-3 ">
          <h4 className="pt-2 pb-4   fw-bold">Reports</h4>

          <div className=" report-chart">

            {!selectedStudent || !selectedSubject ? (
              <div className="d-flex justify-content-center  align-content-center ">
                <img src={nodata} />
              </div>
            ) : (
              <>
                <div className="d-flex flex-column  justify-content-between ">
                  <div className="d-flex gap-2 justify-content-around mb-5">
                    <Form.Check
                      checked={academicsChecked}
                      type="checkbox"
                      label={trends?.trend1}
                      onChange={() => handleCheckboxChange("Academics")}
                    />
                    <Form.Check
                      type="checkbox"
                      checked={improvementsChecked}
                      label={trends?.trend2}
                      onChange={() => handleCheckboxChange("Improvements")}
                    />
                    <Form.Check
                      type="checkbox"
                      checked={behaviorChecked}
                      label={trends?.trend3}
                      onChange={() => handleCheckboxChange("Behavior")}
                    />
                  </div>
                </div>

                <Chart
                  commentName1={trends?.trend1}
                  commentName2={trends?.trend2}
                  commentName3={trends?.trend3}
                  academicsChecked={academicsChecked}

                  improvementsChecked={improvementsChecked}
                  behaviorChecked={behaviorChecked}

                  reportsData={academicsChecked && selectedSubject ? academicsData : []}
                  improvementsData={improvementsChecked && selectedSubject  ? improvementsData : []}
                  behaviourData={behaviorChecked && selectedSubject ? behaviourData : []}
                />
              </>
            )}
          </div>

          </div>

          <div className="box-item">
            {!selectedStudent || !selectedSubject ? (
              <div className="d-flex justify-content-center  align-content-center ">
                <img src={nodata} />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="title3">Trends</h3>

                  <CommonButton
                    // disabled={!location?.state?.data?.id}
                    variant="primary"
                    onClick={addReportHandler}
                    text="Send"
                    type="button"
                  />
                </div>

                <AddCommentCard
                  name="Acadamics"
                  placeholder={holder1}
                  // commentName={commentName1 ? commentName1 : titles?.title1}
                  commentName={trends?.trend1 || ""}
                  onChange={setCommentName1}
                  ratings={acadamicsRatings}
                  ratingChangeHandler={acadamicsChangeHandler}
                  commentChangeHandler={acadamicsCommentChangeHandler}
                  commentText={acadamicsComment}
                  color="#7C5CAF"
                />
                <AddCommentCard
                  name="Improvements"
                  // commentName={commentName2 || titles?.title2}
                  commentName={trends?.trend2 || ""}
                  placeholder={holder2}
                  onChange={setCommentName2}
                  ratings={improvementsRatings}
                  ratingChangeHandler={improvementsChangeHandler}
                  commentChangeHandler={improvementsCommentChangeHandler}
                  commentText={improvementsComment}
                  color="#009BDE"
                />
                <AddCommentCard
                  name="Behaviour"
                  placeholder={holder3}
                  // commentName={commentName3 || titles?.title3}
                  commentName={trends?.trend3 || ""}
                  onChange={setCommentName3}
                  ratings={behaviourRatings}
                  ratingChangeHandler={behaviourChangeHandler}
                  commentChangeHandler={behaviourCommentChangeHandler}
                  commentText={behaviourComment}
                  color="#FF6A4D"
                />
              </>
            )}
          </div>
        </div>

        {/* Right Comments Section */}
        <div className="col-12 col-lg-4">
          <div className="box-item sidebars ">
            {!selectedStudent ?
            <div className=" d-flex justify-content-center  align-content-center h-100">
            <div className="d-flex justify-content-center  align-content-center m-auto " style={{height:"min-content"}}>
                <img src={nodata} />
              </div>
            </div>:<>

            <div className="d-flex flex-column  justify-content-between ">
<div className="d-flex justify-content-between">
<h3 className="title4 fw-bold my-2">{selectedStudent?.student_name}</h3>

<button onClick={handleClick} className="export-btn">
                  <GrDownload />
                </button>

</div>
              <div className="d-flex justify-content-between">
              <h3 className="title4 my-2 fw-bold ">Comment History</h3>

                <p
                  className="text-primary text-center "
                  style={{ cursor: "pointer" }}
                  onClick={toggleServayModal}
                >
                  <FiPlus /> Add New
                </p>
               
              </div>
            </div>

            <>

              <CommentCard
                student={selectedStudent}
                reports={reportsData}
                semester={selectedStudent?.semester_name}
                selectedSubject={selectedSubject}
              />
            </>
            </>}
         
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
