import React from "react";

function CommonDropdown(props) {
  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id={props.id}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {props.buttonText}
      </button>
      <div className="dropdown-menu" aria-labelledby={props.id}>
        <select className="form-control mb-2" onChange={props.onChange}>
          {props.children}
        </select>
      </div>
    </div>
  );
}

export default CommonDropdown;
