import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { apiPrivate } from "../../api";

export const getSemesters = createAsyncThunk(
  "semester/getSemesters",
  async (thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/semester/");
      return response.data;
    } catch (error) {
      const message = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addSemester = createAsyncThunk(
  "semester/addSemester",
  async (data, { dispatch }, thunkAPI) => {
    // const array = [];
    // data.semesterSubject.map((item) => {
    //   array.push(item.value);
    // });

    try {
      const response = await apiPrivate.post("api/semester/", {
        semester_name: data.semesterName,
        semester_start_date: data.semesterStartDate,
        semester_end_date: data.semesterEndDate,
        subject: data.subjects,
        next_semester: data.nextSemester,
      });
      dispatch(getSemesters());
      toast.success("Successfuly Added Semester");
      return response.data;
    } catch (error) {
      const message = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteSemester = createAsyncThunk(
  "semester/deleteSemester",
  async (id, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.delete(`api/semester/${id}/`);
      dispatch(getSemesters());
      toast.success("Successfuly deleted Semester");
    } catch (error) {
      const message = error.response.data;
      toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateSemester = createAsyncThunk(
  "semester/updateSemester",
  async (data, { dispatch }, thunkAPI) => {
    // debugger;
    try {
      await apiPrivate.put(`api/semester/${data?.id}/`, {
        semester_name: data.semesterName,
        semester_start_date: data.semesterStartDate,
        semester_end_date: data.semesterEndDate,
        subject: data.subjects,
        next_semester: data.nextSemester,
      });

      dispatch(getSemesters());
      toast.success("Successfuly Updated Semester");
    } catch (error) {
      const message = error.response.data;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
