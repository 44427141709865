import React, { useEffect } from "react";
import StudentCard from "../../components/Dashboard/StudentCard";
import Statistics from "../../components/Dashboard/Statistics";

import studentImage from "../../assets/student-card.png";
import { FaGraduationCap, FaUserGraduate } from "react-icons/fa";
import { IoLibrary } from "react-icons/io5";
import SubjectCard from "../../components/Dashboard/SubjectCard";
import SubjectCardImg from "../../assets/subjcard-head.png";
import profileBanner from "../../assets/profile-banner.png";
import userprofile from "../../assets/userprofile.png";
import { useDispatch, useSelector } from "react-redux";
import { getSubjects } from "../../store/subjects/subjectThunk";
import { getStats } from "../../store/dashboard/dashboardThunk";
import { getStudents } from "../../store/students/studentsThunk";
import Loader from "../../components/Common/Loader";

const Profile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubjects());
    dispatch(getStats());
    dispatch(getStudents());
  }, [dispatch]);

  const statsData = useSelector((state) => state.reducer.dashboard);
  const subjectsData = useSelector((state) => state.reducer.subjects);
  const studentsData = useSelector((state) => state.reducer.students);
  const { userProfile } = useSelector((state) => state.reducer.dashboard);

  if (statsData?.loading) {
    return <Loader />;
  }

  return (
    <div className="profile">
      <div className="profile-banner">
        <img src={profileBanner} className="img-fluid" alt="profileBanner" />
      </div>
      {/* Left Side */}
      {/* Profile Pic and date */}
      <div className="user-info row bg-white">
        <div className="col-12 col-lg-3">
          <div className="user-data text-center">
            <div
              className="user-profile mx-auto mb-3"
              style={{
                maxWidth: "70",
                maxHeight: "80",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <img
                src={userProfile?.[0]?.profile_img}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt="userprofile"
              />
            </div>
            <h3 className="title4">
              {userProfile?.[0]?.username || "Test User"}
            </h3>
            <p>{userProfile?.[0]?.email}</p>
          </div>
          {/* Students */}
          <div className="mt-3">
            <h3 className="title3 mb-3 mx-3">Students</h3>
            <div className="student-list">
              {studentsData?.data?.map((item) => (
                <StudentCard
                  studentData={item}
                  name="John Wick"
                  image={studentImage}
                  description="Semester - 2 / Subject - Mathematics"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-9">
          <div className="row stats">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <Statistics
                icon={<FaGraduationCap />}
                text="Total Semesters"
                count={statsData?.stats?.semester_count}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 stat2">
              <Statistics
                icon={<FaUserGraduate />}
                text="Total Students"
                count={statsData?.stats?.student_count}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 stat3">
              <Statistics
                icon={<IoLibrary />}
                text="Total Subjects"
                count={statsData?.stats?.subject_count}
              />
            </div>
          </div>
          <div className="subject-card">
            <h3 className="title3 my-4">Subjects</h3>
            <div className="row">
              {subjectsData?.data?.map((item) => (
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <SubjectCard
                    subjectData={item}
                    cardHeader={item}
                    subtitle="Semester 1"
                    title="English Literature"
                    description="Enroll Students"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Main Content */}
    </div>
  );
};

export default Profile;
