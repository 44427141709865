import { Form, Formik } from "formik";
import React from "react";
import {
  commentsCategoryValidationSchema,
  commentsValidationSchema,
} from "../../helpers/validationSchemas";
import FormikField from "../../components/Common/InputField";
import CommonButton from "../../components/Common/CommonButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { commentsCategoryColumns } from "../../helpers/tableColumns.js";
import Loader from "../../components/Common/Loader.jsx";
import { useEffect, useState } from "react";
import {
  addCategory,
  deleteComment,
  getCategory,
  updateCategory,
} from "../../store/commentCategory/commentCategoryThunk";
import DataGrid from "../../components/Common/DataGrid";
import DeleteModal from "../../components/Common/DeleteModal";
import EditCommentCategoryModal from "../../components/Models/EditCommentCategoryModal";

const CommentCategory = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);

  const dispatch = useDispatch();
  const { data, loading, error, status } = useSelector(
    (state) => state.reducer.commentcategory
  );

  const handleAddToggle = (row) => {
    setShowAddModal(!showAddModal);
  };
  const handleCloseToggle = (row) => {
    setCloseModal(!closeModal);
  };
  const numberOfItems = data ? data.length : 0;
  const numbersArray = Array.from({ length: numberOfItems }, (_, index) => ({ value: index + 1 }));


   // Combine numbersArray with data
   const combinedData = data?.map((comments, index) => ({
    ...comments,
    number: numbersArray[index]?.value,
  }));

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  const handleDeleteToggle = async (row) => {
    setselectedItem(row);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteItem = () => {
    dispatch(deleteComment(selectedItem?.id));
    handleDeleteToggle();
  };

  const handleEditToggle = (row) => {
    setselectedItem(row);
    setShowEditModal(!showEditModal);
  };

  const handleUpdateItem = (values, id) => {
    values.id = id;
    dispatch(updateCategory(values));
    handleEditToggle();
  };

  const columns = commentsCategoryColumns(handleEditToggle, handleDeleteToggle);

  const addCategoryHandler = (values, resetForm) => {
    dispatch(addCategory(values.categoryName));
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="comment-page">
        <DeleteModal
          showDeleteModal={showDeleteModal}
          handleDeleteToggle={handleDeleteToggle}
          handleDeleteItem={handleDeleteItem}
        />
        <EditCommentCategoryModal
          showEditModal={showEditModal}
          handleEditToggle={handleEditToggle}
          handleUpdateItem={handleUpdateItem}
          selectedItem={selectedItem}
          commentCategory={data}
          handleCloseToggle={handleCloseToggle}
          setShowEditModal={setShowEditModal}
          />
        <div className="box-item mb-3">
          <h3 className="title3 mb-3">Add Comment Category</h3>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-7">
              <Formik
                initialValues={{
                  categoryName: "",
                }}
                validationSchema={commentsCategoryValidationSchema}
                onSubmit={(values) => addCategoryHandler(values)}
              >
                <Form className="mb-4">
                  <FormikField
                    name="categoryName"
                    label="Category Name"
                    type="text"
                  />

                  <div className="select-btn mt-3">
                    <CommonButton
                      variant="primary"
                      text="+ Create New"
                      type="submit"
                    />
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
        <div className="data-table-container comment-table">
          <h3 className="title3 mb-4 px-3">Comment Categories List</h3>
          <DataGrid columns={columns} data={combinedData} />
        </div>
      </div>
    </div>
  );
};

export default CommentCategory;
