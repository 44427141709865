


import React from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import {
  genderValidationSchema,
  subjectValidationSchema,
} from "../../helpers/validationSchemas";
import FormikField from "../Common/InputField";
import FormikSelect from "../Common/DropDown";
import imageicon from "../../assets/imageicon.png";
import SemesterDropdown from "../Common/SemesterDropdown";
import CategoryDropdown from "../Common/CategoryDropdown";
import SubjectCategoryDropdown from "../Common/SubjectCategoryDropdown";
import CommonButton from "../Common/CommonButton";

const EditSubjectModal = ({
  showEditModal,
  handleEditToggle,
  handleUpdateItem,
  selectedItem,
  data,
  category,
  handleCloseToggle,
  setShowEditModal
}) => {
  const handleCancel =()=>{

    setShowEditModal(!showEditModal)
   
     }
  return (
    <Modal show={showEditModal} onHide={handleEditToggle}>
      <div className="modal-dialog modal-dialog-centered m-0">
        <div className="modal-content">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Edit Subject</Modal.Title>
        </Modal.Header>
<Modal.Body>
            <Formik
              initialValues={{
                file: "",
                subjectName: selectedItem?.subject_name,
                subjectCategory: selectedItem?.category_id,
                trend1: selectedItem?.trend1,
                trend2: selectedItem?.trend2,
                trend3: selectedItem?.trend3,
              }}
              validationSchema={subjectValidationSchema}

              onSubmit={(values, { resetForm }) => {
                handleUpdateItem(values, selectedItem.id);
              }}
            >
              {({ values }) => {
                return (
                  <Form>
                    <div className="row">
                      <div className="mb-3 col-6">
                        <FormikField
                          name="subjectName"
                          label="Subject Name"
                          type="text"
                        />
                      </div>
                      <div className="mb-3 col-6">
                        <SubjectCategoryDropdown
                          name="subjectCategory"
                          label="Subject Category"
                          options={category}
                          displayProperty="subjectCategory"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-4">
                        <FormikField name="trend1" label="Trend 1" type="text" />
                      </div>
                      <div className="mb-3 col-4">
                        <FormikField name="trend2" label="Trend 2" type="text" />
                      </div>
                      <div className="mb-3 col-4">
                        <FormikField name="trend3" label="Trend 3" type="text" />
                      </div>
                    </div>
                    <div className="d-flex flex-row gap-3  mt-5 mb-3">
                      <CommonButton
                        variant="light px-5"
                        onClick={handleCancel}
                        text="Cancel"
                        type="button"


                      />
                      <CommonButton
                        variant="primary px-5"
                        text="Save"
                        type="submit"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default EditSubjectModal;
