import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { apiPrivate } from "../../api";
import axios from "axios";
import { getToken } from "../../helpers";

export const getStats = createAsyncThunk(
  "dashboard/getStats",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/semester-student-subject/");
      return response.data;
    } catch (error) {
      const message = error.response.data;
      // toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPerformance = createAsyncThunk(
  "dashboard/getPerformance",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/students/");
      return response.data;
    } catch (error) {
      const message = error.response.data;
      // toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getLatestComments = createAsyncThunk(
  "dashboard/getLatestComments",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/latest-comment/");
      return response.data;
    } catch (error) {
      const message = error.response.data;
      // toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRecentActivity = createAsyncThunk(
  "dashboard/getRecentActivity",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/recent_activity/");
      return response.data;
    } catch (error) {
      const message = error.response.data;
      // toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getUserInfo = createAsyncThunk(
  "dashboard/getUserInfo",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get(
        "/accounts/profile/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      const message = error.response.data;
      // toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async ({ id, username, contact_number }, thunkAPI,) => {
    try {
      const response = await apiPrivate.put(`accounts/profile/update/${id}/`, {
        username,
        contact_number: contact_number,
      });
      thunkAPI.dispatch(getUserInfo());

      toast.success("Profile updated successfully");

      return response.data;
    } catch (error) {
      const message = error.response?.data || "Something went wrong";
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getStudentPerformance = createAsyncThunk(
  "dashboard/getStudentPerformance",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/student/performance/");
      return response.data;
    } catch (error) {
      const message = error.response.data;
      // toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);