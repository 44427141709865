import React from "react";
import { useNavigate } from "react-router-dom";
import NotFound from "../../assets/404.jpg";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center row">
          <div class=" col-md-6">
            <img src={`${NotFound}`} alt="" class="img-fluid" />
          </div>
          <div class=" col-md-6 mt-5">
            <p class="fs-3">
              <span class="text-danger">Opps!</span> Page not found.
            </p>
            <p class="lead">The page you’re looking for doesn’t exist.</p>
            <button
              onClick={() => navigate(-1)}
              type="button"
              class="btn btn-primary"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
