import React, { useState } from "react";
import { Button, Modal, Nav } from "react-bootstrap";
import { Form, Formik } from "formik";
import FormikField from "./InputField";
import CommonButton from "./CommonButton";
import { resetPasswordConfirm2 } from "../../store/auth/authThunk";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateProfile } from "../../store/dashboard/dashboardThunk";

const ProfileModal = ({ show, handleClose }) => {
  const [activeTab, setActiveTab] = useState("personal");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state.reducer.dashboard);


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required. "),
    password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[a-zA-Z\d])[a-zA-Z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/, 'Password must be alphanumeric with optional special characters')
    .matches(/\d*[a-zA-Z]\d*/, 'Password must contain at least one letter')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  });


  const handleSubmit = async (values, { setSubmitting }) => {
  try {
    const resetToken = localStorage.getItem("access_token");
    await dispatch(resetPasswordConfirm2({ oldPassword: values.oldPassword, token: resetToken, password: values.password }));
    // navigate('/login');
  } catch (error) {

   
  } finally {
    // Reset the form submission state
    setSubmitting(false);
  }
};

   

  const handleFormSubmit = async (values) => {
    const { fullName, email, contactNumber } = values;

    // Dispatch the updateProfile action with the user's id and the updated data
    dispatch(updateProfile({
      id: userProfile.length > 0 ? userProfile[0]?.id : "",
      username: fullName,
      contact_number: contactNumber,
    }));
    handleClose();

  };

  const renderTabContent = () => {
    if (activeTab === "personal") {
      return (
        <div>
          {/* Personal Information Form */}
          <div className="p-5">
          <Formik
  initialValues={{
    fullName: userProfile[0]?.username || "", // Use [0] to access the first object
    email: userProfile[0]?.email || "",
    contactNumber: userProfile[0]?.contact_number || "",
  }}
  onSubmit={handleFormSubmit}
>
  <Form className="row">
    <div className="col-xs-12 col-sm-6 col-6">
      <FormikField name="fullName" label="Full Name" type="text" />
    </div>
    <div className="col-xs-12 col-sm-6 col-6">
      {/* Set 'disabled' prop only for the email field */}
      <FormikField name="email" label="Email" type="text" disabled />
    </div>
    <div className="col-xs-12 col-sm-6 col-6">
      <FormikField name="contactNumber" label="Contact Number" type="text" />
    </div>
    <div className="select-btn mt-5 d-flex gap-3 justify-content-end">
      <CommonButton variant="light px-5" text="Cancel" type="button" onClick={handleClose} />
      <CommonButton variant="primary px-5" text="Save" type="submit"  />

    </div>
  </Form>
</Formik>

          </div>
        </div>
      );
    } else if (activeTab === "password") {
      return (
      

<div>
  <div className="p-5">
    <h4 className="mb-4 fw-bold">Change Password</h4>
    <Formik
      initialValues={{ oldPassword: '', password: '', confirmPassword: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="row">
          <div className="col-12 ">
            <div className="col-6 pe-2">
            <FormikField name="oldPassword" label="Old Password" type="password" />

            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-6">
            <FormikField name="password" label="New Password" type="password" />
          </div>
          <div className="col-xs-12 col-sm-6 col-6">
            <FormikField
              name="confirmPassword"
              label="Confirm Password"
              type="password"
            />
          </div>
          <div className="select-btn mt-5 d-flex gap-3 justify-content-end">
            <CommonButton variant="light px-5" text="Cancel" type="button" onClick={handleClose}/>
            <CommonButton variant="primary px-5" text="Save" type="submit" disabled={isSubmitting} />

           
          </div>
        </Form>
      )}
    </Formik>
  </div>
</div>

      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="px-5">Account Setting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Nav variant="tabs" defaultActiveKey="personal" className="px-4">
          <Nav.Item>
            <Nav.Link
              eventKey="personal"
              onClick={() => handleTabChange("personal")}
            >
              Personal Information
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="password"
              onClick={() => handleTabChange("password")}
            >
              Password
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {renderTabContent()}
      </Modal.Body>
     
    </Modal>
  );
};

export default ProfileModal;
