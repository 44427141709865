import {
  addReports,
  getAcademicReports,
  getBehaviourReports,
  getImprovementsReports,
  getReports,
  getReportComment,
  getTrends,
} from "./reportsThunk";

export const reducerHelper = (builder) => {
  //* Reports Data ====================================
  builder.addCase(getReports.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getReports.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getReports.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Reports Data ====================================
  builder.addCase(getAcademicReports.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getAcademicReports.fulfilled, (state, action) => {
    state.loading = false;
    state.academicsData = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getAcademicReports.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });
  //* Reports Data ====================================
  builder.addCase(getBehaviourReports.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getBehaviourReports.fulfilled, (state, action) => {
    state.loading = false;
    state.behaviourData = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getBehaviourReports.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Reports Data ====================================
  builder.addCase(getImprovementsReports.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getImprovementsReports.fulfilled, (state, action) => {
    state.loading = false;
    state.improvementsData = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getImprovementsReports.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Add Reports Data ====================================
  builder.addCase(addReports.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(addReports.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.data = action.payload;
    state.status = "fulfilled";
  });
  builder.addCase(addReports.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Get Reports Data ====================================
  builder.addCase(getReportComment.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getReportComment.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.reportComments = action.payload;
    state.status = "fulfilled";
  });

  builder.addCase(getReportComment.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Get Reports Data ====================================
  builder.addCase(getTrends.pending, (state, action) => {
    state.status = "pending";
  });
  builder.addCase(getTrends.fulfilled, (state, action) => {
    state.error = null;
    state.trends = action.payload;
    state.status = "fulfilled";
  });
  builder.addCase(getTrends.rejected, (state, action) => {
    state.error = action.payload;
    state.status = "rejected";
  });
};
