import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import commentSlice from "./comments/commentSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import genderSlice from "./gender/genderSlice";
import reportsSlice from "./reports/reportsSlice";
import semesterSlice from "./semester/semesterSlice";
import studentsSlice from "./students/studentsSlice";
import subjectsSlice from "./subjects/subjectsSlice";
import csvSlice from "./csv/csvSlice";
import commentCategorySlice from "./commentCategory/commentCategorySlice";
import classSlice from "./class/classSlice";
import subjectCategorySlice from "./subjectCategory/subjectCategorySlice";
import commentCsvSlice from "./csv/commentCsvSlice";
import singlePdfSlice from "./csv/singlePdfSlice";
import allCommentSlice from "./csv/allCommentSlice";
const rootReducer = combineReducers({
  auth: authSlice,
  semester: semesterSlice,
  gender: genderSlice,
  subjects: subjectsSlice,
  students: studentsSlice,
  dashboard: dashboardSlice,
  comments: commentSlice,
  reports: reportsSlice,
  csv: csvSlice,
  commentcategory: commentCategorySlice,

  class: classSlice,
  subjectcategory: subjectCategorySlice,
  commentcsv: commentCsvSlice,
  singlepdf: singlePdfSlice,
  allcommentpdf: allCommentSlice,
});

export default rootReducer;
