import { Modal, Button } from "react-bootstrap";
import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

const DeleteModal = ({
  showDeleteModal,
  handleDeleteItem,
  handleDeleteToggle,
}) => {
  return (
    <>
      <Modal show={showDeleteModal} onHide={handleDeleteToggle}>
        <div class="modal-dialog modal-dialog-centered m-0">
          <div class="modal-content">
            <div class="modal-body mx-auto">
              <div className="delet-icon">
                <RiDeleteBin6Line />
              </div>
              <p className="w-75 text-center  mb-0 mt-2 title5 mx-auto">
                Are you Sure You Want to Delete?
              </p>
            </div>
            <div className="d-flex gap-3 justify-content-center my-3">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
                onClick={handleDeleteToggle}
              >
                No Don’t
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={handleDeleteItem}
              >
                Yes! Delete
              </button>
            </div>
          </div>
        </div>
      
      </Modal>
    </>
  );
};

export default DeleteModal;
