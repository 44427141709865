import { createSlice } from "@reduxjs/toolkit";
import { reducerHelper } from "./reducerHelper";

const initialState = {
  status: "",
  loading: false,
  data: [],
  error: null,
};

export const subjectCategorySlice = createSlice({
  name: "subjectcategory",
  initialState,
  reducers: {},

  // * Apiii Calls resultss
  extraReducers: reducerHelper,
});

// this is for dispatch
export const {} = subjectCategorySlice.actions;

// this is for configureStore
export default subjectCategorySlice.reducer;
