import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api, { apiPrivate } from "../../api";
import { getSemesters } from "../semester/semesterThunk";
import { getSelectedStudent } from "../../helpers";

export const getReports = createAsyncThunk(
  "reports/getReports",
  async (data, thunkAPI) => {
    try {
      const student = getSelectedStudent();
      const response = await apiPrivate.get(`api/title/reports/${student.id}}`);

      return response.data;
    } catch (error) {
      const message = error.response.data;

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAcademicReports = createAsyncThunk(
  "reports/getAcademicReports",
  async (subjectId, thunkAPI) => {
    const student = getSelectedStudent();
    try {
      const response = await apiPrivate.get(
        `api/acedemic/chart/${student?.id}/${student?.semester_id}/${subjectId}`
      );
      return response.data;
    } catch (error) {
      const message = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBehaviourReports = createAsyncThunk(
  "reports/getBehaviourReports",
  async (subjectId, thunkAPI) => {
    const student = getSelectedStudent();
    try {
      const response = await apiPrivate.get(
        `api/behavior/chart/${student?.id}/${student?.semester_id}/${subjectId}`
      );
      return response.data;
    } catch (error) {
      const message = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getImprovementsReports = createAsyncThunk(
  "reports/getImprovementsReports",
  async (subjectId, thunkAPI) => {
    const student = getSelectedStudent();
    try {
      const response = await apiPrivate.get(
        `api/improvement/chart/${student?.id}/${student?.semester_id}/${subjectId}`
      );

      return response?.data;
    } catch (error) {
      const message = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTrends = createAsyncThunk(
  "reports/getTrends",
  async (data, dispatch, thunkAPI) => {
    try {
      let url;
      if (data.subjectId === undefined || !data.subjectId) {
        url = `api/subjects/trends/?student_id=${data?.studentId}`;
      } else {
        url = `api/subjects/trends/?student_id=${data?.studentId}&subject_id=${data?.subjectId}`;
      }
      const response = await apiPrivate.get(url);
      return response.data;
    } catch (error) {
      // toast.error("Something went wrong");
      const message = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addReportComment = createAsyncThunk(
  "reports/addReportComment",
  async (data, dispatch, thunkAPI) => {
    try {
      const response = await apiPrivate.post(`api/comment/history/`, {
        student: data?.student,
        comments: data?.comments,
        subject: data?.subject,
      });
      toast.success("Successfully added Comment");
      data.setRefresh(!data.refresh);
      dispatch(getReportComment());
      return response.data;
    } catch (error) {
      // toast.error("Something went wrong");
      const message = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReportComment = createAsyncThunk(
  "reports/getReportComment",
  async (data, thunkAPI) => {
    const student = getSelectedStudent();
    try {
      const response = await apiPrivate.get(
        `api/show/comment/history/${data || student.id}/`
      );
      return response.data;
    } catch (error) {
      const message = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteComment = createAsyncThunk(
  "subject/deleteComment",
  async (id, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.delete(`api/comment_history/delete/${id}/`);
      dispatch(getReportComment());
      toast.success("Successfully Deleted Comment");
    } catch (error) {
      const message = error.response.data;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addReports = createAsyncThunk(
  "reports/addReports",
  async (data, { dispatch }, thunkAPI) => {
    try {
      const student = getSelectedStudent();
      const response = await apiPrivate.post("api/reports/", {
        student: data?.selectedStudent?.id || student.id,
        academics_comment: data?.acadamicsComment,
        academics_rating: data?.acadamicsRatings,
        improvements_comment: data?.improvementsComment,
        improvements_rating: data?.improvementsRatings,
        Behaior_comment: data?.behaviourComment,
        Behaior_rating: data?.behaviourRatings,
        subject: data?.subject,
        // title1: data?.commentName1,
        // title2: data?.commentName2,
        // title3: data?.commentName3,
      });

      toast.success("Successfully added Report");
      dispatch(getReports());
      return response.data;
    } catch (error) {
      toast.error("Something went wrong");
      const message = error.response.data;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
