import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "../auth/authThunk";
import { reducerHelper } from "./reducerHelper";

const initialState = {
  studentsBySemester: [],
  status: "",
  loading: false,
  data: [],
  error: null,
};

export const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {},

  // * Apiii Calls resultss
  extraReducers: reducerHelper,
});

// this is for dispatch
export const {} = studentsSlice.actions;

// this is for configureStore
export default studentsSlice.reducer;
