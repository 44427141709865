


import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import SidebarItem from "./SidebarItem";
import { useNavigate } from "react-router-dom";

const SidebarDropdown = ({ icon, text, items, selected, setSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="sidebar-dropdown">
      <div
        className={`sidebar-item ${selected?.text === text ? "active" : ""}`}
        onClick={handleClick}
      >
        <span>
          <span style={{minWidth:"20px"}}> {icon}</span>
         
          <span style={{padding:"8px"}}>{text}</span>
        </span>
        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
      </div>
      {isOpen &&
        items.map((item, idx) => (
          <div 
            key={idx}
            onClick={() => {
              setSelected(item);
              localStorage.setItem("selected-tab", JSON.stringify(item));
              navigate(item.url);
            }}
          >
            <SidebarItem
              icon={item.icon}
              text={item.text}
              url={item.url}
              setSelected={setSelected}
              selected={selected}
            />
          </div>
        ))}
    </div>
  );
};

export default SidebarDropdown;

