import React, { useEffect, useState } from "react";

const CommentsForm = ({
  name,
  label,
  options,
  value,
  onChange,
  comments,
  commentsData,
}) => {
  const [selectedField, setSelectedField] = useState([]);

  return (
    <div>
      <select className="form-control" value={value} onChange={onChange}>
        <option>Select</option>
        {comments?.map((item) => {
          return (
            <option key={item?.comments_id} value={item?.comments_id}>
              {item?.comments}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default CommentsForm;
