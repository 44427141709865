import { createSlice } from "@reduxjs/toolkit";
import { loginUser, registerUser } from "./authThunk";

const initialState = {
  status: "",
  token: "",
  loading: false,
  user: null,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
  },

  // * Apiii Calls resultss
  extraReducers: (builder) => {
    //* Login Data ====================================
    builder.addCase(loginUser.pending, (state, action) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
      state.status = "fulfilled";
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload;
      state.status = "rejected";
    });
  },
});

// this is for dispatch
export const {} = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
