import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Form, Formik } from "formik";
import { genderValidationSchema } from "../../helpers/validationSchemas.js";
import { useDispatch } from "react-redux";
import FormikField from "../Common/InputField.jsx";
import CommonButton from "../Common/CommonButton.jsx";

const AddGenderModal = ({
  showAddModal,
  handleAddToggle,
  addGender,setShowAddModal
}) => {
    const dispatch = useDispatch();
    const [saveButton, setSaveButton] = useState(false)

    const addGenderHandler = (values, resetForm,shouldResetForm) => {
        if (!values.title) {
          return;
        }
    
        dispatch(addGender(values)).then(() => {
          if (shouldResetForm === false && saveButton === false) {
            resetForm();
          } else{
            handleAddToggle()
          }
        });
      };
      const addGenderSave = (values) => {
        if (!values.title) {
          return;
        }
    
        dispatch(addGender(values)).then((data) => {
         

        });
      };
  return (
    <>
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <div class="modal-dialog modal-dialog-centered m-0 ">
          <div class="modal-content p-4 ">
            {/* <div class="modal-body mx-auto">
              
            </div> */}

            <div className=" mb-3">
            <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Add Gender</Modal.Title>
        </Modal.Header>     
        <Modal.Body> 
          <Formik
          initialValues={{
            title: "",
            pronouns: "",
          }}
          validationSchema={genderValidationSchema}
          onSubmit={(values, { resetForm }) => {
            addGenderHandler(values, resetForm, false)

          }
          }
        >
          <Form className="row">
            <div className="col-6">
              <FormikField name="title" label="Gender" type="text"  placeholder="Enter Gender"/>
            </div>
            <div className="col-6">
              <FormikField name="pronouns" label="Add Pronouns" type="text" placeholder="Enter Pronouns"/>
            </div>
            <div className="select-btn mt-5 d-flex gap-3 ">

              <CommonButton variant="light px-5"   onClick={handleAddToggle} text="Cancel"/>
              <CommonButton variant="primary px-4" text="Save & Add New" type="submit" />

              <CommonButton variant="primary px-5" text="Save" type="submit" onClick={(values) =>
                            setSaveButton(true)
                          } />


            </div>
          </Form>
        </Formik>
        </Modal.Body>
      </div>
           
          </div>
        </div>
        
      </Modal>
    </>
  );
};

export default AddGenderModal;
