import React from "react";
import DataTable from "react-data-table-component";

export default function DataGrid({ data, columns }) {
 

  return (
    <div className="App data_table">
      <DataTable
        columns={columns}
        data={data}
        defaultSortFieldId
        pagination={5}
        highlightOnHover
        fixedHeader
        // fixedHeaderScrollHeight="300px"
      />
    </div>
  );
}
