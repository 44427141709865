import React from "react";

const CommonButton = ({ variant, onClick,classs, text, type, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={`btn btn-${variant} ${classs}`}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  );
};

export default CommonButton;
