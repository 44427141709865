import { ErrorMessage, Field } from "formik";
import React from "react";
import Select from "react-select";

const SubjectMultiSelect = ({ label, name, options, selectedItem }) => {
  const dataCheck = options?.map((option) => {
    return { value: option?.id, label: option?.subject_name };
  });

  const selectedOptions = options.filter((option) => {
    return option?.id === selectedItem?.value;
  });

  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
  <Field name="semester" className='p-3 ' >
        {({ formikField, form }) => {
          return (
            <Select
              {...formikField}
              isMulti
              name={name}
              options={dataCheck}
              value={formikField?.field.value}
              onChange={(option) => form.setFieldValue(name, option)}
              className="subject-select"
            
            />
          );
        }}
      </Field>
      <div className="err-msg">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default SubjectMultiSelect;
