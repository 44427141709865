import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import {
  commentsValidationSchema,
  semesterVaidationSchema,
} from "../../helpers/validationSchemas";
import FormikField from "../Common/InputField";
import FormikTextarea from "../Common/TextArea";
import ReportStepper from "./ReportStepper";
import NameForm from "./NameForm";
import SubjectForm from "./SubjectForm";
import OpeningForm from "./OpeningForm";
import BehaviourForm from "./BehaviourForm";
import ImprovementsForm from "./CommentsForm";
import ClosingForm from "./ClosingForm";
import CommonButton from "../Common/CommonButton";
import { RxCrossCircled } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { addReportComment } from "../../store/reports/reportsThunk";
import { toast } from "react-toastify";
import CommentForm from "./CommentsForm";
import {
  getCategory,
  getCategoryStepper,
} from "../../store/commentCategory/commentCategoryThunk";
let value = 0;

const SurveyModal = ({
  openSurveyModal,
  handleEditToggle,
  handleUpdateItem,
  selectedItem,
  toggleServayModal,
  selectedStudent,
  refresh,
  subject,
  setSubject,
  setRefresh,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  // const [name, setName] = useState("");
  // const [subject, setSubject] = useState("");
  const [opening, setOpening] = useState("");
  const [behaviour, setBehaviour] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [tempValue, setTempValue] = useState([]);
  const [closing, setClosing] = useState("");
  const [label, setLabel] = useState("");
  const [name, setName] = useState("");
  const [selectedSubject, setSelectedSUbject] = useState("");
  const comments = useSelector((state) => state.reducer.comments);

  const stepperData = useSelector(
    (state) => state.reducer.commentcategory?.stepperData
  );
  const daytaa = useSelector(
    (state) => state.reducer.commentcategory
  );
  const studentData = useSelector((state) => state.reducer.commentcategory);

  const dispatch = useDispatch();

  const subjectChangeHandler = (e) => {
    console.log(e.target.value,"gugigguu")
    setSubject(e.target.value);
    setSelectedSUbject(e.target.value);
  };

  const CommentChangeHandler = (e) => {
    const newArray = [...tempValue];
    newArray.push(e.target.value);
    setTempValue(newArray);
    setSelectedCategory(e.target.value);
  };

  const closingChangeHandler = (e) => {
    setClosing(e.target.value);
  };
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const addCommentHandler = () => {
    const newArray = [subject, ...tempValue];
    setTempValue(newArray);
console.log(selectedSubject,"gugigg")
    const data = {
      student: selectedStudent,
      comments: tempValue,
      refresh: refresh,
      subject: selectedSubject,
      setRefresh: setRefresh,
    };

    toggleServayModal(!openSurveyModal);
    dispatch(addReportComment(data)).then(() => {
      setRefresh(!refresh);
    });
    setTempValue([]);
    setActiveStep(0);
  };

  const check = () => {
    // comments?.data?.filter((item) => item?.title === selectedCategory);
  };
  let componentToRender;

  if (activeStep === 0) {
    componentToRender = (
      <NameForm
        label="Name"
        type="input"
        name="name"
        value={name || stepperData?.student_name}
        // onChange={nameChangeHandler}
        readOnly={true}
        disabled={true}
      />
    );
  } else if (activeStep === 1) {
    componentToRender = (
      <SubjectForm
        subjectname={stepperData?.subjects}
        label="Subject"
        type="input"
        name="subject"
        value={subject}
        onChange={subjectChangeHandler}
      />
    );
    value = value + 1;
  } else if (activeStep >= 2) {
    stepperData?.comments?.map?.((item, index) => {
      if (index + 2 === activeStep) {
        componentToRender = (
          <CommentForm
            key={item.name}
            label={item.name}
            name={item.name}
            value={selectedCategory}
            onChange={CommentChangeHandler}
            category={item?.comment}
            comments={item?.comment}
            commentsData={comments}
          />
        );
      }
    });
    value = value + 1;
  }

  return (
    <Modal show={openSurveyModal} onHide={toggleServayModal}>
      <div class="modal-dialog modal-dialog-centered m-0 ">
        <div class="modal-content">
          <div className="modal-header border-0 justify-content-end">
            <button
              onClick={toggleServayModal}
              className="border-0 bg-transparent close-icon"
            >
              <RxCrossCircled />
            </button>
          </div>

          <div class="modal-body">
            <h3 className="title2 text-center">Survey</h3>
            <ReportStepper
              activeStep={activeStep}
              category={stepperData}
              selectedCategory={selectedCategory}
            />
            <div className="w-75 mx-auto">{componentToRender}</div>
            <div className="d-flex justify-content-between align-items-center my-4 w-75 mx-auto">
              <CommonButton
                disabled={activeStep === 0}
                variant="dark"
                onClick={() => setActiveStep(activeStep + -1)}
                text="Back"
                type="button"
              />
              {stepperData?.comments?.length + 1 > activeStep ? (
                <CommonButton
                  // disabled={!selectedStudent}
                  variant="primary"
                  onClick={() => setActiveStep(activeStep + 1)}
                  text="Next"
                  type="button"
                />
              ) : (
                <CommonButton
                  variant="primary"
                  onClick={addCommentHandler}
                  text="Done"
                  type="button"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SurveyModal;
