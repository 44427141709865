import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Form, Formik } from "formik";
import { studentsValidationSchema } from "../../helpers/validationSchemas.js";
import { useDispatch, useSelector } from "react-redux";
import FormikField from "../Common/InputField.jsx";
import CommonButton from "../Common/CommonButton.jsx";
import SubjectCategoryDropdown from "../Common/SubjectCategoryDropdown.js";
import SemesterMultiselect from "../Common/SemesterMultiselect.jsx";
import GenderDropdown from "../Common/GenderDropdown.jsx";
import ClassDropdown from "../Common/ClassDropdown.js";
import FormikMultiSelect from "../Common/MultiSelect.jsx";
import SemesterDropdown from "../Common/SemesterDropdown.jsx";
import imageicon from "../../assets/imageicon.png";
import { addStudent } from "../../store/students/studentsThunk.js";


const AddStudentModal = ({ showAddModal, handleAddToggle,selectedItem }) => {
  const dispatch = useDispatch();
  const genderData = useSelector((state) => state.reducer.gender);
  const subjectsData = useSelector((state) => state.reducer.subjects);
  const classData = useSelector((state) => state.reducer.class);
  const semestersData = useSelector((state) => state.reducer.semester);

  const [saveButton, setSaveButton] = useState(false)

  const [selectedClassId, setSelectedClassId] = useState(null);
  const [imagePreview, setImagePreview] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClassSelect = (selectedItem) => {
    console.log(selectedItem?.id,"dtdtd")
    setSelectedClassId(selectedItem?.id);
  };

  const classOptions = classData?.data;
  const semesterData = semestersData?.data; 

  const subjectOptions = subjectsData?.data?.map((option) => ({
    value: option?.id,
    label: option?.subject_name,
  }));

  
  const addStudentHandler = (values, resetForm,shouldResetForm) => {
    let ids = [];
    values?.subjects?.map((subj) => {
      ids.push(subj.value);
    });
    delete values.subjects;
    values.subject = ids;
    if (selectedImage){
      values.selectedImg = selectedImage;

    }
    
    
    dispatch(addStudent(values)).then(() => {
      if (shouldResetForm === false && saveButton === false) {
        setImagePreview("");

        resetForm();
      } else{
        handleAddToggle()
      }
    });
  
     
  
  };

  return (
    <Modal show={showAddModal} onHide={handleAddToggle}>
      <div className="modal-dialog modal-dialog-centered m-0">
        <div className="modal-content p-4">
          <div className="mb-3">
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Add Student</Modal.Title>
        </Modal.Header> 
            {/* </div> */}
<Modal.Body>
            <Formik
              initialValues={{
                studentName: "",
                studentEmail: "",
                gender: "",
                subjects: [],
                semester: "",
                className: "",
                studentImg:""
              }}
              validationSchema={studentsValidationSchema}
              // onSubmit={(values, { resetForm }) => {
              //   addStudentHandler(values, resetForm);
              // }}
              onSubmit={(values, { resetForm }) => {
                addStudentHandler(values, resetForm, false)

              }
              }
            >
              <Form className="row " encType="multipart/form-data">
                <div className="col-12 input-type-file">
                  <div className="image-upload py-3">
                    <label htmlFor="file-input">
                      {imagePreview ? (
                        <img
                          alt="preview image"
                          src={imagePreview}
                          className="img-fluid"
                        />
                      ) : (
                        <img src={imageicon} className="img-fluid" />
                      )}
                    </label>
                    <input
                      name="file"
                      id="file-input"
                      type="file"
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          setImagePreview(
                            URL.createObjectURL(e.target.files[0])
                          );
                        }
                        setSelectedImage(e.target?.files?.[0]);
                      }}
                    />
                    <p className="m-0 title5">Add Image</p>
                  </div>
                </div>
                <div className="col-6 col-lg-6">
                  <FormikField
                    name="studentName"
                    label="Student Name"
                    type="text"
                  />
                </div>

                <div className="col-6 col-lg-6">
                  <FormikField
                    name="studentEmail"
                    label="Student Email"
                    type="email"
                  />
                </div>
                <div className="col-6 col-lg-6">
                  <GenderDropdown
                    name="gender"
                    label="Gender"
                    options={genderData?.data}
                    displayProperty="title"
                  />
                </div>
                <div className="col-6 col-lg-6">
                  <ClassDropdown
                    name="className"
                    label="Class"
                    options={classOptions}
                    onSelect={handleClassSelect}
                  />
                </div>
                <div className="col-6 col-lg-6 multi-select">
                  <FormikMultiSelect
                    name="subjects"
                    label="Subject"
                    options={subjectOptions}
                  />
                </div>
                <div className="col-6 col-lg-6">
                <SemesterDropdown
                name="semester"
                label="Semester"
                options={classData?.data}
                selected={selectedItem?.semester}
                classData={classData?.data}
              />
                </div>
                <div className="select-btn mt-5 d-flex gap-3 ">
                  <CommonButton
                    variant=" px-5 light"
                    onClick={handleAddToggle}
                    text="Cancel"
                  />
                  <CommonButton
                    variant="primary px-4"
                    text="Save & Add New"
                    type="submit"
                  />
                  <CommonButton variant="primary px-5" text="Save"   onClick={(values) =>
                            setSaveButton(true)
                          }/>
                </div>
              </Form>
            </Formik>
            </Modal.Body>
          </div>
        </div>
      </div>
    </Modal>
  );
};



export default AddStudentModal;
