// ResetPassword.js

import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { resetPasswordConfirm } from '../../store/auth/authThunk';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import FormikField from '../../components/Common/InputField.jsx';
import logo from "../../assets/logo.png";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[a-zA-Z\d])[a-zA-Z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/, 'Password must be alphanumeric with optional special characters')
    .matches(/\d*[a-zA-Z]\d*/, 'Password must contain at least one letter')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  });
  

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Assuming you have the reset token from the URL params
      const resetToken = new URLSearchParams(window.location.search).get('token');
      // Dispatch the resetPassword action with the entered password and token
      await dispatch(resetPasswordConfirm({ token: resetToken, password: values.password }));
      navigate('/login')
      // Navigate to the login page or another appropriate page
    } catch (error) {
      console.error('Error resetting password:', error);
    } finally {
      // Reset the form submission state
      setSubmitting(false);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center p-5 reset-form-screen" style={{height:"100vh"}}>
      <div className="reset-form">
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form >
                          <div className="logo ">
                <img src={logo} class="img-fluid" alt="" />
              </div>
            <h3 className="text-left fw-bold  m-auto mb-4">Reset Password</h3>
            <div >
            <FormikField
                  type="password"
                  label="Password"
                  name="password"
                  placeholder="Enter your new password"
                />
            </div>

               
<div className='mt-3' >
<FormikField
                  type="password"
                  label="Confirm Password"

                  name="confirmPassword"
                  placeholder="Confirm your new password"
                />

</div>
               
            <div className="my-4">
              <Button type="submit" disabled={isSubmitting}>
                Reset Password
              </Button>
            </div>

            <p className="text-center mt-2 mb-0 cursor-pointer forget-text" onClick={() => navigate('/')}>
              Back to login
            </p>
          </Form>
        )}
      </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
