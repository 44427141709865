

import React from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { genderValidationSchema } from "../../helpers/validationSchemas";
import FormikField from "../Common/InputField";

const EditCommentCategoryModal = ({
  showEditModal,
  handleEditToggle,
  handleUpdateItem,
  selectedItem,
  handleCloseToggle,
  setShowEditModal
}) => {

  const handleCancel =()=>{

    setShowEditModal(!showEditModal)
   
     }
  return (
    <Modal show={showEditModal} onHide={handleEditToggle}>
      <div class="modal-dialog modal-dialog-centere m-0">
        <div class="modal-content">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Edit Comment Category</Modal.Title>
        </Modal.Header>
<Modal.Body>
              <Formik
              initialValues={{
                categoryName: selectedItem?.name,
              }}
              // validationSchema={genderValidationSchema}
              onSubmit={(values, { resetForm }) => {
                handleUpdateItem(values, selectedItem.id);
              }}
            >
              {({ values }) => {
                return (
                  <Form>
                    <div class="mb-3">
                      <FormikField
                        name="categoryName"
                        label="Category title"
                        type="text"
                      />
                    </div>

                   
                     <div className="d-flex m-3 gap-3 ">
                      <button
                        type="button"
                        class="btn btn-light"
                        data-bs-dismiss="modal"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        class="btn text-white  bg-primary "
                        // onClick={handleUpdateItem}
                      >
                        Save Changes
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            </Modal.Body>
          
        </div>
      </div>
    </Modal>
  );
};

export default EditCommentCategoryModal;
