import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CSVLink } from "react-csv";
import { apiPrivate } from "../../api";
import jsPDF from "jspdf";

export const ExportCSV = createAsyncThunk(
  "commentcsv/ExportCSV",

  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get(`api/export/csv/${data}`);

      const dataString = response.data;

      const doc = new jsPDF();
      let y = 20;
      let prevName = "";
      let prevSemester = "";
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(18);
      doc.text("Feedback", 20, y + 20);
      dataString.slice(1).forEach((item) => {
        const name = item.name.charAt(0).toLowerCase()
          ? item.name.charAt(0).toUpperCase() + item.name.slice(1)
          : item.name;
        const semester = item.name.charAt(0).toLowerCase()
          ? item.semester.charAt(0).toUpperCase() + item.semester.slice(1)
          : item.semester;
        const subject = item.name.charAt(0).toLowerCase()
          ? item.subject.charAt(0).toUpperCase() + item.subject.slice(1)
          : item.subject;
        // doc.setFont("Helvetica", "bold");
        // doc.setFontSize(12);
        // doc.text(name, 20, y + 30);
        // doc.setFont("helvetica", "normal");
        // doc.setFontSize(10);
        // doc.text(item.semester, 20, y + 40);

        if (prevName !== name || prevSemester !== item.semester) {
          doc.setFont("Helvetica", "bold");
          doc.setFontSize(12);
          doc.text(name, 20, y + 30);

          doc.setFontSize(10);
          doc.text(semester, 20, y + 35);
          prevName = name;
          prevSemester = item.semester;
        }
        doc.setFont("helvetica", "bold");
        doc.text(subject, 20, y + 40);
        doc.setFont("helvetica", "normal");
        const commentLines = doc.splitTextToSize(item.comment, 140);
        y -= 14;
        commentLines.forEach((line) => {
          // const lineHeight =
          //   doc.internal.getLineHeight() / doc.internal.scaleFactor;
          const lineHeight =
            doc.internal.getLineHeight() / doc.internal.scaleFactor;

          if (y + 70 + lineHeight > doc.internal.pageSize.height - 20) {
            doc.addPage();
            y = 20;
          }
          doc.text(line, 20, y + 60);
          // y += lineHeight;
          let k = 1;
          y += k += lineHeight;
          // doc.text(line, 20, y + 70);
          // y += 10;
        });

        // y += 15;
        let j = 13;
        y += j += doc.getLineHeight();
        if (y > doc.internal.pageSize.height - 20) {
          doc.addPage();
          y = 10;
        }

        // if (y + 10 > doc.internal.pageSize.height - 20) {
        //   doc.addPage();
        //   y = 10;
        // } else {
        //   y += 20;
        // }
      });

      doc.setFont("Helvetica", "bold");
      doc.setFontSize(8);
      doc.save("data.pdf");

      return response.data;
    } catch (error) {
      const message = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const csvSlice = createSlice({
  name: "commentcsv",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [ExportCSV.pending]: (state) => {
      state.status = "loading";
    },
    [ExportCSV.fulfilled]: (state) => {
      state.status = "succeeded";
    },
    [ExportCSV.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { setData } = csvSlice.actions;

export default csvSlice.reducer;
