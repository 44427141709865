import React from "react";
import { Grid } from "react-loader-spinner";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Grid
        height="80"
        width="80"
        color="#3e7bfa"
        ariaLabel="grid-loading"
        radius="8"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
