import React from "react";

import { ErrorMessage, Field } from "formik";

const GenderDropdown = ({ name, label, selected, options }) => {
  return (
    <Field as="select" name={name}>
      {(formikField) => {
        return (
          <>
            <div>
              {label && (
                <label for="" class="form-label">
                  {label}
                </label>
              )}
              <select
                placeholder="Select"
                className="form-select"
                value={formikField.field.value || ""}
                {...formikField.field}
              >
                <option>Select</option>
                {options?.map((item) => {
                  return (
                    <option key={item?.id} value={item?.id}>
                      {item?.title}
                    </option>
                  );
                })}
              </select>
            </div>
            <ErrorMessage name={name}>
              {(errMessage) => {
                return <div className="err-msg">{errMessage}</div>;
              }}
            </ErrorMessage>
          </>
        );
      }}
    </Field>
  );
};

export default GenderDropdown;
