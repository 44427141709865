import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import ClassAccordian from "../../components/Reports/ClassAccordian";
import { getSemesters } from "../../store/semester/semesterThunk";
import { getStudents } from "../../store/students/studentsThunk";
import { getClass } from "../../store/class/classThunk";
import { getSubjects } from "../../store/subjects/subjectThunk";
const ReportMain = () => {
  const dispatch = useDispatch();
  const [selectedClass, setSelectedClass] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const studentsData = useSelector((state) => state.reducer.students);
  const [selectedStudent, setSelectedStudent] = useState("");

  const classData = useSelector((state) => state.reducer.class);

  useEffect(() => {
    dispatch(getSemesters());
    dispatch(getStudents());

    dispatch(getClass());
    dispatch(getSubjects());
  }, [dispatch, refresh]);

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };
  return (
    <div className="d-flex align-items-center justify-content-center students-comp">
      <div className="box-item">
        <div className="d-flex justify-content-between align-items-center m-3 mt-2">
          <h3 className="title3">Students</h3>
          <div className="report-select">
            <select
              placeholder="Select"
              className="form-select"
              value={selectedClass}
              onChange={handleClassChange}
            >
              <option>Select</option>
              {classData?.data?.map?.((item) => {
                return (
                  <option key={item?.id} value={item?.id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {classData?.data
          ?.filter((item) =>
            selectedClass && selectedClass !== "Select"
              ? item.id === Number(selectedClass)
              : true
          )
          ?.map((item) => {
            return (
              <ClassAccordian
                headerText={item?.name}
                classData={item}
                studentsData={studentsData}
                setSelectedStudent={setSelectedStudent}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ReportMain;
