

import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import loginImg from "../../assets/login-img.png";

import FormikField from "../../components/Common/InputField.jsx";
import CommonButton from "../../components/Common/CommonButton.jsx";

import logo from "../../assets/logo.png";
import { useDispatch } from "react-redux";
import { signupUser } from "../../store/auth/authThunk";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { apiInstance, apiPrivate } from "../../api/index.js";
import EmailVerificationModal from "../../components/AddModals/EmailVerificationModal.jsx";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tokenInputValue, setTokenInputValue] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTokenInputValue("");
  };

  const handleVerification = async () => {
    try {
      await apiInstance.get(`accounts/verify_email/${tokenInputValue}/`);
      toast.success("Email verified successfully");
      closeModal();
      navigate("/login")
    } catch (error) {
      const message = error.response?.data || "Error verifying email";
      toast.error(message);
    }
  };


 

  const signupHandler = async (values) => {
    try {
     const response = await dispatch(signupUser(values));
      if (response.payload.message === 'Please verify your email address') {
        toast.success("Check you email for verification token")
        openModal(); // Open the modal only if signup is successful
      } else {
        // Handle other status scenarios, if needed
        
      }
    } catch (error) {
      // Handle signup error, if needed
      console.error("Signup failed:", error);
      const message =
        error?.response?.data?.email?.[0] || error?.response?.data?.error?.[0] || "Something went wrong";
      toast.error(message);
    }
  };

  const validationSchema = Yup.object().shape({
    teacherId: Yup.string().required("Full Name is required"),
    contactNumber: Yup.string().required("Contact Number is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),

    password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[a-zA-Z\d])[a-zA-Z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/, 'Password must be alphanumeric with optional special characters')
    .matches(/\d*[a-zA-Z]\d*/, 'Password must contain at least one letter')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  });

  

  return (
    <div className="container-fluid bg-f2f6ff">
      <div className="row align-items-center">
        <div className="col-sm-12 col-md-6 col-lg-5 p-0 border-12">
          <div className="form-holder bg-white border-12">
            <div className="logIn-form px-5 pt-2 pb-0">
              <div className="logo">
                <img src={logo} className="img-fluid" alt="" />
              </div>
              <h2 className="title1">Sign Up</h2>
              <p className="title5 my-1">Create Account Now</p>
              <Formik
                initialValues={{
                  teacherId: "",
                  contactNumber: "",
                  email:"",
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => signupHandler(values)}
              >
                <Form className="my-3">
                  <div className="mb-3 width-100">
                    <FormikField name="teacherId" label="Full Name" type="text" placeholder="eg. John Doe" />
                  </div>
                  <div className="mb-3 width-100">
                    <FormikField
                      name="contactNumber"
                      label="Contact Number"
                      type="text"
                    />
                  </div>
                  <div className="mb-3 width-100">
                    <FormikField
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="email@example.com"
                    />
                  </div>
                  <div className="mb-3 width-100">
                    <FormikField
                      name="password"
                      label="Password"
                      type="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="mb-3 width-100">
                    <FormikField
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      placeholder="Confirm Password"
                    />
                  </div>

                  <CommonButton
                    variant="primary"
                    text="Sign Up"
                    type="submit"
                  />

                  <div className="text-center my-3">
                    Already have an account? <Link to="/login" className=" text-decoration-none ">Login</Link>
                  </div>
                </Form>
              </Formik>
              <div className="bottom-sec">
                <p className="copy-right span1 mb-3">
                  All Rights Reserved <span>© ClassView360</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-7 p-0">
          <div class="rgtside-login p-5">
            <div class="bootom-img">
              <img src={loginImg} class="img-fluid" alt="" />
            </div>
          </div>
        </div>      </div>

        <EmailVerificationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onVerify={handleVerification}
        tokenInputValue={tokenInputValue}
        setTokenInputValue={setTokenInputValue}
      />
    </div>
  );
};

export default Signup;

