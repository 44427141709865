import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { apiPrivate } from "../../api";

export const getStudents = createAsyncThunk(
  "students/getStudents",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/students/");
      return response.data;
    } catch (error) {
      const message = error.response.data;

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getStudentsBySemester = createAsyncThunk(
  "students/getStudentsBySemester",
  async (id, thunkAPI) => {
    try {
      const response = await apiPrivate.get(`api/students/by/${id}`);
      return response.data;
    } catch (error) {
      const message = error.response.data;

      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const addStudent = createAsyncThunk(
  "students/addStudent",
  async (data, { dispatch }, thunkAPI) => {
    // debugger
    try {
      const formData = new FormData();
      formData.append("subjects", data.subject);
      formData.append("student_name", data.studentName);
      formData.append("student_email", data.studentEmail);
      formData.append("gender", data.gender);
      formData.append("semester", data.semester);
      formData.append("class_name", data.className);
      if (data.selectedImg) {
        // formData.append("student_img", data.selectedImg);
        formData.append("student_img", data.selectedImg, data.selectedImg.name);
      }
      // formData.append("student_img", data.selectedImage);
      await apiPrivate.post("api/students/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Successfully added student.")
      dispatch(getStudents());
    } catch (error) {
      toast.error(error?.response?.data?.[0])

      // console.error("Error adding student:", error?.response?.data[0]);
    }
  }
);

export const deleteStudent = createAsyncThunk(
  "students/deleteStudent",
  async (id, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.delete(`api/students/${id}/`);
      dispatch(getStudents());
      toast.success("Successfully Deleted Student");
    } catch (error) {
      const message = error.response.data;
      toast.error(message);
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateStudent = createAsyncThunk(
  "students/updateStudent",
  async (data, { dispatch }, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("subjects", data.subjects?.join(","));
      formData.append("student_name", data.studentName);
      formData.append("student_email", data.studentEmail);
      formData.append("gender", data.gender);
      formData.append("semester", data?.semester);
      formData.append("class_name", data.className);
      if (data.selectedImg) {
        // formData.append("student_img", data.selectedImg);
        formData.append("student_img", data.selectedImg, data.selectedImg.name);
      }
      await apiPrivate.put(`api/students/${data?.id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(getStudents());
      toast.success("Successfully Updated Student");
    } catch (error) {
      const message = error.response.data;
      toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);





export const promoteStudents = createAsyncThunk(
  'students/promoteStudents',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      // Make the API request to update student class
      const response = await apiPrivate.put('api/update_student_class/', data);

      // Dispatch an action to fetch updated student data
      dispatch(getStudents());

      // Display a success toast message
      toast.success('Students promoted successfully');

      // Return the response data
      return response.data;
    } catch (error) {
      // Handle the error response
      const errorMessage = error.response?.data || 'Failed to promote students. Please try again.';

      // Display an error toast message
      toast.error(errorMessage);

      // Reject the promise with the error message
      return rejectWithValue(errorMessage);
    }
  }
);

