import {
    addClass,
    deleteClass,
    getClass,
    updateClass,
  } from "./classThunk";
  
  export const reducerHelpers = (builder) => {
    //* Gender Data ====================================
    builder.addCase(getClass.pending, (state, action) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getClass.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      state.status = "fulfilled";
    });
    builder.addCase(getClass.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.status = "rejected";
    });
  
    //* Add Gender Data ====================================
    builder.addCase(addClass.pending, (state, action) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(addClass.fulfilled, (state, action) => {
      state.loading = false;
      // state.data.push(action.payload);
      // state.data = action.payload;
      state.error = null;
      state.status = "fulfilled";
    });
    builder.addCase(addClass.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.status = "rejected";
    });
  
    //* Deleter Gender Data ====================================
    builder.addCase(deleteClass.pending, (state, action) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(deleteClass.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.status = "fulfilled";
    });
    builder.addCase(deleteClass.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.status = "rejected";
    });
  
    //* Deleter Gender Data ====================================
    builder.addCase(updateClass.pending, (state, action) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateClass.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.status = "fulfilled";
    });
    builder.addCase(updateClass.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.status = "rejected";
    });
  };
  