import * as Yup from "yup";
import Semester from "../pages/semester/Semester";

// * Semester Validation

export const semesterVaidationSchema = Yup.object().shape({
  semesterName: Yup.string().max(50, "Must be less than 50 characters").required("Semester name is required"),

  semesterStartDate: Yup
    .date()
    .typeError("Invalid date")
    .required("Semester Start Date is required"),

  semesterEndDate: Yup
    .date()
    .typeError("Invalid date")
    .required("Semester End Date is required")
    .min(Yup.ref('semesterStartDate'), "End Date must be greater Start Date"),

  subjects: Yup.array()
    .required("Please select at least one option")
    .min(1, "Please select at least one option")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),

  enableMultiple: Yup.boolean(),
});


// * Subject Validation
export const subjectValidationSchema = Yup.object().shape({
  subjectName: Yup.string()
    .max(50, "Must be less than 50 characters")
    .required("Subject is required"),
   
});

//* Class validation
export const classValidationSchema = Yup.object().shape({
  className: Yup.string().required("Class name is required"),
  classSemester: Yup.array()
    .required("Please select at least one option")
    .min(1, "Please select at least one option")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
});
// * Student Validation
export const studentsValidationSchema = Yup.object().shape({
  studentEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  subjects: Yup.array()
    .required("Please select at least one option")
    .min(1, "Please select at least one option")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
  enableMultiple: Yup.boolean(),
 
});

// * Comments Validation
export const commentsValidationSchema = Yup.object().shape({
  commentText: Yup.string()
    .max(150, "Must be less than 50 characters")
    .required("Comment is a required field"),
  name: Yup.string().required("Category is a required field"),
});
//* Comment Category validation

export const commentsCategoryValidationSchema = Yup.object().shape({
  categoryName: Yup.string().required("Category is required"),
});
//* Subject Category validation
export const subjectsCategoryValidationSchema = Yup.object().shape({
  categoryName: Yup.string().required("Category is required"),
});
// * Gender Validation
export const genderValidationSchema = Yup.object().shape({
  title: Yup.string().max(50, "Must be less than 50 characters"),
  // .required("Gender Title is required"),
  pronouns: Yup.string().max(50, "Must be less than 50 characters"),
  // .required("Pronouns are required."),
});
export const promoteValidationSchema = Yup.object().shape({
  class_id: Yup.string().required('Class is required'),
  semester_id: Yup.string().required('Semester is required'),
});