import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import {
  semesterVaidationSchema,
  studentsValidationSchema,
} from "../../helpers/validationSchemas";
import FormikField from "../Common/InputField";
import FormikMultiSelect from "../Common/MultiSelect";
import FormikSelect from "../Common/DropDown";
import GenderDropdown from "../Common/GenderDropdown";
import SemesterDropdown from "../Common/SemesterDropdown";
import ClassDropdown from "../Common/ClassDropdown";
import CommonButton from "../Common/CommonButton";
import imageicon from "../../assets/imageicon.png";
const EditStudentModal = ({
  showEditModal,
  handleEditToggle,
  handleUpdateItem,
  selectedItem,
  genderData,
  subjectsData,
  semestersData,
  classData,
  handleCloseToggle,
  setShowEditModal
  
}) => {
  const dataCheck = subjectsData?.data?.map((option) => {
    return { value: option?.id, label: option?.subject_name };
  });
  const [imagePreview, setImagePreview] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  // Pass the selected item as a prop to the Select component
  const selectedItems = selectedItem
    ? dataCheck.filter((item) =>
        selectedItem?.subjects_ids?.find((i) => item?.value === i)
      )
    : null;

    const handleCancel =()=>{

      setShowEditModal(!showEditModal)
     
       }
  return (
    <Modal show={showEditModal} onHide={handleEditToggle}>
      <div class="modal-dialog modal-dialog-centere m-0">
        <div class="modal-content">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Edit Student</Modal.Title>
        </Modal.Header>
<Modal.Body>
            <Formik
              initialValues={{
                studentName: selectedItem?.student_name,
                studentEmail: selectedItem?.student_email,
                gender: selectedItem?.gender_id,
                subjects: selectedItems,
                semester: selectedItem?.semester_id,
                className: selectedItem?.class_id,
                studentImg: selectedItem?.student_img,
              }}
              validationSchema={studentsValidationSchema}
              onSubmit={(values, { resetForm }) => {
                handleUpdateItem(values, selectedItem?.id, selectedImage);
              }}
            >
              {({ values }) => {
                return (
                  <Form>
                    <div className="row">
                      <div class="image-upload py-3 col-12 m-auto w-25 mb-4">
                        <label for="file-input">
                          <img
                            src={
                              imagePreview ||
                              selectedItem?.student_img ||
                              imageicon
                            }
                            alt=""
                            className="img-fluid"
                          />
                        </label>
                        <input
                          name="studentImg"
                          id="file-input"
                          type="file"
                          onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                              setImagePreview(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }
                            setSelectedImage(e.target?.files?.[0]);
                          }}
                        />
                        <p className="m-0 title5">Add Image</p>
                      </div>
                    </div>
                    <div className="row">
                      <div class="mb-3 col-6">
                        <FormikField
                          name="studentName"
                          label="Student Name"
                          type="text"
                        />
                      </div>
                      <div class="mb-3 col-6">
                        <FormikField
                          name="studentEmail"
                          label="Student Email"
                          type="email"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div class="mb-3 col-6 ">
                        <GenderDropdown
                          name="gender"
                          label="Gender"
                          options={genderData?.data}
                          selected={selectedItem?.gender}
                          // displayProperty="title"
                        />
                      </div>
                      <div class="mb-3 col-6 ">
                        <ClassDropdown
                          name="className"
                          label="Class"
                          options={classData?.data}
                          selected={selectedItem?.className}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div class="mb-3 col-6 ">
                        <FormikMultiSelect
                          name="subjects"
                          label="Subject"
                          options={dataCheck}
                          selectedItem={selectedItem}
                        />
                      </div>
                      <div class="mb-3 col-6 ">
                        <SemesterDropdown
                          name="semester"
                          label="Semester"
                          options={semestersData?.data}
                          displayProperty="semester_name"
                          selected={selectedItem?.semester}
                          classData={classData?.data}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row gap-3   mt-5 mb-3">
                      <CommonButton
                        variant="light px-5"
                        onClick={handleCancel}
                        text="Cancel"
                        type="button"

                      />
                      <CommonButton
                        variant="primary px-5"
                        text="Save"
                        type="submit"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};
export default EditStudentModal;







