
import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api, { apiPrivate } from "../../api";

export const getClass = createAsyncThunk(
  "class/getClass",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/class/");
      return response.data;
    } catch (error) {
      const message = error.response.data;

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addClass = createAsyncThunk(
  "class/addClass",
  async (data, { dispatch }, thunkAPI) => {
    try {
      const response = await apiPrivate.post("api/class/", {
        name: data.className,
        semester: data.classSemester,
      });

      dispatch(getClass());
      toast.success("Successfully added Class");
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const message = error.response.data[0];

        toast.error(message);
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const deleteClass = createAsyncThunk(
  "class/deleteClass",
  async (id, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.delete(`api/class/${id}/`);
      dispatch(getClass());
      toast.success("Successfully Deleted Class");
    } catch (error) {
      const message = error.response.data;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateClass = createAsyncThunk(
  "class/updateClass",
  async (data, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.put(`api/class/${data?.id}/`, {
        name: data.className,
        semester: data.classSemester,
      });
      dispatch(getClass());
      toast.success("Successfully Updated Class");
    } catch (error) {
      const message = error.response.data;
      toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);
