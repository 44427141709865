// EmailVerificationModal.jsx

import React from "react";
import { Modal, Button } from "react-bootstrap";
import FormikField from "../Common/InputField";
import CommonButton from "../Common/CommonButton";

const EmailVerificationModal = ({
  isOpen,
  onClose,
  onVerify,
  tokenInputValue,
  setTokenInputValue,
}) => {
  return (
    <div className="m-3">
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Email Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className=" fw-medium ">Please enter the verification token sent to your email:</p>
        <input
          type="text"
          value={tokenInputValue}
          className={`form-control w-50 `}

          onChange={(e) => setTokenInputValue(e.target.value)}
        />

<div className="select-btn mt-4 d-flex gap-3 ">
                        <CommonButton
                          variant="light px-5 "
                          type="button"
                          onClick={onClose}
                          text="Close"
                        />
                        <CommonButton
                          variant="primary px-5"
                          text="Verify"
                          type="button"
onClick={onVerify}                        />
                       
                      </div>

{/* <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={onVerify}>
          Verify
        </Button> */}
        
      </Modal.Body>
      
    </Modal>
    </div>
  );
};

export default EmailVerificationModal;
