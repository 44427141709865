import {
  addCategory,
  deleteComment,
  getCategory,
  getCategoryStepper,
  updateCategory,
} from "./commentCategoryThunk";

export const reducerHelpers = (builder) => {
  //* Gender Data ====================================
  builder.addCase(getCategory.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getCategory.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getCategory.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Gender Data ====================================
  builder.addCase(getCategoryStepper.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getCategoryStepper.fulfilled, (state, action) => {
    state.loading = false;
    state.stepperData = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getCategoryStepper.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Add Gender Data ====================================
  builder.addCase(addCategory.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(addCategory.fulfilled, (state, action) => {
    state.loading = false;
    // state.data.push(action.payload);
    // state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(addCategory.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Gender Data ====================================
  builder.addCase(deleteComment.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(deleteComment.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(deleteComment.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });

  //* Deleter Gender Data ====================================
  builder.addCase(updateCategory.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(updateCategory.fulfilled, (state, action) => {
    state.loading = false;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(updateCategory.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });
};
