import { getSubjectCategory } from "./subjectCategoryThunk";

export const reducerHelper = (builder) => {
  //* Gender Data ====================================
  builder.addCase(getSubjectCategory.pending, (state, action) => {
    state.loading = true;
    state.status = "pending";
  });
  builder.addCase(getSubjectCategory.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.error = null;
    state.status = "fulfilled";
  });
  builder.addCase(getSubjectCategory.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.status = "rejected";
  });
};
