import React from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { genderValidationSchema } from "../../helpers/validationSchemas";
import FormikField from "../Common/InputField";
import CommonButton from "../Common/CommonButton";

const EditGenderModal = ({
  showEditModal,
  setShowEditModal,
  handleEditToggle,
  handleUpdateItem,
  selectedItem,
  handleCloseToggle
}) => {
  const handleCancel =()=>{

 setShowEditModal(!showEditModal)

  }
  return (
    <Modal show={showEditModal} onHide={handleEditToggle}>
      <div class="modal-dialog modal-dialog-centere m-0">
        <div class="modal-content">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Edit Gender</Modal.Title>
        </Modal.Header>
<Modal.Body>
            <Formik
              initialValues={{
                title: selectedItem?.title,
                pronouns: selectedItem?.pronoun,
              }}
              validationSchema={genderValidationSchema}
              onSubmit={(values, { resetForm }) => {
                handleUpdateItem(values, selectedItem.id);
              }}
            >
              {({ values }) => {
                return (
                  <Form>
                    <div className="row">

                      <div class="mb-3 col-6">
                        <FormikField name="title" label="Gender" type="text" />
                      </div>
                      <div class="mb-3 col-6">
                        <FormikField
                          name="pronouns"
                          label=" Add Pronouns"
                          type="text"
                        />
                      </div>

                    </div>


                    <div className="d-flex flex-row gap-3  my-3">
                    
                      <CommonButton variant="light px-5" onClick={handleCancel} text="Cancel"  type="button"/>
                      <CommonButton variant="primary px-5" text="Save" type="submit" />

                      
                    </div>
                  </Form>
                );
              }}
            </Formik>
            </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default EditGenderModal;
