import React, { useEffect, useState } from "react";
import Statistics from "../../components/Dashboard/Statistics.jsx";
import StudentPerformance from "../../components/Dashboard/StudentPerformance.jsx";
import LatestComments from "../../components/Dashboard/LatestComments.jsx";
import RecentActivites from "../../components/Dashboard/RecentActivites.jsx";
import Subjects from "../../components/Dashboard/Subjects.jsx";
import Students from "../../components/Dashboard/Students.jsx";
import { FaGraduationCap, FaUserGraduate } from "react-icons/fa";
import { IoLibrary } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  getLatestComments,
  getPerformance,
  getRecentActivity,
  getStats,
  getStudentPerformance,
  getUserInfo,


} from "../../store/dashboard/dashboardThunk.js";
import { getSubjects } from "../../store/subjects/subjectThunk.js";
import { getStudents } from "../../store/students/studentsThunk.js";
import { getToken } from "../../helpers/index.js";
import Loader from "../../components/Common/Loader.jsx";
import { toast } from "react-toastify";
import { getSemesters } from "../../store/semester/semesterThunk.js";
import Semesters from "../../components/Dashboard/Semesters.jsx";
import { dashStudentsColumns, daysColumns, genderColumns, semesterColumns, studentsColumns } from "../../helpers/tableColumns.js";
import DataGrid from "../../components/Common/DataGrid.jsx";
import semesterIcon from "../../assets/Group 2225.png"
import classIcon from "../../assets/Group 2226.png"
import studentIcon from "../../assets/Group 2227.png"
import subjectIcon from "../../assets/Group 2229.png"
import { getClass } from "../../store/class/classThunk.js";
import { getGenders } from "../../store/gender/genderThunk.js";
import Calendar from "react-calendar";
import { date } from "yup";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [offset, setOffset] = useState(0);

  const [pageIndex, setPageIndex] = useState(0);

  const incrementPageIndex = () => {
    setPageIndex(pageIndex + 1);
  };

  useEffect(() => {
    dispatch(getStudents())
    dispatch(getStats());
    dispatch(getPerformance());
    dispatch(getRecentActivity());
    dispatch(getSemesters());
    dispatch(getClass())
    dispatch(getGenders())
    // dispatch(getStudentPerformance())
  }, [dispatch]);



  const { stats, recentActivity, loading } = useSelector(
    (state) => state.reducer.dashboard
  );
  const semesterData = useSelector((state) => state.reducer.semester);
  const studentData = useSelector((state) => state.reducer.students);
  const classData = useSelector((state) => state.reducer.class);
  const genderData = useSelector((state) => state.reducer.gender);
  const numberOfItems = studentData.data ? studentData.data.length : 0;
  const numbersArray = Array.from({ length: numberOfItems }, (_, index) => ({ value: index + 1 }));
  
  const columnss = dashStudentsColumns(studentData?.data,numbersArray);


  const tileDisabled = () => {
    return true
  }

  

  const combinedData = studentData.data.map((student, index) => ({
    ...student,
    number: numbersArray[index]?.value,
  }));

  const columns = daysColumns();
  return (
    <div className="row">

      <div className="row col-md-12 col-lg-8 d-flex ">
        <div className="col-6 col-lg-3">
          <Statistics
            image={semesterIcon}
            text="Total Semesters"
            count={stats?.semester_count}
            color="magenta"
          />
        </div>
        <div className="col-6 col-lg-3 stat2">
          <Statistics
            image={studentIcon}
            text="Total Students"
            count={stats?.student_count}
            color='green'
          />
        </div>
        <div className="col-6 col-lg-3 stat3">
          <Statistics
            image={subjectIcon}
            text="Total Subjects"
            count={stats?.subject_count}
            color='blue'
          />
        </div>
        <div className="col-6 col-lg-3 stat3">
          <Statistics
            image={classIcon}
            text="Total classes"
            count={stats?.class_count}
            color='pink'
          />
        </div>
        <div className="col-12 mb-3 ">
        <div className=" box-item d-lg-none mb-3">
          <Calendar
            locale="en-US"
            calendarType="US"
          />
        </div>
          <StudentPerformance />
        </div>
       
        <div>

        <div className="box-item">
          <h4 className="p-3 fw-bold  ">New Students</h4>
          <DataGrid columns={columnss} data={combinedData} />
        </div>
        
      </div>
      </div>
      <div className=" row col-lg-4" style={{height:"min-content"}}>
        <div className="box-item d-md-none d-lg-block ">
          <Calendar
            locale="en-US"
            calendarType="US"
          />
        </div>
        <div className="box-item  p-4">
          <h3 className="title3 mb-3">Recent Activity</h3>
          <div className="activity-timeline">
            {recentActivity?.map((item) => (
              <RecentActivites
                action={item.action}
                message={item?.message}
                createdAt={item?.created_at}
                type={item?.type}
              />
            ))}
          </div>
        </div>
      </div>
     
     
     
    </div>
  );
};
export default Dashboard;
