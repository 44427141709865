import React, { useEffect, useState } from "react";
import StudentCard from "../Dashboard/StudentCard";
import studentImage from "../../assets/student-card.png";
import { FaGraduationCap } from "react-icons/fa";
import FormikField from "../Common/InputField";

const StudentAccordianList = ({
  headerText,
  studentsData,
  classData,
  setSelectedStudent,
  selectedStudent,
}) => {
  const [toggle, setToggle] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleToggle = () => {
    setToggle(!toggle);
  };

  
  const handleChange = (event) => {
    setSearchValue(event.target.value.toLowerCase());
  };
  
  const filteredData = studentsData?.data?.filter(
    (item) =>
      item?.semester?.includes(headerText?.semester_name) &&
      item.class_id === classData &&
      item?.student_name?.toLowerCase().includes(searchValue)
  );
 


  return (
    <div className="student-accordian">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item border-0">
          <h2 class="accordion-header" id="headingOne">
            <button
              onClick={() => handleToggle()}
              class="accordion-button collapsed"
              data-bs-toggle="collapse"
              type="button"
            >
              <FaGraduationCap />
              {headerText?.semester_name}
            </button>
          </h2>
          {toggle && (
            <div
              className={`accordion-collapse collapse ${toggle ? "show" : ""}`}
              style={{
                transition: "max-height 0.3s ease-out",
                maxHeight: toggle ? "1000px" : "0px",
                overflow: "hidden",
              }}
            >
              <div class="accordion-body">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    onChange={handleChange}
                  />
                </div>

                {filteredData?.map((item) => {
                  return (
                    <StudentCard
                      studentData={item}
                      image={studentImage}
                      setSelectedStudent={setSelectedStudent}
                      selectedStudent={selectedStudent}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentAccordianList;
