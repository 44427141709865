import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import CommonButton from "../../components/Common/CommonButton.jsx";
import FormikField from "../../components/Common/InputField.jsx";
import { toast } from "react-toastify";
import DataGrid from "../../components/Common/DataGrid.jsx";
import FormikSelect from "../../components/Common/DropDown.jsx";
import "../../index.css";
import Loader from "../../components/Common/Loader.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  addStudent,
  deleteStudent,
  getStudents,
  promoteStudents,
  updateStudent,
} from "../../store/students/studentsThunk.js";

import { getGenders } from "../../store/gender/genderThunk";
import { getSubjects } from "../../store/subjects/subjectThunk";
import { getSemesters } from "../../store/semester/semesterThunk";
import FormikMultiSelect from "../../components/Common/MultiSelect.jsx";
import { studentsColumns } from "../../helpers/tableColumns.js";
import { promoteValidationSchema, studentsValidationSchema, studentsvValidationSchema } from "../../helpers/validationSchemas.js";
import DeleteModal from "../../components/Common/DeleteModal.jsx";
import EditStudentModal from "../../components/Models/EditStudentModal.jsx";
import GenderDropdown from "../../components/Common/GenderDropdown.jsx";
import SemesterDropdown from "../../components/Common/SemesterDropdown.jsx";
import { downloadCSV } from "../../store/csv/csvSlice.js";
import { getClass } from "../../store/class/classThunk.js";
import ClassDropdown from "../../components/Common/ClassDropdown.js";
import { ExportCommentPDF } from "../../store/csv/allCommentSlice.js";
import { GrDownload } from "react-icons/gr";
import AddStudentModal from "../../components/AddModals/AddStudentModal.jsx";
import excelImg from "../../assets/icons/excel.png"
import printerImg from "../../assets/icons/printer.png"
import { Button, Modal } from "react-bootstrap";
const Students = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(downloadCSV());
  };


  const downloadpdf = () => {
    const data1 = classOptions;
    const data2 = semesterOptions;
    const data3 = subjectOptions;

    const id = [data1, data2, data3];

    dispatch(ExportCommentPDF(id));
    // dispatch(ExportCommentPDF());
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [closeModal, setCloseModal] = useState(false);
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [selectedSemesterId, setSelectedSemesterId] = useState(null);
  const [selectedPromoteSemester, setSelectedPromoteSemester] = useState(null); // New state for selected semester
  const [selectedClassId, setSelectedClassId] = useState(null);



  const [availableSemesters, setAvailableSemesters] = useState([]);
    const [availableModalSemesters, setAvailableModalSemesters] = useState([]);

  const [checkedRows, setCheckedRows] = useState([]);

  const { data, loading } = useSelector((state) => state.reducer.students);
  const [filteredArray, setFilteredArray] = useState();
  const genderData = useSelector((state) => state.reducer.gender);
  const subjectsData = useSelector((state) => state.reducer.subjects);
  const classData = useSelector((state) => state.reducer.class);
  const semestersData = useSelector((state) => state.reducer.semester);
  const [showAddModal, setShowAddModal] = useState(false);
  const [imagePreview, setImagePreview] = useState();
 const [classOptions, setClassOptions] = useState("Select Class");
  const [semesterOptions, setSemesterOptions] = useState("Select Semester");
  const [subjectOptions, setSubjectOptions] = useState("Select Subject");
  const numberOfItems = data ? data.length : 0;
  const [selectedModalClass, setSelectedModalClass] = useState("");
  const [selectedModalSemester, setSelectedModalSemester] = useState("");



  const numbersArray = Array.from({ length: numberOfItems }, (_, index) => ({
    value: index + 1,
  }));
  useEffect(() => {
    dispatch(getStudents());
    dispatch(getGenders());
    dispatch(getSubjects());
    dispatch(getSemesters());
    dispatch(getClass());
  }, [dispatch]);


  const handleCloseToggle = (row) => {
    setCloseModal(!closeModal);
  };


  useEffect(() => {
    const applyFilters = () => {
      return data?.filter?.((item) => {
        const classFilter = classOptions === null || item?.class_id === Number(classOptions);
        const semesterFilter = semesterOptions === null || ( item?.semester_id === Number(semesterOptions));

        const subjectFilter = subjectOptions === null || item?.subjects_ids?.includes(Number(subjectOptions));
    
        
        return classFilter && semesterFilter && subjectFilter;
      });
    };
    
    

    setFilteredArray(applyFilters());
  }, [classOptions, semesterOptions, subjectOptions, data, classData.data, semestersData.data, subjectsData.data]);

  // Reset filters when "Select" is clicked
  useEffect(() => {
    if (classOptions && classOptions.startsWith("Select")) {
      setClassOptions(null);
      setSemesterOptions(null); // Reset semester when class is selected
      setSubjectOptions(null);
    }
    if (semesterOptions && semesterOptions.startsWith("Select")) {
      setSemesterOptions(null);
    }
    if (subjectOptions && subjectOptions.startsWith("Select")) {
      setSubjectOptions(null);
    }
  }, [classOptions, semesterOptions, subjectOptions]);



  useEffect(() => {
    if (classOptions !== null && classOptions !== "Select Class" && classData.data) {
      const selectedClass = classData.data.find((item) => item.id === Number(classOptions));
  
      if (selectedClass) {
        setAvailableSemesters(selectedClass.semester);
      }
    }
     if (selectedModalClass !== null && selectedModalClass !== "Select Class" && classData.data) {
      const selectedClass2 = classData.data.find((item) => item.id === Number(selectedModalClass));
  
      if (selectedClass2) {
        setAvailableModalSemesters(selectedClass2.semester);
      }
    }
  }, [classOptions,selectedModalClass, classData.data,]);
  const handleAddToggle = (row) => {
    setShowAddModal(!showAddModal);
  };

  const handleDeleteToggle = async (row) => {
    setselectedItem(row);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteItem = () => {
    dispatch(deleteStudent(selectedItem?.id));
    handleDeleteToggle();
  };

  const handleEditToggle = (row) => {
    setselectedItem(row);
    setShowEditModal(!showEditModal);
  };

  const handleUpdateItem = (values, id, selectedImage) => {
    values.id = id;
    let ids = [];
    values?.subjects?.map((subj) => {
      ids.push(subj.value);
    });
    delete values.subjects;
    values.subjects = ids;
    if (selectedImage) {
      values.selectedImg = selectedImage;
    }
    dispatch(updateStudent(values)).then(() => {
      setImagePreview("");
    });
    handleEditToggle();
  };



  // Function to handle checkbox changes
  const handleCheckboxChange = (rowId) => {
    setCheckedRows((prevCheckedRows) => {
      const isChecked = prevCheckedRows.includes(rowId);
  
      if (isChecked) {
        // If already checked, remove from the array
        const updatedRows = prevCheckedRows.filter((id) => id !== rowId);

        console.log("Updated checkedRows:", updatedRows);

        return updatedRows
      } else {
        // If not checked, add to the array
        const updatedRows = [...prevCheckedRows, rowId];

        console.log("Updated checkedRows:", updatedRows);

        return updatedRows
      }
    });
  };
  


const handlePromoteToggle = () => {
  setShowPromoteModal(!showPromoteModal);
};


  const columns = studentsColumns(
    handleEditToggle,
    handleDeleteToggle,
    classData?.data,
    handleCheckboxChange,
    checkedRows,


  );



  if (loading) {
    return <Loader />;
  }

  const dataCheck = subjectsData?.data?.map((option) => {
    return { value: option?.id, label: option?.subject_name };
  });

  const combinedFilteredArray = filteredArray?.map((student, index) => ({
    ...student,
    number: numbersArray[index]?.value,
  }));



  const handleClassModal = (selectedItem) => {
   if (selectedItem !== "Select Class") {
    setSelectedModalClass(selectedItem);
  } else {
    setSelectedModalClass(null); // Save nothing in state
  }
  };

  const handleSemesterModal = (selectedItem) => {
    if (selectedItem !== "Select Semester") {
      setSelectedModalSemester(selectedItem);
    } else {
      setSelectedModalSemester(null); 
    }
  };
  const handlePromoteStudents = async () => {
    try {
      // Ensure that both class and semester are selected
      if (!selectedModalClass || !selectedModalSemester) {
        toast.error('Please select both class and semester.');
        return;
      }
  
      // Ensure that at least one student is selected for promotion
      if (checkedRows.length === 0) {
        toast.error('Please select at least one student for promotion.');
        return;
      }
  
      // Gather the necessary data to send to the API
      const promoteData = {
        class_id: selectedModalClass,
        semester_id: selectedModalSemester,
        student_ids: checkedRows,
      };
  
      // Dispatch the promoteStudents Thunk action
      await dispatch(promoteStudents(promoteData));
  
      // Reset selected items and close the modal after successful promotion
      setCheckedRows([]);
      setSelectedModalClass(null);
      setSelectedModalSemester(null);
  
      // Close the modal
      handlePromoteToggle();
  
      // Optionally, show a success message
      toast.success('Students promoted successfully!');
    } catch (error) {
      // Handle error scenarios (showing an error message, logging, etc.)
      console.error('Error promoting students:', error);
      // Optionally, show an error message
      toast.error('Failed to promote students. Please try again.');
    }
  };
  

  return (
    <div>
      <AddStudentModal
        showAddModal={showAddModal}
        handleAddToggle={handleAddToggle}
        addStudent={addStudent}
        selectedItem={selectedItem}
      />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteToggle={handleDeleteToggle}
        handleDeleteItem={handleDeleteItem}
      />
      <EditStudentModal
        showEditModal={showEditModal}
        handleEditToggle={handleEditToggle}
        handleUpdateItem={handleUpdateItem}
        selectedItem={selectedItem}
        genderData={genderData}
        subjectsData={subjectsData}
        semestersData={semestersData}
        handleCloseToggle={handleCloseToggle}
        classData={classData}
        setShowEditModal={setShowEditModal}

      />
      
      <div className=" w-100 d-flex flex-wrap-reverse gap-2 justify-content-end my-3">
      <div>
          <button onClick={handlePromoteToggle} className="pdf-btn">
          

            Promote
          </button>
        </div>
        <div>
          <button onClick={downloadpdf} className="print-btn border-2">
            <img src={printerImg} className="me-1" />
            Print
          </button>
        </div>

        <div>
          <button onClick={handleClick} className="pdf-btn">
            <img src={excelImg} className="me-1" />

            Export Students
            <span>{/* <GrDownload style={{ color: "white" }} /> */}</span>
          </button>
        </div>
        <CommonButton
          variant="primary"
          text="+ Create New"
          onClick={() => handleAddToggle()}
        />
      </div>

      <div className="data-table-container">
        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <h3 className="title3 mb-4 px-3">Student List</h3>
          </div>
          <div className="d-flex sm:flex-column xl:flex-row gap-2 flex-wrap justify-content-between m-2 ">
        
      <div>
  <select
    placeholder="Select"
    value={classOptions}
    className="form-select width-200"
    onChange={(e) => {
      setClassOptions(e.target.value);
      setSemesterOptions("Select Semester"); // Reset semester when class is selected
      setSubjectOptions("Select Subject");
    }}
  >
    <option>Select Class</option>
    {classData?.data?.map((item) => (
      <option key={item?.id} value={item?.id}>
        {item?.name}
      </option>
    ))}
  </select>
</div>

      <div>
        <select
        value={semesterOptions}
          placeholder="Select"
          className="form-select width-200"
          onChange={(e) => {
            setSemesterOptions(e.target.value);
          }}
        >
          <option>Select Semester</option>
          {availableSemesters?.map((semester) => (
            <option key={semester?.id} value={semester?.id}>
              {semester?.semester_name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <select
          placeholder="Select"
          className="form-select width-200"
          value={subjectOptions}
          onChange={(e) => {
            setSubjectOptions(e.target.value);
          }}
        >
          <option>Select Subjects</option>
          {subjectsData?.data?.map((item) => (
            <option key={item?.id} value={item?.id}>
              {item?.subject_name}
            </option>
          ))}
        </select>
      </div>

          </div>

        </div>
        {filteredArray && (
          <DataGrid columns={columns} data={combinedFilteredArray} />
        )}
      </div>

      <Modal show={showPromoteModal} onHide={handlePromoteToggle}>
  <Modal.Header closeButton>
    <Modal.Title>Promote Students</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <Formik
              initialValues={{
                class_id: '',
            semester_id: '',
              }}
              validationSchema={promoteValidationSchema}
             
            >
  <div className="row">
  <Form className="col-12">
  <div className="col-6 col-lg-6">
                 

  <div className="mb-4">
  <select
  id="class_id"
    placeholder="Select"
    value={selectedModalClass}
    className="form-select "
    onChange={(e) => {
      handleClassModal(e.target.value);
     
    }}
  >
    <option>Select Class</option>
    {classData?.data?.map((item) => (
      <option key={item?.id} value={item?.id}>
        {item?.name}
      </option>
    ))}
  </select>
</div>

      <div>
        <select
        id="semester_id"
        value={selectedModalSemester}
          placeholder="Select"
          className="form-select "
          onChange={(e) => {
            handleSemesterModal(e.target.value);
          }}
        >
          <option>Select Semester</option>
          {availableModalSemesters?.map((semester) => (
            <option key={semester?.id} value={semester?.id}>
              {semester?.semester_name}
            </option>
          ))}
        </select>
      </div>
                </div>

               
                </Form>
               
 
    </div>
</Formik>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handlePromoteToggle}>
      Close
    </Button>
    <Button variant="primary" onClick={handlePromoteStudents}>
            Promote
          </Button>
  </Modal.Footer>
</Modal>
    </div>


  );
};

export default Students;
