import React from "react";
import groupavatar from "../../assets/groupavatar.png";

const SubjectCard = ({ subjectData }) => {
  return (
    <div className="subject">
      <div className="subject-card-img">
        <img
          src={subjectData?.subject_img}
          className="img-fluid rounded"
          alt="..."
        />
      </div>
      <div className="subject-body">
        <h5 className="card-subtitle span1 my-1">
          {subjectData?.semester?.semester_name}
        </h5>
        <h5 className="card-title title5">{subjectData?.subject_name}</h5>
     
      </div>
    </div>
  );
};

export default SubjectCard;
