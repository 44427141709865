import React from "react";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { useState } from "react";
const colors = ["#7C5CAF", "#009BDE", "#FF6A4D"];

export default function Chart({
  reportsData,
  improvementsData,
  behaviourData,
  commentName1,
  commentName2,
  commentName3,
  behaviorChecked,
  academicsChecked,
  improvementsChecked
}) {

  console.log(academicsChecked, "academicsChecked"
  )
  const reportsTitle = useSelector((state) => state.reducer.reports);

  console.log(reportsData, improvementsData, behaviourData, "behaviourData")

  const acadamics = reportsData?.map?.((item) => ({
    createdAt: item?.created_at.substr(0, 10),
    rating: item?.academics_rating,
    comment: item?.academics_comment,
  }));

  const improvements = improvementsData?.map?.((item) => ({
    createdAt: item?.created_at.substr(0, 10),
    rating: item?.improvements_rating,
    comment: item?.improvements_comment,
  }));

  const behaviour = behaviourData?.map?.((item) => ({
    createdAt: item?.created_at.substr(0, 10),
    rating: item?.Behaior_rating,
    comment: item?.Behaior_comment,
  }));

  const CustomizedLabel = (props) => {
    const { x, y, stroke, value } = props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={2} textAnchor="middle">
        {value}
      </text>
    );
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          fontSize={10}
          x={0}
          y={0}
          dy={2}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let data = payload
      let newArray = [];

      if (behaviorChecked) {
        newArray = [...newArray, data.find(item => item.stroke === "#FF6A4D")];
      }
      if (academicsChecked) {
        newArray = [...newArray, data.find(item => item.stroke === "#7C5CAF")];
      }
      if (improvementsChecked) {
        newArray = [...newArray, data.find(item => item.stroke === "#009BDE")];
      }

      console.log(data, "oioio")
      return (
        <div className="custom-tooltip">
          <div
            style={{
              backgroundColor: "#ffffff8a",
              border: "1px solid #ccc",
              padding: "8px",
              maxWidth: "100%",
            }}
          >

            {newArray?.map((item) => {
              return (
                <div style={{ color: item?.color }}>
                  <p>{`Rating: ${item?.payload?.rating}`}</p>
                  {item?.payload?.comment ? (
                    <p>{`Comment: ${item?.payload?.comment}`}</p>
                  ) : null}
                </div>
              );
            })}

          </div>
        </div>
      );
    }

    return null;
  };





  const getColor = (value, stroke) => {
    return value?.comment ? "red" : stroke; // set the color based on the value
  };




  const CustomDot = (props) => {
    const { cx, cy, stroke, value, payload } = props;
    return (
      <svg
        x={cx - 5}
        y={cy - 5}
        width={10}
        height={10}
        fill={getColor(payload, stroke)}
        viewBox="0 0 10 10"
      >
        <circle cx={5} cy={5} r={5} fill={getColor(payload, stroke)} />
      </svg>
    );
  };
  const colors = ["#7C5CAF", "#009BDE", "#FF6A4D"];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={500} height={300}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="createdAt"
          type="category"
          axisLine={false}
          tick={<CustomizedAxisTick />}
          height={60}
        // allowDuplicatedCategory={false}
        />
        <YAxis axisLine={false}  domain={[0, 10]} ticks={[0, 2, 4, 6, 8, 10]} />
        {/* <Tooltip /> */}
        <Tooltip content={<CustomTooltip academicsChecked={academicsChecked}
          improvementsChecked={improvementsChecked}
          behaviorChecked={behaviorChecked} />} />
        <Legend
          margin={{ top: 10 }}
          payload={[
            { value: commentName1, color: colors[0] },
            { value: commentName2, color: colors[1] },
            { value: commentName3, color: colors[2] },
          ]}
        />

        <Line
          dataKey="rating"
          stroke={colors[2]}
          strokeWidth={3}
          data={behaviorChecked ? behaviour : []} 
          dot={<CustomDot />}
          activeDot={{ r: 8 }}
          isAnimationActive={false}
        />
        <Line
          dataKey="rating"
          stroke={colors[1]}
          strokeWidth={3}
          data={improvementsChecked ? improvements : []} 
          dot={<CustomDot />}
          activeDot={{ r: 8 }}
          isAnimationActive={false}
        />
        <Line
          dataKey="rating"
          stroke={colors[0]}
          strokeWidth={3}
          dot={<CustomDot />}
          data={academicsChecked ? acadamics : []} 
          activeDot={{ r: 8 }}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
