import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const FormikMultiSelect = ({ label, name, options, selectedItem }) => {
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <Field name="subject">
        {({ formikField, form }) => {
          return (
            <Select
              defaultValue={form?.values?.subjects}
              {...formikField}
              isMulti
              name={name}
              options={options}
              value={formikField?.field.value || form?.values?.subjects}
              onChange={(option) => form.setFieldValue(name, option)}
            />
          );
        }}
      </Field>
      <div className="err-msg">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default FormikMultiSelect;
