import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api, { apiPrivate } from "../../api";

export const getGenders = createAsyncThunk(
  "gender/getGenders",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/gender/");
      return response.data;
    } catch (error) {
      const message = error.response.data;

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addGender = createAsyncThunk(
  "gender/addGender",
  async (data, { dispatch }, thunkAPI) => {
    try {
      const response = await apiPrivate.post("api/gender/", {
        title: data.title,
        pronoun: data.pronouns,
      });
      dispatch(getGenders());
      toast.success("Successfully added Gender");
      return response.data;
    } catch (error) {
      // Check if the error message indicates that the gender already exists
      if (error.response && error.response.data && error.response.data.non_field_errors) {
        const errorMessage = error.response.data.non_field_errors[0];
        if (errorMessage === "The title already exists for this user") {
          toast.error("Gender already exists");
        }
      }

      // Handle other errors
      const message = error.response?.data?.non_field_errors || "An error occurred";
      toast.error(message);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteGender = createAsyncThunk(
  "gender/deteteGender",
  async (id, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.delete(`api/gender/${id}/`);
      dispatch(getGenders());
      toast.success("Successfully Deleted Gender");
    } catch (error) {
      const message = error.response.data;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateGender = createAsyncThunk(
  "gender/updateGender",
  async (data, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.put(`api/gender/${data?.id}/`, {
        title: data.title,
        pronoun: data.pronouns,
      });
      dispatch(getGenders());
      toast.success("Successfully Updated Gender");
    } catch (error) {
      const message = error.response.data;
      toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);
