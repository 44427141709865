import { useField } from "formik";
import React from "react";

const NameForm = ({ label, name, type, value, onChange }) => {
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        className={`form-control`}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default NameForm;
