import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api, { apiPrivate } from "../../api";

export const getComments = createAsyncThunk(
  "comments/getComments",
  async (data, thunkAPI) => {
    try {
      const response = await apiPrivate.get("api/comments/");
      return response.data;
    } catch (error) {
      const message = error.response.data;

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addComment = createAsyncThunk(
  "comments/addComment",
  async (data, { dispatch }, thunkAPI) => {
    try {
      const response = await apiPrivate.post("api/comments/", {
        title: data.name,
        comment: data.commentText,
      });
      dispatch(getComments());
      toast.success("Successfully added Comment");
      return response.data;
    } catch (error) {
      const message = error.response.data;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteComment = createAsyncThunk(
  "comments/deleteComment",
  async (id, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.delete(`api/comments/${id}/`);
      dispatch(getComments());
      toast.success("Successfully Deleted Comment");
    } catch (error) {
      const message = error.response.data;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateComment = createAsyncThunk(
  "comments/updateComment",
  async (data, { dispatch }, thunkAPI) => {
    try {
      await apiPrivate.put(`api/comments/${data?.id}/`, {
        title: data.commentTitle,
        comment: data.commentText,
      });
      dispatch(getComments());
      toast.success("Successfully Updated Comment");
    } catch (error) {
      const message = error.response.data;
      toast.error("Something went wrong");
      return thunkAPI.rejectWithValue(message);
    }
  }
);


