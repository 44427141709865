import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getToken } from "../../helpers/index.js";
import TopBar from "../Common/TopBar.jsx";

function PublicRoutes({ isLoggedIn }) {
  //   const { isLoggedIn, loggedInUser } = useSelector((state) => state.auth);
  const token = getToken();
  return (
    <>
      {!isLoggedIn || !token ? (
        <>
          <Outlet />
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
}

export default PublicRoutes;
