import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import CommonButton from "../../components/Common/CommonButton.jsx";
import FormikField from "../../components/Common/InputField.jsx";

import DataGrid from "../../components/Common/DataGrid.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addComment,
  deleteComment,
  getComments,
  updateComment,
} from "../../store/comments/commentThunk";

import Loader from "../../components/Common/Loader.jsx";
import { commentsColumns } from "../../helpers/tableColumns.js";
import { commentsValidationSchema } from "../../helpers/validationSchemas.js";
import DeleteModal from "../../components/Common/DeleteModal.jsx";
import EditFormModal from "../../components/Models/EditSemesterModal.jsx";
import EditCommentModal from "../../components/Models/EditCommentModal.jsx";
import FormikSelect from "../../components/Common/DropDown.jsx";
import { getCategory } from "../../store/commentCategory/commentCategoryThunk.js";
import { getGenders } from "../../store/gender/genderThunk.js";
import AddCommentModal from "../../components/AddModals/AddCommentModal.jsx";

const Comments = () => {
  const { data, loading, error, status } = useSelector(
    (state) => state.reducer.comments
  );
  const [showAddModal, setShowAddModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredArray, setFilteredArray] = useState(data);
  const [closeModal, setCloseModal] = useState(false);

  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const commentCategory = useSelector((state) => state.reducer.commentcategory);
  const gender = useSelector((state) => state.reducer.gender);
  useEffect(() => {
    dispatch(getComments());
    dispatch(getCategory());
    dispatch(getGenders());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCategory) {
      const check = data.filter((row) => row.title === selectedCategory);
      setFilteredArray(check);
    }

    if (selectedCategory === null || selectedCategory === "Select") {
      setFilteredArray(data);
    }
  }, [selectedCategory, data]);

  const handleDeleteToggle = async (row) => {
    setselectedItem(row);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteItem = () => {
    dispatch(deleteComment(selectedItem?.id));
    handleDeleteToggle();
  };

  const handleEditToggle = (row) => {
    setselectedItem(row);
    setShowEditModal(!showEditModal);
  };
  const handleCloseToggle = (row) => {
    setCloseModal(!closeModal);
  };
  const handleViewPage = () => {
    // values.id = id;
    // dispatch(updateComment(values));
    navigate("/view");
  };

  const handleUpdateItem = (values, id) => {
    values.id = id;
    dispatch(updateComment(values));
    handleEditToggle();
  };
  const handleAddToggle = (row) => {
    
    setShowAddModal(!showAddModal);
  };

  const columns = commentsColumns(handleEditToggle, handleDeleteToggle);

  const addCommentHandler = (values, resetForm) => {
    if (!values.commentText) {
      return;
    }

    dispatch(addComment(values)).then(() => {
      resetForm();
    });
  };

  if (loading) {
    return <Loader />;
  }


  const numberOfItems = data ? data.length : 0;
  const numbersArray = Array.from({ length: numberOfItems }, (_, index) => ({ value: index + 1 }));

  const combinedArray = filteredArray?.map((comment, index) => ({
    ...comment,
    number: numbersArray[index]?.value,
  }));


   
  return (
    <div className="comment-page">
      <AddCommentModal
        showAddModal={showAddModal}
        handleAddToggle={handleAddToggle}
        addComment={addComment}
       
      />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteToggle={handleDeleteToggle}
        handleDeleteItem={handleDeleteItem}
      />
      <EditCommentModal
        handleCloseToggle={handleCloseToggle}
        showEditModal={showEditModal}
        handleEditToggle={handleEditToggle}
        handleUpdateItem={handleUpdateItem}
        commentCategory={commentCategory}
        selectedItem={selectedItem}
        setShowEditModal={setShowEditModal}

      />

<div className=" w-100 d-flex justify-content-end my-3">
      <CommonButton
       variant="primary"
       text='+ Create New'
       onClick={() => handleAddToggle()}
       />
        </div>
      <div className="data-table-container comment-table">
        <div className="d-flex align-items-center justify-content-between mb-4 px-3">
          <h3 className="title3">Comments</h3>
          <select
            placeholder="Select"
            className="form-select w-25"
            onChange={(e) => {
            
              setSelectedCategory(e.target.value);
            }}
          >
            <option>Select</option>
            {commentCategory?.data?.map((item) => {
              return (
                <option
                  key={item?.id}
                  setselectedCategory={item?.name}
                  value={item?.name}
                >
                  {item?.name}
                </option>
              );
            })}
          </select>
        </div>

        {filteredArray && <DataGrid columns={columns} data={combinedArray} />}
      </div>
    </div>
  );
};

export default Comments;
