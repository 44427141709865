import { createSlice } from "@reduxjs/toolkit";
import { reducerHelper } from "./reducerHelper";

const initialState = {
  status: "",
  loading: false,
  data: [],
  error: null,
};

export const genderSlice = createSlice({
  name: "gender",
  initialState,
  reducers: {},

  // * Apiii Calls resultss
  extraReducers: reducerHelper,
});

// this is for dispatch
export const {} = genderSlice.actions;

// this is for configureStore
export default genderSlice.reducer;
