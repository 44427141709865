import { createSlice } from "@reduxjs/toolkit";
import { reducerHelpers } from "./reducerHelper";

const initialState = {
  status: "",
  loading: false,
  data: [],
  error: null,
};

export const classSlice = createSlice({
  name: "class",
  initialState,
  reducers: {},

  // * Apiii Calls resultss
  extraReducers: reducerHelpers,
});

// this is for dispatch
export const {} = classSlice.actions;

// this is for configureStore
export default classSlice.reducer;
