import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Form, Formik } from "formik";
import { commentsValidationSchema } from "../../helpers/validationSchemas.js";
import { useDispatch, useSelector } from "react-redux";
import FormikField from "../Common/InputField.jsx";
import CommonButton from "../Common/CommonButton.jsx";
import FormikSelect from "../Common/DropDown.jsx";

const AddCommentModal = ({
    showAddModal,
    handleAddToggle,
    addComment
}) => {
    const dispatch = useDispatch();
    const commentCategory = useSelector((state) => state.reducer.commentcategory);
    const gender = useSelector((state) => state.reducer.gender);
    const [saveButton, setSaveButton] = useState(false)


    const addCommentHandler = (values, resetForm, shouldResetForm) => {
        if (!values.commentText) {
            return;
        }

        // dispatch(addComment(values)).then(() => {
        //     resetForm();
        // });
        dispatch(addComment(values)).then(() => {
            if (shouldResetForm === false && saveButton === false) {
                resetForm();
            } else {
                handleAddToggle()
            }
        });
    };
    return (
        <>
            <Modal show={showAddModal} onHide={handleAddToggle}>
                <div class="modal-dialog modal-dialog-centered m-0 ">
                    <div class="modal-content p-4 ">
                        <div className="comment-modal mb-3">
<Modal.Header closeButton>
          <Modal.Title className="fw-bold">Add Comment</Modal.Title>
        </Modal.Header> 
                                    <div className="row">
                                <div className="col-6 col-lg-7">

                                    <Formik
                                        initialValues={{
                                            commentText: "",
                                            name: "",
                                        }}
                                        validationSchema={commentsValidationSchema}
                                        onSubmit={(values, { resetForm }) =>
                                            addCommentHandler(values, resetForm, false)
                                        }
                                    >
                                        <Form className="mb-4">
                                            <FormikSelect
                                                name="name"
                                                label="Category Name"
                                                options={commentCategory?.data}
                                                displayProperty="name"
                                            />
                                            <FormikField name="commentText" label="Comment" type="text" />

                                            <div className="select-btn mt-5 d-flex gap-3 ">
                                                <CommonButton
                                                    variant="light px-5"
                                                    type="button"
                                                    onClick={handleAddToggle}
                                                    text="Cancel"
                                                />
                                                <CommonButton
                                                    variant="primary px-2"
                                                    text="Save & Add New"
                                                    type="submit"
                                                />
                                                <CommonButton
                                                    variant="primary px-5"
                                                    text="Save"
                                                    type="submit"
                                                    onClick={(values) =>
                                                        setSaveButton(true)
                                                    }
                                                />
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                                <div className="col-6 col-lg-4 offset-lg-1 table-responsive">
                                    <h3 className="title4 mb-3 fw-bold ">Gender and Pronouns</h3>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td scope="col"></td>
                                                <td scope="col">
                                                    {"{"}p0{"}"}
                                                </td>
                                                <td scope="col">
                                                    {"{"}p1{"}"}
                                                </td>
                                                <td scope="col">
                                                    {"{"}p2{"}"}
                                                </td>
                                                <td scope="col">
                                                    {"{"}p3{"}"}
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gender?.data.map((row) => (
                                                <tr key={row.id}>
                                                    <th>{row.title}</th>
                                                    {row.pronoun.split("/").map((pronoun, index) => (
                                                        <td key={index}>{pronoun}</td>
                                                    ))}
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                    <p className="fw-bold">{`For student first name use {name}.`}</p>
                                    <p className="fw-bold">{`For subject use {subject}.`}</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    );
};

export default AddCommentModal;
